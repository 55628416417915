import { useMemo } from 'react'
import { Content } from 'shared/components/Walkthrough/partials/WalkthroughElements'
import { IsStagingServer } from 'shared/constants/const'
import useAuth from 'shared/hooks/useAuth'
import { ProfileTypes } from 'shared/types/profile/profile.type'

const useWalkthroughSteps = () => {
  const { userProfile } = useAuth()

  const isStarterProfile = useMemo(() => {
    return (
      userProfile != null &&
      [ProfileTypes.Starter].includes(userProfile.type as any)
    )
  }, [userProfile])

  const HOMEPAGE_STEPS = useMemo(() => {
    return [
      {
        selector: '.free_roam_tab',
        content: () => (
          <Content
            title="Free Roam"
            body="When 'Free Roam' is active, you will be able to move in any direction within the 3D Scene of your video scan."
          />
        ),
        position: 'right'
      },
      ...(isStarterProfile
        ? []
        : [
            {
              selector: '.snapshots_tab',
              content: () => (
                <Content
                  title="Snapshots"
                  body="In this tab, you will be able to see the screenshots you took while roaming around the scan in Free roam mode or video capture mode."
                />
              ),
              position: 'right'
            }
          ]),
      ...(IsStagingServer
        ? [
            {
              selector: '.video_capture_tab',
              content: () => (
                <Content
                  title="Video Capture"
                  body="The 'Video Capture' tab allows you to create a recording of your desired camera path set within the 3D Scene. You can also take screenshots from different angles within the viewer."
                />
              ),
              position: 'right'
            },
            {
              selector: '.object_detection_tab',
              content: () => (
                <Content
                  title="Object Detection"
                  body="Here you can see all the objects that our processing has identified within your video scan. Through 'Object Detection' you can filter scans based upon the specific objects that are cataloged. "
                />
              ),
              position: 'right'
            },
            {
              selector: '.notes_tab',
              content: () => (
                <Content
                  title="Notes"
                  body="The 'Notes' tab displays all the notes you took while viewing the 3D Scene of your video scan. Notes are position based, meaning they will only appear when you are in proximity to them."
                />
              ),
              position: 'right'
            },
            {
              selector: '.recordings_tab',
              content: () => (
                <Content
                  title="Recordings"
                  body="The 'Recordings' Tab displays all the recordings you created while in the 3D viewer. You can export these videos and share them on other social platforms."
                />
              ),
              position: 'right'
            }
          ]
        : ([] as any))
    ]
  }, [isStarterProfile])

  return { HOMEPAGE_STEPS }
}

export default useWalkthroughSteps
