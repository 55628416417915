import useThemeImages from 'assets/images'
import ProfileImage from 'assets/images/user-image.png'
import AppIconButton from 'shared/components/AppIconButton/AppIconButton'
import IsStagingComponent from 'shared/components/IsStagingComponent/IsStagingComponent'
import SideDrawer from 'shared/components/SideDrawer/SideDrawer'

import GroupChat from './partials/GroupChat/GroupChat'
import useDashboardScreen from './useDashboardScreen'

const DashboardScreen = () => {
  const { is_embed, scannerDetails, client_id, spectating_client_id } =
    useDashboardScreen()
  const { MailIcon, MapPinIcon, MessageCircleIcon } = useThemeImages()

  return is_embed != null || client_id !== spectating_client_id ? (
    <></>
  ) : (
    <div className="test_class relative">
      <SideDrawer
        btnContent={
          <img
            src={scannerDetails.profile_image?.url ?? ProfileImage}
            className="rounded-full w-8 h-8 lg:w-10 lg:h-10 z-10 object-cover"
          />
        }
        position="left"
        contentTitle="Scanner"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <img
              src={scannerDetails.profile_image?.url ?? ProfileImage}
              className="rounded-full w-8 h-8 lg:w-10 lg:h-10 object-cover"
            />
            <div>
              <h4 className="text-primary text-sm lg:text-md font-semibold leading-5">
                {scannerDetails.name}
              </h4>
              {/* <h5 className="text-white leading-5 text-sm lg:text-md">{scannerDetails.email}</h5> */}
            </div>
          </div>
          <IsStagingComponent>
            <div className="flex items-center space-x-4">
              <a
                href={`mailto:${scannerDetails.email}`}
                target="_blank"
                rel="noreferrer"
              >
                <AppIconButton icon={<MailIcon />} />
              </a>
              <AppIconButton
                icon={<MapPinIcon />}
                onClick={() => {
                  console.log('clicked')
                }}
              />
            </div>
          </IsStagingComponent>
        </div>
      </SideDrawer>
      <IsStagingComponent>
        <SideDrawer
          btnContent={
            <AppIconButton
              icon={<MessageCircleIcon color="white" height={20} width={20} />}
              disableRipple
            />
          }
          position="right"
          contentTitle={
            <div className="flex items-center space-x-4">
              <AppIconButton
                icon={
                  <MessageCircleIcon color="white" height={20} width={20} />
                }
                disableRipple
              />
              <h4 className="text-[20px] font-medium">Group chat</h4>
            </div>
          }
        >
          <GroupChat />
        </SideDrawer>
      </IsStagingComponent>
    </div>
  )
}

export default DashboardScreen
