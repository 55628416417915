import { useEffect, useRef, useState } from 'react'

import { type ISideDrawerProps } from './SideDrawer'

const useSideDrawer = (props: ISideDrawerProps) => {
  const { position } = props
  const [open, setOpen] = useState(false)
  const drawerRef = useRef<HTMLDivElement>(null)
  const drawerBtnRef = useRef<HTMLDivElement>(null)

  const fullClass = position === 'left' ? '-left-full' : '-right-full'
  const zeroClass = position === 'left' ? 'left-0' : 'right-0'
  const fourClass =
    position === 'left' ? 'left-0 lg:left-4' : 'right-0 lg:right-4'

  useEffect(() => {
    setTimeout(
      () => {
        if (open) {
          drawerBtnRef.current?.classList.add(fullClass)
          drawerBtnRef.current?.classList.remove(zeroClass)
        } else {
          drawerBtnRef.current?.classList.remove(fullClass)
          drawerBtnRef.current?.classList.add(zeroClass)
        }
      },
      open ? 0 : 200
    )

    setTimeout(
      () => {
        if (open) {
          drawerRef.current?.classList.add(fourClass)
          drawerRef.current?.classList.remove(fullClass)
        } else {
          drawerRef.current?.classList.remove(fourClass)
          drawerRef.current?.classList.add(fullClass)
        }
      },
      open ? 200 : 0
    )
  }, [open, fullClass, zeroClass, fourClass])

  const toggleDrawer = () => {
    setOpen(prev => !prev)
  }

  return { fullClass, zeroClass, drawerBtnRef, open, drawerRef, toggleDrawer }
}

export default useSideDrawer
