import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import CommonModalActionButton from 'shared/components/CommonModal/partials/CommonModalActionButton'
import FieldController from 'shared/components/FieldController/FieldController'
import { useSubmitSnapshotMutation } from 'shared/graphql/utils/enhanced'
import useFileUploader from 'shared/hooks/useFileUploader'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { rtkHandler } from 'shared/utils/handlers'
import { dataURLtoFile } from 'shared/utils/helper'
import yup from 'shared/utils/yup'
import { setCommonModalLoadingBtn } from 'store/slices/utils/commonModalSlice'

const SubmitSnapshotModalContent = ({
  onClose,
  getCameraPosition
}: {
  onClose: () => void
  getCameraPosition: any
}) => {
  const dispatch = useAppDispatch()

  const { uploadFile } = useFileUploader()
  const [submitSnapshot, submitSnapshotRes] = useSubmitSnapshotMutation()

  const scan_id = useAppSelector((state) => state.scan.scan?.scan_id);

  const schema = yup.object().shape({
    name: yup.string().required()
  })

  const form = useForm({
    defaultValues: {
      name: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: { name: string }) => {
    const render_img = document
      .querySelector('#background-image')
      ?.getAttribute('src')

    dispatch(setCommonModalLoadingBtn('submit-snapshot-btn'))
    const renderImgFile = await dataURLtoFile(
      render_img,
      `snapshot_${scan_id}_${Date.now()}.png`
    )

    uploadFile(renderImgFile as File).then((response: any) => {
      const photoId = response.data.file_id;
      // uploadImage(renderImgFile as File).then((photoId) => {
      const cameraPosition = getCameraPosition();

      submitSnapshot({
        data: {
          scan_id: scan_id,
          image_id: photoId,
          name: data.name,
          ...cameraPosition,
        },
      });
    });
  };

  useEffect(() => {
    rtkHandler(submitSnapshotRes, {
      onSuccess() {
        dispatch(setCommonModalLoadingBtn(''))
        onClose()
      },
      onError() {
        dispatch(setCommonModalLoadingBtn(''))
      }
    })
  }, [submitSnapshotRes])

  return (
    <form onSubmit={() => form.handleSubmit(onSubmit)} className="text-left">
      <FieldController
        control={form.control}
        name="name"
        label="Name:"
        inputVariant="outlined"
        placeholder="Enter the name for your snapshot"
        labelClassName="text-md font-medium text-[#344054]"
      />
      <CommonModalActionButton
        buttons={[
          {
            id: 'submit-snapshot-btn',
            children: 'Save',
            type: 'submit',
            onClick: () => {
              console.log('submit')
            }
          }
        ]}
        className="flex-row-reverse !pb-2"
        onCancelBtnClick={onClose}
      />
    </form>
  )
}

export default SubmitSnapshotModalContent
