import { gqlApi } from 'shared/apis/apiSlice/gqlApi'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  JSONObject: { input: any; output: any }
}

export type AddInviteeInput = {
  emails: Array<Scalars['String']['input']>
  scan_id: Scalars['Float']['input']
}

export type AddScanningDevicesInput = {
  category: Scalars['String']['input']
  devices: Array<Scalars['String']['input']>
}

export type AddSubmissionInput = {
  input_file_id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  scan_request_id: Scalars['Int']['input'];
  scanning_device_id?: InputMaybe<Scalars['Float']['input']>;
};

export type Address = {
  __typename?: 'Address'
  city?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  street?: Maybe<Scalars['String']['output']>
  zip?: Maybe<Scalars['String']['output']>
}

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>
  country?: InputMaybe<Scalars['String']['input']>
  street?: InputMaybe<Scalars['String']['input']>
  zip?: InputMaybe<Scalars['String']['input']>
}

export type AppleLoginInput = {
  code: Scalars['String']['input']
  redirect_uri: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type AppleRegisterInput = {
  code: Scalars['String']['input']
  invite_code?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
  redirect_uri: Scalars['String']['input']
}

export type Asset = {
  __typename?: 'Asset';
  asset_id: Scalars['Float']['output'];
  file: File;
  folder_id: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  project_id: Scalars['Float']['output'];
  user: User;
};

export type ChangePasswordInput = {
  new_password: Scalars['String']['input']
  old_password: Scalars['String']['input']
}

export type ChangeUsernameDtoInput = {
  username: Scalars['String']['input']
}

export type Configurations = {
  __typename?: 'Configurations'
  viewer_watermark: Scalars['String']['output']
}

export type ConfigurationsInput = {
  viewer_watermark: Scalars['String']['input']
}

export type CoordinateInput = {
  x: Scalars['Float']['input']
  y: Scalars['Float']['input']
  z: Scalars['Float']['input']
}

export type Coordinates = {
  __typename?: 'Coordinates'
  x: Scalars['Float']['output']
  y: Scalars['Float']['output']
  z: Scalars['Float']['output']
}

export type CreateAssetInput = {
  file_id: Scalars['Int']['input'];
  folder_id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  project_id: Scalars['Int']['input'];
};

export type CreateFileInput = {
  bucket: Scalars['String']['input']
  key: Scalars['String']['input']
  mimetype: Scalars['String']['input']
  name: Scalars['String']['input']
  size: Scalars['Float']['input']
  type: Scalars['String']['input']
  url: Scalars['String']['input']
}

export type CreateFolderInput = {
  name: Scalars['String']['input']
}

export type CreateProjectInput = {
  description: Scalars['String']['input']
  folders?: InputMaybe<Array<Scalars['String']['input']>>
  name: Scalars['String']['input']
  thumbnail_id?: InputMaybe<Scalars['Float']['input']>
  users?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type CreateSnapshotInput = {
  image_id: Scalars['Float']['input']
  name: Scalars['String']['input']
  position: CoordinateInput
  rotation: CoordinateInput
  scan_id: Scalars['Float']['input']
}

export type CreateTeamInput = {
  bio: Scalars['String']['input']
  brand_color: Scalars['String']['input']
  logo: Scalars['Float']['input']
  name: Scalars['String']['input']
}

export type DateFilterInput = {
  end_date?: InputMaybe<Scalars['String']['input']>
  start_date?: InputMaybe<Scalars['String']['input']>
}

export type DeleteProjectInput = {
  id: Scalars['Int']['input']
  option: Scalars['String']['input']
}

export type EditAccountInput = {
  address?: InputMaybe<AddressInput>
  name?: InputMaybe<Scalars['String']['input']>
}

export type EditProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  thumbnail_id?: InputMaybe<Scalars['Float']['input']>
  users?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type EditScanInput = {
  capture_intent?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<Scalars['String']['input']>
  folder_id?: InputMaybe<Scalars['Float']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  project_id?: InputMaybe<Scalars['Float']['input']>
  scanning_device_id?: InputMaybe<Scalars['Float']['input']>
  visibility?: InputMaybe<Scalars['String']['input']>
}

export type EditTeamInput = {
  bio?: InputMaybe<Scalars['String']['input']>
  brand_color?: InputMaybe<Scalars['String']['input']>
  logo_id?: InputMaybe<Scalars['Float']['input']>
  social_accounts?: InputMaybe<SocialAccountsInput>
}

export type EditTeamSettingInput = {
  configurations: ConfigurationsInput
}

export type File = {
  __typename?: 'File'
  bucket: Scalars['String']['output']
  file_id: Scalars['Float']['output']
  key: Scalars['String']['output']
  mimetype: Scalars['String']['output']
  name: Scalars['String']['output']
  size: Scalars['Float']['output']
  thumbnail?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type Folder = {
  __typename?: 'Folder'
  folder_id: Scalars['Float']['output']
  name: Scalars['String']['output']
  scans: Scan[]
  user: User
}

export type ForgotPasswordInput = {
  email: Scalars['String']['input']
}

export type GetProjectsInput = {
  search_key?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
}

export type GetRequestsInput = {
  dataset_type?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<DateFilterInput>;
  sort_on?: InputMaybe<Scalars['String']['input']>;
  sort_type?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type GetScansInput = {
  capture_intent?: InputMaybe<Scalars['String']['input']>
  category?: InputMaybe<Scalars['String']['input']>
  date?: InputMaybe<DateFilterInput>
  model_status?: InputMaybe<Scalars['String']['input']>
  scanner_name?: InputMaybe<Scalars['String']['input']>
  search_key?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<Scalars['String']['input']>
}

export type GetUsersInput = {
  roles?: InputMaybe<Array<Scalars['String']['input']>>
}

export type GoogleLoginInput = {
  code: Scalars['String']['input']
  redirect_uri: Scalars['String']['input']
}

export type GoogleRegisterInput = {
  code: Scalars['String']['input']
  invite_code?: InputMaybe<Scalars['String']['input']>
  phone: Scalars['String']['input']
  redirect_uri: Scalars['String']['input']
}

export type InviteCode = {
  __typename?: 'InviteCode'
  code: Scalars['String']['output']
  generated_at: Scalars['DateTime']['output']
  generated_by: Scalars['Float']['output']
  invite_code_id: Scalars['Float']['output']
  user_who_generated: User
  user_who_used?: Maybe<User>
}

export type InviteMembersInput = {
  emails: Array<Scalars['String']['input']>
}

export type Limits = {
  __typename?: 'Limits'
  projects_limit: Scalars['Float']['output']
  storage_limit: Scalars['Float']['output']
  team_members_limit: Scalars['Float']['output']
}

export type Location = {
  __typename?: 'Location'
  latitude: Scalars['String']['output']
  longitude: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type LocationInput = {
  latitude: Scalars['String']['input']
  longitude: Scalars['String']['input']
}

export type LoginInput = {
  email?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  phone_number?: InputMaybe<Scalars['String']['input']>
}

export type Mutation = {
  __typename?: 'Mutation';
  accept_invitation: Team;
  accept_scan_request: ScanRequest;
  accept_submission: ScanRequest;
  add_admin: User;
  add_scan_invitee: Scan;
  add_scanning_devices: Array<ScanningDevice>;
  add_submission: ScanRequest;
  add_team_manager: Team;
  add_user_scanning_devices: Array<ScanningDevice>;
  apple_login: UserWithToken;
  apple_register: Verification;
  approve_request: Team;
  cancel_subscription: Subscriptions;
  change_password: User;
  change_profile_image: User;
  change_username: Profile;
  create_asset: Asset;
  create_file: File;
  create_folder: Project;
  create_project: Project;
  create_snapshot: Snapshot;
  create_subscription: StripeInfo;
  create_team: Team;
  delete_account: Scalars['Boolean']['output'];
  delete_asset: Asset;
  delete_folder: Project;
  delete_project: Project;
  delete_scan: Scan;
  delete_scan_admin: Scan;
  delete_scan_request: ScanRequest;
  delete_snapshot: Snapshot;
  delete_team: Scalars['Boolean']['output'];
  edit_account: User;
  edit_folder: Project;
  edit_project: Project;
  edit_scan: Scan;
  end_scan_request: ScanRequest;
  forgot_password: PasswordReset;
  generate_invite_codes: Array<InviteCode>;
  google_login: UserWithToken;
  google_register: Verification;
  invite_members: Team;
  invite_requestees: ScanRequest;
  kill_viewer: Scalars['Boolean']['output'];
  leave_request_feedback: ScanRequest;
  leave_team: Team;
  login: UserWithToken;
  mark_notifications_as_read: Array<Notification>;
  publish_scan_request: ScanRequest;
  reject_scan_request: ScanRequest;
  remove_admin: User;
  remove_member: Team;
  remove_scan_invitee: Scan;
  remove_trial_period: Team;
  remove_user_scanning_devices: Array<ScanningDevice>;
  request_review: ScanRequest;
  request_revision: ScanRequest;
  request_team: Team;
  resend_email_otp: Verification;
  resend_invitation: Team;
  resend_phone_otp: Verification;
  reset_password: VerifyPasswordReset;
  send_bulk_notifications: Array<Notification>;
  send_submission_reminder: ScanRequest;
  set_default_point: Scan;
  signup: Array<Verification>;
  start_viewer: StartViewerResponse;
  submit_scan: Scan;
  switch_profile: UserWithToken;
  transfer_ownership: Team;
  update_email: Verification;
  update_model: Scalars['Int']['output'];
  update_phone: Verification;
  update_subscription: Subscriptions;
  update_team: Team;
  update_team_setting: Team;
  update_team_status: Team;
  update_user_status: User;
  update_viewer_port: ViewerPort;
  verify_email_update: Verification;
  verify_otp: UserWithToken;
  verify_phone_update: Verification;
};

export type MutationAccept_InvitationArgs = {
  invite_code: Scalars['String']['input']
}

export type MutationAccept_Scan_RequestArgs = {
  scan_request_id: Scalars['Int']['input']
}

export type MutationAccept_SubmissionArgs = {
  application_id: Scalars['Float']['input']
  scan_request_id: Scalars['Float']['input']
}

export type MutationAdd_AdminArgs = {
  user_id: Scalars['Int']['input']
}

export type MutationAdd_Scan_InviteeArgs = {
  data: AddInviteeInput
}

export type MutationAdd_Scanning_DevicesArgs = {
  data: AddScanningDevicesInput
}

export type MutationAdd_SubmissionArgs = {
  data: AddSubmissionInput
}

export type MutationAdd_Team_ManagerArgs = {
  member_id: Scalars['Int']['input']
}

export type MutationAdd_User_Scanning_DevicesArgs = {
  data: UserScanningDevicesInput
}

export type MutationApple_LoginArgs = {
  data: AppleLoginInput
}

export type MutationApple_RegisterArgs = {
  data: AppleRegisterInput
}

export type MutationApprove_RequestArgs = {
  member_id: Scalars['Int']['input']
}

export type MutationCancel_SubscriptionArgs = {
  subscription_id: Scalars['Int']['input']
}

export type MutationChange_PasswordArgs = {
  data: ChangePasswordInput
}

export type MutationChange_Profile_ImageArgs = {
  file_id: Scalars['Int']['input']
}

export type MutationChange_UsernameArgs = {
  data: ChangeUsernameDtoInput
}

export type MutationCreate_AssetArgs = {
  data: CreateAssetInput;
};

export type MutationCreate_FileArgs = {
  data: CreateFileInput
}

export type MutationCreate_FolderArgs = {
  data: CreateFolderInput
  project_id: Scalars['Int']['input']
}

export type MutationCreate_ProjectArgs = {
  data: CreateProjectInput
}

export type MutationCreate_SnapshotArgs = {
  data: CreateSnapshotInput
}

export type MutationCreate_SubscriptionArgs = {
  plan_id: Scalars['Int']['input']
}

export type MutationCreate_TeamArgs = {
  data: CreateTeamInput
}

export type MutationDelete_AssetArgs = {
  asset_id: Scalars['Int']['input'];
};

export type MutationDelete_FolderArgs = {
  folder_id: Scalars['Int']['input']
  project_id: Scalars['Int']['input']
}

export type MutationDelete_ProjectArgs = {
  data: DeleteProjectInput
}

export type MutationDelete_ScanArgs = {
  id: Scalars['Int']['input']
}

export type MutationDelete_Scan_AdminArgs = {
  scan_id: Scalars['Int']['input']
}

export type MutationDelete_Scan_RequestArgs = {
  scan_request_id: Scalars['Int']['input']
}

export type MutationDelete_SnapshotArgs = {
  snapshot_id: Scalars['Int']['input']
}

export type MutationEdit_AccountArgs = {
  data: EditAccountInput
}

export type MutationEdit_FolderArgs = {
  data: UpdateFolderInput
  folder_id: Scalars['Int']['input']
  project_id: Scalars['Int']['input']
}

export type MutationEdit_ProjectArgs = {
  data: EditProjectInput
  id: Scalars['Int']['input']
}

export type MutationEdit_ScanArgs = {
  data: EditScanInput
  id: Scalars['Int']['input']
}

export type MutationEnd_Scan_RequestArgs = {
  scan_request_id: Scalars['Int']['input'];
};

export type MutationForgot_PasswordArgs = {
  data: ForgotPasswordInput
}

export type MutationGenerate_Invite_CodesArgs = {
  count: Scalars['Int']['input']
}

export type MutationGoogle_LoginArgs = {
  data: GoogleLoginInput
}

export type MutationGoogle_RegisterArgs = {
  data: GoogleRegisterInput
}

export type MutationInvite_MembersArgs = {
  data: InviteMembersInput
}

export type MutationInvite_RequesteesArgs = {
  scan_request_id: Scalars['Int']['input']
  users: Array<Scalars['Int']['input']>
}

export type MutationKill_ViewerArgs = {
  port_id: Scalars['Float']['input'];
};

export type MutationLeave_Request_FeedbackArgs = {
  application_id: Scalars['Float']['input'];
  comment: Scalars['String']['input'];
  rating: Scalars['Float']['input'];
  scan_request_id: Scalars['Float']['input'];
};

export type MutationLoginArgs = {
  data: LoginInput
}

export type MutationPublish_Scan_RequestArgs = {
  data: SubmitScanRequestInput
}

export type MutationReject_Scan_RequestArgs = {
  scan_request_id: Scalars['Int']['input']
}

export type MutationRemove_AdminArgs = {
  user_id: Scalars['Int']['input']
}

export type MutationRemove_MemberArgs = {
  member_id: Scalars['Int']['input']
}

export type MutationRemove_Scan_InviteeArgs = {
  data: RemoveInviteeInput
}

export type MutationRemove_Trial_PeriodArgs = {
  team_id: Scalars['Int']['input']
}

export type MutationRemove_User_Scanning_DevicesArgs = {
  data: UserScanningDevicesInput
}

export type MutationRequest_ReviewArgs = {
  scan_request_id: Scalars['Float']['input']
}

export type MutationRequest_RevisionArgs = {
  application_id: Scalars['Float']['input']
  scan_request_id: Scalars['Float']['input']
}

export type MutationRequest_TeamArgs = {
  invite_code: Scalars['String']['input']
}

export type MutationResend_Email_OtpArgs = {
  email: Scalars['String']['input']
}

export type MutationResend_InvitationArgs = {
  email: Scalars['String']['input']
}

export type MutationResend_Phone_OtpArgs = {
  phone: Scalars['String']['input']
}

export type MutationReset_PasswordArgs = {
  data: ResetPasswordInput
}

export type MutationSend_Bulk_NotificationsArgs = {
  data: SendNotificationInput
}

export type MutationSend_Submission_ReminderArgs = {
  application_id: Scalars['Float']['input'];
  scan_request_id: Scalars['Float']['input'];
};

export type MutationSet_Default_PointArgs = {
  data: SetDefaultPointDtoInput
  scan_id: Scalars['Int']['input']
}

export type MutationSignupArgs = {
  data: SignupInput
}

export type MutationStart_ViewerArgs = {
  data: StartModelInput
}

export type MutationSubmit_ScanArgs = {
  data: SubmitScanInput
}

export type MutationSwitch_ProfileArgs = {
  id: Scalars['Int']['input']
}

export type MutationTransfer_OwnershipArgs = {
  member_id: Scalars['Int']['input']
}

export type MutationUpdate_ModelArgs = {
  data: UpdateModelInput
  scan_id: Scalars['Int']['input']
}

export type MutationUpdate_SubscriptionArgs = {
  plan_id: Scalars['Int']['input'];
  subscription_id: Scalars['Int']['input'];
};

export type MutationUpdate_TeamArgs = {
  data: EditTeamInput
}

export type MutationUpdate_Team_SettingArgs = {
  data: EditTeamSettingInput
}

export type MutationUpdate_Team_StatusArgs = {
  team_id: Scalars['Int']['input']
}

export type MutationUpdate_User_StatusArgs = {
  user_id: Scalars['Int']['input']
}

export type MutationUpdate_Viewer_PortArgs = {
  pod_id: Scalars['String']['input']
  port_id: Scalars['Int']['input']
}

export type MutationVerify_Email_UpdateArgs = {
  data: VerifyEmailInput
}

export type MutationVerify_OtpArgs = {
  data: VerifyOtpInput
}

export type MutationVerify_Phone_UpdateArgs = {
  data: VerifyPhoneInput
}

export type Notification = {
  __typename?: 'Notification'
  body: Scalars['String']['output']
  created_at: Scalars['DateTime']['output']
  metadata: Scalars['JSONObject']['output']
  notification_id: Scalars['Float']['output']
  read: Scalars['Boolean']['output']
  title: Scalars['String']['output']
  type: Scalars['String']['output']
}

export type PaginationInput = {
  limit: Scalars['Int']['input']
  page: Scalars['Int']['input']
}

export type PasswordReset = {
  __typename?: 'PasswordReset'
  password_reset_id: Scalars['Float']['output']
}

export type Permissions = {
  __typename?: 'Permissions'
  team_manager: UserPermission
  team_member: UserPermission
  team_owner: UserPermission
}

export type Plan = {
  __typename?: 'Plan';
  benefits: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  period: Scalars['String']['output'];
  plan_id: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  team?: Maybe<Team>;
};

export type Profile = {
  __typename?: 'Profile'
  member?: Maybe<TeamMember>
  name: Scalars['String']['output']
  profile_id: Scalars['Float']['output']
  profile_image: File
  type: Scalars['String']['output']
  user: User
  username?: Maybe<Scalars['String']['output']>
}

export type Project = {
  __typename?: 'Project';
  created_at: Scalars['DateTime']['output'];
  default: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  folders: Array<Folder>;
  name: Scalars['String']['output'];
  project_id: Scalars['Float']['output'];
  thumbnail: File;
  user: User;
  users: Array<User>;
  visible: Scalars['Boolean']['output'];
};

export type ProjectFolderPermissions = {
  __typename?: 'ProjectFolderPermissions'
  create: Scalars['Boolean']['output']
  delete: Scalars['Boolean']['output']
  edit: Scalars['Boolean']['output']
}

export type ProjectPermissions = {
  __typename?: 'ProjectPermissions'
  create: Scalars['Boolean']['output']
  delete: Scalars['Boolean']['output']
  edit: Scalars['Boolean']['output']
  folder: ProjectFolderPermissions
}

export type Query = {
  __typename?: 'Query';
  asset: Asset;
  assets: Array<Asset>;
  download_model: Scan;
  get_scan: Scan;
  invitations: Array<Team>;
  invite_codes: Array<InviteCode>;
  notifications: Array<Notification>;
  plans: Array<Plan>;
  profile: Profile;
  profiles: Array<Profile>;
  project: Project;
  projects: Array<Project>;
  public_scan: Scan;
  public_scans: Array<Scan>;
  published_scan_requests: Array<ScanRequest>;
  scan: Scan;
  scan_request: ScanRequest;
  scanning_devices: Array<ScanningDevice>;
  scans: Array<Scan>;
  subscriptions: Array<Subscriptions>;
  team: Team;
  team_scan_requests: Array<ScanRequest>;
  teams: Array<Team>;
  users: Array<User>;
  verify_forgot_password: VerifyPasswordReset;
  verify_token: User;
  viewer_port: ViewerPort;
};

export type QueryAssetArgs = {
  asset_id: Scalars['Int']['input'];
};

export type QueryDownload_ModelArgs = {
  scan_id: Scalars['Int']['input']
}

export type QueryGet_ScanArgs = {
  scan_id: Scalars['Int']['input']
}

export type QueryInvite_CodesArgs = {
  pagination: PaginationInput
}

export type QueryProjectArgs = {
  id: Scalars['Int']['input']
}

export type QueryProjectsArgs = {
  filters: GetProjectsInput
}

export type QueryPublic_ScanArgs = {
  id: Scalars['Int']['input']
}

export type QueryPublic_ScansArgs = {
  filters: GetScansInput
}

export type QueryPublished_Scan_RequestsArgs = {
  filters?: InputMaybe<GetRequestsInput>;
};

export type QueryScanArgs = {
  id: Scalars['Int']['input']
}

export type QueryScan_RequestArgs = {
  scan_request_id: Scalars['Int']['input']
}

export type QueryScansArgs = {
  filters: GetScansInput
}

export type QueryTeam_Scan_RequestsArgs = {
  filters?: InputMaybe<GetRequestsInput>;
};

export type QueryTeamsArgs = {
  pagination: PaginationInput
}

export type QueryUsersArgs = {
  data: GetUsersInput
  pagination: PaginationInput
}

export type QueryVerify_Forgot_PasswordArgs = {
  data: VerifyForgotPasswordInput
}

export type QueryViewer_PortArgs = {
  port_id: Scalars['Float']['input']
}

export type RemoveInviteeInput = {
  emails: Array<Scalars['String']['input']>
  scan_id: Scalars['Float']['input']
}

export type ResetPasswordInput = {
  id: Scalars['Float']['input']
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type Scan = {
  __typename?: 'Scan';
  capture_intent: Scalars['String']['output'];
  category: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  dataset_type: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  folder_id: Scalars['Float']['output'];
  input_file: File;
  invitees: Array<ScanInvitee>;
  location: Location;
  model: ScanModel;
  name: Scalars['String']['output'];
  project_id: Scalars['Float']['output'];
  scan_id: Scalars['Float']['output'];
  scanning_device?: Maybe<ScanningDevice>;
  snapshots: Array<Snapshot>;
  type: Scalars['String']['output'];
  user: User;
  visibility: Scalars['String']['output'];
};

export type ScanInvitee = {
  __typename?: 'ScanInvitee'
  email: Scalars['String']['output']
  invitee_id: Scalars['Float']['output']
  status: Scalars['String']['output']
  user?: Maybe<User>
}

export type ScanModel = {
  __typename?: 'ScanModel';
  default_pos_img?: Maybe<File>;
  failed_email_sent: Scalars['Boolean']['output'];
  failed_reason: Scalars['String']['output'];
  is_failed: Scalars['Boolean']['output'];
  model_id: Scalars['Float']['output'];
  ns_file?: Maybe<File>;
  position?: Maybe<Coordinates>;
  processing_time: Scalars['Float']['output'];
  render_file?: Maybe<File>;
  rendering_time: Scalars['Float']['output'];
  rotation?: Maybe<Coordinates>;
  status: Scalars['String']['output'];
  training_time: Scalars['Float']['output'];
};

export type ScanModelPermissions = {
  __typename?: 'ScanModelPermissions'
  download_model: Scalars['Boolean']['output']
  view_scan_in_viewer: Scalars['Boolean']['output']
  watermark_toggle: Scalars['Boolean']['output']
}

export type ScanPermissions = {
  __typename?: 'ScanPermissions'
  delete: Scalars['Boolean']['output']
  edit: Scalars['Boolean']['output']
  embeds: Scalars['Boolean']['output']
  model: ScanModelPermissions
  share: Scalars['Boolean']['output']
  snapshots: Scalars['Boolean']['output']
  submit: SubmitScanPermissions
}

export type ScanRequest = {
  __typename?: 'ScanRequest';
  accepting_applications: Scalars['Boolean']['output'];
  applications: Array<ScanRequestApplication>;
  camera_type: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  dataset_type: Scalars['String']['output'];
  description: Scalars['String']['output'];
  estimated_time: Scalars['String']['output'];
  extra_notes: Scalars['String']['output'];
  location: Location;
  requestor: User;
  scan_focus: Scalars['String']['output'];
  scan_request_id: Scalars['Float']['output'];
  scanner_count: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  title: Scalars['String']['output'];
  urgency: Scalars['String']['output'];
};

export type ScanRequestApplication = {
  __typename?: 'ScanRequestApplication';
  application_id: Scalars['Float']['output'];
  review?: Maybe<ScanRequestReview>;
  status: Scalars['String']['output'];
  submissions: Array<Scan>;
  user: User;
};

export type ScanRequestReview = {
  __typename?: 'ScanRequestReview';
  comment: Scalars['String']['output'];
  rating: Scalars['Float']['output'];
  review_id: Scalars['Float']['output'];
};

export type ScanningDevice = {
  __typename?: 'ScanningDevice'
  category: Scalars['String']['output']
  name: Scalars['String']['output']
  scanning_device_id: Scalars['Float']['output']
}

export type SendNotificationInput = {
  message: Scalars['String']['input']
  metadata?: InputMaybe<Scalars['JSONObject']['input']>
  title: Scalars['String']['input']
  users?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type SetDefaultPointDtoInput = {
  default_pos_img_id: Scalars['Float']['input']
  position: CoordinateInput
  rotation: CoordinateInput
}

export type SignupInput = {
  email: Scalars['String']['input']
  invite_code?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  password: Scalars['String']['input']
  phone: Scalars['String']['input']
}

export type Snapshot = {
  __typename?: 'Snapshot'
  image: File
  name: Scalars['String']['output']
  position?: Maybe<Coordinates>
  rotation?: Maybe<Coordinates>
  snapshot_id: Scalars['Float']['output']
  user: User
}

export type SocialAccounts = {
  __typename?: 'SocialAccounts'
  facebook?: Maybe<Scalars['String']['output']>
  instagram?: Maybe<Scalars['String']['output']>
  linkedin?: Maybe<Scalars['String']['output']>
  twitter?: Maybe<Scalars['String']['output']>
}

export type SocialAccountsInput = {
  facebook?: InputMaybe<Scalars['String']['input']>
  instagram?: InputMaybe<Scalars['String']['input']>
  linkedin?: InputMaybe<Scalars['String']['input']>
  twitter?: InputMaybe<Scalars['String']['input']>
}

export type StartModelInput = {
  position: Scalars['String']['input']
  scan_id: Scalars['Int']['input']
  snapshot_id?: InputMaybe<Scalars['Int']['input']>
  user_id?: InputMaybe<Scalars['Int']['input']>
}

export type StartViewerResponse = {
  __typename?: 'StartViewerResponse'
  port: ViewerPort
  scan: Scan
  watermark: Watermark
}

export type StripeInfo = {
  __typename?: 'StripeInfo'
  client_secret: Scalars['String']['output']
  customer_id: Scalars['String']['output']
  intent_id: Scalars['String']['output']
  latest_invoice_id: Scalars['String']['output']
  subscription_id: Scalars['String']['output']
}

export type SubmitScanInput = {
  capture_intent: Scalars['String']['input']
  category: Scalars['String']['input']
  dataset_type: Scalars['String']['input']
  folder_id: Scalars['Float']['input']
  input_file_id: Scalars['Float']['input']
  location: LocationInput
  name: Scalars['String']['input']
  project_id: Scalars['Float']['input']
  scanning_device_id?: InputMaybe<Scalars['Float']['input']>
}

export type SubmitScanPermissions = {
  __typename?: 'SubmitScanPermissions'
  overall: Scalars['Boolean']['output']
  video: Scalars['Boolean']['output']
  video_360: Scalars['Boolean']['output']
  zipped_photos: Scalars['Boolean']['output']
}

export type SubmitScanRequestInput = {
  camera_type: Scalars['String']['input']
  dataset_type: Scalars['String']['input']
  description: Scalars['String']['input']
  estimated_time: Scalars['String']['input']
  extra_notes?: InputMaybe<Scalars['String']['input']>
  location: LocationInput
  scan_focus: Scalars['String']['input']
  scanner_count: Scalars['Float']['input']
  title: Scalars['String']['input']
  urgency: Scalars['String']['input']
}

export type Subscriptions = {
  __typename?: 'Subscriptions'
  plan: Plan
  status: Scalars['String']['output']
  subscription_id: Scalars['Float']['output']
}

export type Team = {
  __typename?: 'Team'
  active: Scalars['Boolean']['output']
  bio: Scalars['String']['output']
  brand_color: Scalars['String']['output']
  credits: TeamCredit
  invite_code: Scalars['String']['output']
  logo: File
  members: TeamMember[]
  name: Scalars['String']['output']
  owner: User
  setting: TeamSetting
  social_accounts: SocialAccounts
  team_id: Scalars['Float']['output']
  trial_period?: Maybe<TrialPeriod>
}

export type TeamCredit = {
  __typename?: 'TeamCredit'
  credits: Scalars['Float']['output']
  team_id: Scalars['Float']['output']
}

export type TeamMember = {
  __typename?: 'TeamMember'
  accepted_at?: Maybe<Scalars['DateTime']['output']>
  email: Scalars['String']['output']
  invited_at?: Maybe<Scalars['DateTime']['output']>
  member_id: Scalars['Float']['output']
  profile?: Maybe<Profile>
  requested_at?: Maybe<Scalars['DateTime']['output']>
  role: Scalars['String']['output']
  status: Scalars['String']['output']
  team_id: Scalars['Float']['output']
  user?: Maybe<User>
}

export type TeamMemberPermissions = {
  __typename?: 'TeamMemberPermissions'
  approve_request: Scalars['Boolean']['output']
  get: Scalars['Boolean']['output']
  invite: Scalars['Boolean']['output']
  remove: Scalars['Boolean']['output']
  transfer_ownership: Scalars['Boolean']['output']
}

export type TeamPermissions = {
  __typename?: 'TeamPermissions'
  delete: Scalars['Boolean']['output']
  edit: Scalars['Boolean']['output']
  leave: Scalars['Boolean']['output']
  members: TeamMemberPermissions
}

export type TeamSetting = {
  __typename?: 'TeamSetting'
  configurations: Configurations
  limits: Limits
  permissions: Permissions
  team_id: Scalars['Float']['output']
}

export type TrialPeriod = {
  __typename?: 'TrialPeriod'
  days_remaining: Scalars['Float']['output']
  end_date: Scalars['DateTime']['output']
  start_date: Scalars['DateTime']['output']
  team_id: Scalars['Float']['output']
}

export type UpdateFolderInput = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateModelInput = {
  failed_reason?: InputMaybe<Scalars['String']['input']>;
  internal_status?: InputMaybe<Scalars['String']['input']>;
  is_failed?: InputMaybe<Scalars['Boolean']['input']>;
  ns_file_id?: InputMaybe<Scalars['Int']['input']>;
  processing_time?: InputMaybe<Scalars['Float']['input']>;
  render_file_id?: InputMaybe<Scalars['Int']['input']>;
  rendering_time?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  training_time?: InputMaybe<Scalars['Float']['input']>;
};

export type User = {
  __typename?: 'User'
  active: Scalars['Boolean']['output']
  address: Address
  auth_type: Scalars['String']['output']
  email: Scalars['String']['output']
  email_verified: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  phone: Scalars['String']['output']
  phone_verified: Scalars['Boolean']['output']
  profile_image: File
  scanning_devices: ScanningDevice[]
  user_id: Scalars['Float']['output']
  waiting_list: Scalars['Boolean']['output']
}

export type UserPermission = {
  __typename?: 'UserPermission'
  projects: ProjectPermissions
  scans: ScanPermissions
  team: TeamPermissions
}

export type UserScanningDevicesInput = {
  devices: Array<Scalars['Int']['input']>
}

export type UserWithToken = {
  __typename?: 'UserWithToken'
  active: Scalars['Boolean']['output']
  address: Address
  auth_type: Scalars['String']['output']
  email: Scalars['String']['output']
  email_verified: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  phone: Scalars['String']['output']
  phone_verified: Scalars['Boolean']['output']
  profile: Profile
  profile_image: File
  scanning_devices: ScanningDevice[]
  token: Scalars['String']['output']
  user_id: Scalars['Float']['output']
  waiting_list: Scalars['Boolean']['output']
}

export type Verification = {
  __typename?: 'Verification'
  /** Email or Phone number */
  data: Scalars['String']['output']
  type: Scalars['String']['output']
  verification_id: Scalars['Float']['output']
}

export type VerifyEmailInput = {
  email: Scalars['String']['input']
  otp: Scalars['String']['input']
  verification_id: Scalars['Int']['input']
}

export type VerifyForgotPasswordInput = {
  id: Scalars['Float']['input']
  token: Scalars['String']['input']
}

export type VerifyOtpInput = {
  email_otp?: InputMaybe<Scalars['String']['input']>
  email_verification_id?: InputMaybe<Scalars['Float']['input']>
  phone_otp?: InputMaybe<Scalars['String']['input']>
  phone_verification_id?: InputMaybe<Scalars['Float']['input']>
}

export type VerifyPasswordReset = {
  __typename?: 'VerifyPasswordReset'
  email: Scalars['String']['output']
  status: Scalars['Boolean']['output']
  user_id: Scalars['Float']['output']
}

export type VerifyPhoneInput = {
  otp: Scalars['String']['input']
  phone: Scalars['String']['input']
  verification_id: Scalars['Int']['input']
}

export type ViewerPort = {
  __typename?: 'ViewerPort'
  is_available: Scalars['Boolean']['output']
  pod_id?: Maybe<Scalars['String']['output']>
  port: Scalars['Float']['output']
  port_id: Scalars['Float']['output']
  scan_id?: Maybe<Scalars['Float']['output']>
  user?: Maybe<User>
  user_id?: Maybe<Scalars['Float']['output']>
  worker_id?: Maybe<Scalars['String']['output']>
}

export type Watermark = {
  __typename?: 'Watermark'
  url: Scalars['String']['output']
}

export type GoogleLoginMutationVariables = Exact<{
  data: GoogleLoginInput
}>

export type GoogleLoginMutation = {
  __typename?: 'Mutation'
  google_login: {
    __typename?: 'UserWithToken'
    user_id: number
    name: string
    email: string
    token: string
  }
}

export type AppleLoginMutationVariables = Exact<{
  data: AppleLoginInput
}>

export type AppleLoginMutation = {
  __typename?: 'Mutation'
  apple_login: {
    __typename?: 'UserWithToken'
    user_id: number
    name: string
    email: string
    token: string
  }
}

export type SwitchProfileMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type SwitchProfileMutation = {
  __typename?: 'Mutation'
  switch_profile: {
    __typename?: 'UserWithToken'
    user_id: number
    name: string
    email: string
    phone: string
    active: boolean
    token: string
    waiting_list: boolean
    auth_type: string
    phone_verified: boolean
    email_verified: boolean
    address: {
      __typename?: 'Address'
      city?: string | null
      country?: string | null
      street?: string | null
      zip?: string | null
    }
    profile_image: { __typename?: 'File'; url: string }
    scanning_devices: Array<{
      __typename?: 'ScanningDevice'
      scanning_device_id: number
      name: string
      category: string
    }>
    profile: {
      __typename?: 'Profile'
      profile_id: number
      name: string
      type: string
      username?: string | null
      profile_image: { __typename?: 'File'; url: string }
    }
  }
}

export type LoginMutationVariables = Exact<{
  data: LoginInput
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'UserWithToken'
    user_id: number
    name: string
    email: string
    phone: string
    active: boolean
    token: string
    waiting_list: boolean
    auth_type: string
    phone_verified: boolean
    email_verified: boolean
    address: {
      __typename?: 'Address'
      city?: string | null
      country?: string | null
      street?: string | null
      zip?: string | null
    }
    profile_image: { __typename?: 'File'; url: string }
    scanning_devices: Array<{
      __typename?: 'ScanningDevice'
      scanning_device_id: number
      name: string
      category: string
    }>
    profile: {
      __typename?: 'Profile'
      profile_id: number
      name: string
      type: string
      username?: string | null
      profile_image: { __typename?: 'File'; url: string }
    }
  }
}

export type VerifyTokenQueryVariables = Exact<Record<string, never>>

export type VerifyTokenQuery = {
  __typename?: 'Query'
  verify_token: {
    __typename?: 'User'
    waiting_list: boolean
    auth_type: string
    phone_verified: boolean
    email_verified: boolean
    user_id: number
    name: string
    email: string
    phone: string
    active: boolean
    address: {
      __typename?: 'Address'
      street?: string | null
      city?: string | null
      zip?: string | null
      country?: string | null
    }
    profile_image: { __typename?: 'File'; url: string }
    scanning_devices: Array<{
      __typename?: 'ScanningDevice'
      scanning_device_id: number
      name: string
      category: string
    }>
  }
  team: {
    __typename?: 'Team'
    team_id: number
    name: string
    bio: string
    brand_color: string
    logo: { __typename?: 'File'; url: string }
    members: Array<{
      __typename?: 'TeamMember'
      member_id: number
      email: string
      team_id: number
      role: string
      status: string
      requested_at?: any | null
      accepted_at?: any | null
      invited_at?: any | null
      user?: {
        __typename?: 'User'
        user_id: number
        name: string
        email: string
        profile_image: { __typename?: 'File'; thumbnail?: string | null }
        scanning_devices: Array<{
          __typename?: 'ScanningDevice'
          name: string
          scanning_device_id: number
        }>
      } | null
      profile?: { __typename?: 'Profile'; username?: string | null } | null
    }>
    setting: {
      __typename?: 'TeamSetting'
      configurations: {
        __typename?: 'Configurations'
        viewer_watermark: string
      }
      permissions: {
        __typename?: 'Permissions'
        team_owner: {
          __typename?: 'UserPermission'
          scans: {
            __typename?: 'ScanPermissions'
            edit: boolean
            delete: boolean
            embeds: boolean
            snapshots: boolean
            share: boolean
            submit: {
              __typename?: 'SubmitScanPermissions'
              overall: boolean
              video: boolean
              video_360: boolean
              zipped_photos: boolean
            }
            model: {
              __typename?: 'ScanModelPermissions'
              download_model: boolean
              view_scan_in_viewer: boolean
            }
          }
          projects: {
            __typename?: 'ProjectPermissions'
            create: boolean
            edit: boolean
            delete: boolean
            folder: {
              __typename?: 'ProjectFolderPermissions'
              create: boolean
              edit: boolean
              delete: boolean
            }
          }
          team: {
            __typename?: 'TeamPermissions'
            edit: boolean
            delete: boolean
            leave: boolean
            members: {
              __typename?: 'TeamMemberPermissions'
              get: boolean
              invite: boolean
              remove: boolean
              transfer_ownership: boolean
              approve_request: boolean
            }
          }
        }
        team_manager: {
          __typename?: 'UserPermission'
          scans: {
            __typename?: 'ScanPermissions'
            edit: boolean
            delete: boolean
            embeds: boolean
            snapshots: boolean
            share: boolean
            submit: {
              __typename?: 'SubmitScanPermissions'
              overall: boolean
              video: boolean
              video_360: boolean
              zipped_photos: boolean
            }
            model: {
              __typename?: 'ScanModelPermissions'
              download_model: boolean
              view_scan_in_viewer: boolean
            }
          }
          projects: {
            __typename?: 'ProjectPermissions'
            create: boolean
            edit: boolean
            delete: boolean
            folder: {
              __typename?: 'ProjectFolderPermissions'
              create: boolean
              edit: boolean
              delete: boolean
            }
          }
          team: {
            __typename?: 'TeamPermissions'
            edit: boolean
            delete: boolean
            leave: boolean
            members: {
              __typename?: 'TeamMemberPermissions'
              get: boolean
              invite: boolean
              remove: boolean
              transfer_ownership: boolean
              approve_request: boolean
            }
          }
        }
        team_member: {
          __typename?: 'UserPermission'
          scans: {
            __typename?: 'ScanPermissions'
            edit: boolean
            delete: boolean
            embeds: boolean
            snapshots: boolean
            share: boolean
            submit: {
              __typename?: 'SubmitScanPermissions'
              overall: boolean
              video: boolean
              video_360: boolean
              zipped_photos: boolean
            }
            model: {
              __typename?: 'ScanModelPermissions'
              download_model: boolean
              view_scan_in_viewer: boolean
            }
          }
          projects: {
            __typename?: 'ProjectPermissions'
            create: boolean
            edit: boolean
            delete: boolean
            folder: {
              __typename?: 'ProjectFolderPermissions'
              create: boolean
              edit: boolean
              delete: boolean
            }
          }
          team: {
            __typename?: 'TeamPermissions'
            edit: boolean
            delete: boolean
            leave: boolean
            members: {
              __typename?: 'TeamMemberPermissions'
              get: boolean
              invite: boolean
              remove: boolean
              transfer_ownership: boolean
              approve_request: boolean
            }
          }
        }
      }
    }
    trial_period?: {
      __typename?: 'TrialPeriod'
      start_date: any
      end_date: any
      days_remaining: number
    } | null
    credits: { __typename?: 'TeamCredit'; credits: number }
    social_accounts: {
      __typename?: 'SocialAccounts'
      facebook?: string | null
      twitter?: string | null
      instagram?: string | null
      linkedin?: string | null
    }
  }
  profile: {
    __typename?: 'Profile'
    profile_id: number
    name: string
    type: string
    username?: string | null
    profile_image: {
      __typename?: 'File'
      url: string
      thumbnail?: string | null
    }
  }
}

export type UserDetailsFragment = {
  __typename?: 'User'
  user_id: number
  name: string
  email: string
  phone: string
  active: boolean
  address: {
    __typename?: 'Address'
    street?: string | null
    city?: string | null
    zip?: string | null
    country?: string | null
  }
  profile_image: { __typename?: 'File'; url: string }
  scanning_devices: Array<{
    __typename?: 'ScanningDevice'
    scanning_device_id: number
    name: string
    category: string
  }>
}

export type UserTokenDetailsFragment = {
  __typename?: 'UserWithToken'
  user_id: number
  token: string
  name: string
  email: string
  phone: string
  active: boolean
  profile: {
    __typename?: 'Profile'
    profile_id: number
    name: string
    type: string
    username?: string | null
    member?: {
      __typename?: 'TeamMember'
      member_id: number
      email: string
      team_id: number
      role: string
      status: string
      requested_at?: any | null
      accepted_at?: any | null
      invited_at?: any | null
      user?: {
        __typename?: 'User'
        user_id: number
        name: string
        email: string
        profile_image: { __typename?: 'File'; thumbnail?: string | null }
        scanning_devices: Array<{
          __typename?: 'ScanningDevice'
          name: string
          scanning_device_id: number
        }>
      } | null
      profile?: { __typename?: 'Profile'; username?: string | null } | null
    } | null
    profile_image: { __typename?: 'File'; url: string }
  }
  address: {
    __typename?: 'Address'
    street?: string | null
    city?: string | null
    zip?: string | null
    country?: string | null
  }
  profile_image: { __typename?: 'File'; url: string }
  scanning_devices: Array<{
    __typename?: 'ScanningDevice'
    scanning_device_id: number
    name: string
    category: string
  }>
}

export type ScanningDeviceDetailsFragment = {
  __typename?: 'ScanningDevice'
  scanning_device_id: number
  name: string
  category: string
}

export type ProfileDetailsFragment = {
  __typename?: 'Profile'
  profile_id: number
  name: string
  type: string
  username?: string | null
  member?: {
    __typename?: 'TeamMember'
    member_id: number
    email: string
    team_id: number
    role: string
    status: string
    requested_at?: any | null
    accepted_at?: any | null
    invited_at?: any | null
    user?: {
      __typename?: 'User'
      user_id: number
      name: string
      email: string
      profile_image: { __typename?: 'File'; thumbnail?: string | null }
      scanning_devices: Array<{
        __typename?: 'ScanningDevice'
        name: string
        scanning_device_id: number
      }>
    } | null
    profile?: { __typename?: 'Profile'; username?: string | null } | null
  } | null
  profile_image: { __typename?: 'File'; url: string }
}

export type AddressDetailsFragment = {
  __typename?: 'Address'
  street?: string | null
  city?: string | null
  zip?: string | null
  country?: string | null
}

export type ConfigurationsFragment = {
  __typename?: 'Configurations'
  viewer_watermark: string
}

export type ScanSnapShotFragment = {
  __typename?: 'Snapshot'
  snapshot_id: number
  name: string
  image: { __typename?: 'File'; url: string; thumbnail?: string | null }
  user: {
    __typename?: 'User'
    user_id: number
    name: string
    email: string
    phone: string
    active: boolean
    address: {
      __typename?: 'Address'
      street?: string | null
      city?: string | null
      zip?: string | null
      country?: string | null
    }
    profile_image: { __typename?: 'File'; url: string }
    scanning_devices: Array<{
      __typename?: 'ScanningDevice'
      scanning_device_id: number
      name: string
      category: string
    }>
  }
}

export type PermissionsFragment = {
  __typename?: 'Permissions'
  team_owner: {
    __typename?: 'UserPermission'
    scans: {
      __typename?: 'ScanPermissions'
      edit: boolean
      delete: boolean
      embeds: boolean
      snapshots: boolean
      share: boolean
      submit: {
        __typename?: 'SubmitScanPermissions'
        overall: boolean
        video: boolean
        video_360: boolean
        zipped_photos: boolean
      }
      model: {
        __typename?: 'ScanModelPermissions'
        download_model: boolean
        view_scan_in_viewer: boolean
      }
    }
    projects: {
      __typename?: 'ProjectPermissions'
      create: boolean
      edit: boolean
      delete: boolean
      folder: {
        __typename?: 'ProjectFolderPermissions'
        create: boolean
        edit: boolean
        delete: boolean
      }
    }
    team: {
      __typename?: 'TeamPermissions'
      edit: boolean
      delete: boolean
      leave: boolean
      members: {
        __typename?: 'TeamMemberPermissions'
        get: boolean
        invite: boolean
        remove: boolean
        transfer_ownership: boolean
        approve_request: boolean
      }
    }
  }
  team_manager: {
    __typename?: 'UserPermission'
    scans: {
      __typename?: 'ScanPermissions'
      edit: boolean
      delete: boolean
      embeds: boolean
      snapshots: boolean
      share: boolean
      submit: {
        __typename?: 'SubmitScanPermissions'
        overall: boolean
        video: boolean
        video_360: boolean
        zipped_photos: boolean
      }
      model: {
        __typename?: 'ScanModelPermissions'
        download_model: boolean
        view_scan_in_viewer: boolean
      }
    }
    projects: {
      __typename?: 'ProjectPermissions'
      create: boolean
      edit: boolean
      delete: boolean
      folder: {
        __typename?: 'ProjectFolderPermissions'
        create: boolean
        edit: boolean
        delete: boolean
      }
    }
    team: {
      __typename?: 'TeamPermissions'
      edit: boolean
      delete: boolean
      leave: boolean
      members: {
        __typename?: 'TeamMemberPermissions'
        get: boolean
        invite: boolean
        remove: boolean
        transfer_ownership: boolean
        approve_request: boolean
      }
    }
  }
  team_member: {
    __typename?: 'UserPermission'
    scans: {
      __typename?: 'ScanPermissions'
      edit: boolean
      delete: boolean
      embeds: boolean
      snapshots: boolean
      share: boolean
      submit: {
        __typename?: 'SubmitScanPermissions'
        overall: boolean
        video: boolean
        video_360: boolean
        zipped_photos: boolean
      }
      model: {
        __typename?: 'ScanModelPermissions'
        download_model: boolean
        view_scan_in_viewer: boolean
      }
    }
    projects: {
      __typename?: 'ProjectPermissions'
      create: boolean
      edit: boolean
      delete: boolean
      folder: {
        __typename?: 'ProjectFolderPermissions'
        create: boolean
        edit: boolean
        delete: boolean
      }
    }
    team: {
      __typename?: 'TeamPermissions'
      edit: boolean
      delete: boolean
      leave: boolean
      members: {
        __typename?: 'TeamMemberPermissions'
        get: boolean
        invite: boolean
        remove: boolean
        transfer_ownership: boolean
        approve_request: boolean
      }
    }
  }
}

export type PermissionSettingFragment = {
  __typename?: 'UserPermission'
  scans: {
    __typename?: 'ScanPermissions'
    edit: boolean
    delete: boolean
    embeds: boolean
    snapshots: boolean
    share: boolean
    submit: {
      __typename?: 'SubmitScanPermissions'
      overall: boolean
      video: boolean
      video_360: boolean
      zipped_photos: boolean
    }
    model: {
      __typename?: 'ScanModelPermissions'
      download_model: boolean
      view_scan_in_viewer: boolean
    }
  }
  projects: {
    __typename?: 'ProjectPermissions'
    create: boolean
    edit: boolean
    delete: boolean
    folder: {
      __typename?: 'ProjectFolderPermissions'
      create: boolean
      edit: boolean
      delete: boolean
    }
  }
  team: {
    __typename?: 'TeamPermissions'
    edit: boolean
    delete: boolean
    leave: boolean
    members: {
      __typename?: 'TeamMemberPermissions'
      get: boolean
      invite: boolean
      remove: boolean
      transfer_ownership: boolean
      approve_request: boolean
    }
  }
}

export type TeamDetailsFragment = {
  __typename?: 'Team'
  team_id: number
  name: string
  bio: string
  brand_color: string
  logo: { __typename?: 'File'; url: string }
  members: Array<{
    __typename?: 'TeamMember'
    member_id: number
    email: string
    team_id: number
    role: string
    status: string
    requested_at?: any | null
    accepted_at?: any | null
    invited_at?: any | null
    user?: {
      __typename?: 'User'
      user_id: number
      name: string
      email: string
      profile_image: { __typename?: 'File'; thumbnail?: string | null }
      scanning_devices: Array<{
        __typename?: 'ScanningDevice'
        name: string
        scanning_device_id: number
      }>
    } | null
    profile?: { __typename?: 'Profile'; username?: string | null } | null
  }>
  setting: {
    __typename?: 'TeamSetting'
    configurations: { __typename?: 'Configurations'; viewer_watermark: string }
    permissions: {
      __typename?: 'Permissions'
      team_owner: {
        __typename?: 'UserPermission'
        scans: {
          __typename?: 'ScanPermissions'
          edit: boolean
          delete: boolean
          embeds: boolean
          snapshots: boolean
          share: boolean
          submit: {
            __typename?: 'SubmitScanPermissions'
            overall: boolean
            video: boolean
            video_360: boolean
            zipped_photos: boolean
          }
          model: {
            __typename?: 'ScanModelPermissions'
            download_model: boolean
            view_scan_in_viewer: boolean
          }
        }
        projects: {
          __typename?: 'ProjectPermissions'
          create: boolean
          edit: boolean
          delete: boolean
          folder: {
            __typename?: 'ProjectFolderPermissions'
            create: boolean
            edit: boolean
            delete: boolean
          }
        }
        team: {
          __typename?: 'TeamPermissions'
          edit: boolean
          delete: boolean
          leave: boolean
          members: {
            __typename?: 'TeamMemberPermissions'
            get: boolean
            invite: boolean
            remove: boolean
            transfer_ownership: boolean
            approve_request: boolean
          }
        }
      }
      team_manager: {
        __typename?: 'UserPermission'
        scans: {
          __typename?: 'ScanPermissions'
          edit: boolean
          delete: boolean
          embeds: boolean
          snapshots: boolean
          share: boolean
          submit: {
            __typename?: 'SubmitScanPermissions'
            overall: boolean
            video: boolean
            video_360: boolean
            zipped_photos: boolean
          }
          model: {
            __typename?: 'ScanModelPermissions'
            download_model: boolean
            view_scan_in_viewer: boolean
          }
        }
        projects: {
          __typename?: 'ProjectPermissions'
          create: boolean
          edit: boolean
          delete: boolean
          folder: {
            __typename?: 'ProjectFolderPermissions'
            create: boolean
            edit: boolean
            delete: boolean
          }
        }
        team: {
          __typename?: 'TeamPermissions'
          edit: boolean
          delete: boolean
          leave: boolean
          members: {
            __typename?: 'TeamMemberPermissions'
            get: boolean
            invite: boolean
            remove: boolean
            transfer_ownership: boolean
            approve_request: boolean
          }
        }
      }
      team_member: {
        __typename?: 'UserPermission'
        scans: {
          __typename?: 'ScanPermissions'
          edit: boolean
          delete: boolean
          embeds: boolean
          snapshots: boolean
          share: boolean
          submit: {
            __typename?: 'SubmitScanPermissions'
            overall: boolean
            video: boolean
            video_360: boolean
            zipped_photos: boolean
          }
          model: {
            __typename?: 'ScanModelPermissions'
            download_model: boolean
            view_scan_in_viewer: boolean
          }
        }
        projects: {
          __typename?: 'ProjectPermissions'
          create: boolean
          edit: boolean
          delete: boolean
          folder: {
            __typename?: 'ProjectFolderPermissions'
            create: boolean
            edit: boolean
            delete: boolean
          }
        }
        team: {
          __typename?: 'TeamPermissions'
          edit: boolean
          delete: boolean
          leave: boolean
          members: {
            __typename?: 'TeamMemberPermissions'
            get: boolean
            invite: boolean
            remove: boolean
            transfer_ownership: boolean
            approve_request: boolean
          }
        }
      }
    }
  }
  trial_period?: {
    __typename?: 'TrialPeriod'
    start_date: any
    end_date: any
    days_remaining: number
  } | null
  credits: { __typename?: 'TeamCredit'; credits: number }
  social_accounts: {
    __typename?: 'SocialAccounts'
    facebook?: string | null
    twitter?: string | null
    instagram?: string | null
    linkedin?: string | null
  }
}

export type MemberDetailsFragment = {
  __typename?: 'TeamMember'
  member_id: number
  email: string
  team_id: number
  role: string
  status: string
  requested_at?: any | null
  accepted_at?: any | null
  invited_at?: any | null
  user?: {
    __typename?: 'User'
    user_id: number
    name: string
    email: string
    profile_image: { __typename?: 'File'; thumbnail?: string | null }
    scanning_devices: Array<{
      __typename?: 'ScanningDevice'
      name: string
      scanning_device_id: number
    }>
  } | null
  profile?: { __typename?: 'Profile'; username?: string | null } | null
}

export type GetPublicScanByIdQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetPublicScanByIdQuery = {
  __typename?: 'Query'
  public_scan: {
    __typename?: 'Scan'
    scan_id: number
    name: string
    dataset_type: string
    project_id: number
    folder_id: number
    model: {
      __typename?: 'ScanModel'
      training_time: number
      processing_time: number
    }
    snapshots: Array<{
      __typename?: 'Snapshot'
      snapshot_id: number
      image: { __typename?: 'File'; url: string }
    }>
  }
}

export type GetMyScansByIdQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetMyScansByIdQuery = {
  __typename?: 'Query'
  scan: {
    __typename?: 'Scan'
    scan_id: number
    name: string
    dataset_type: string
    project_id: number
    folder_id: number
    model: {
      __typename?: 'ScanModel'
      training_time: number
      processing_time: number
    }
    snapshots: Array<{
      __typename?: 'Snapshot';
      snapshot_id: number;
      name: string;
      image: { __typename?: 'File'; url: string; thumbnail?: string | null };
      position?: { __typename?: 'Coordinates'; x: number; y: number; z: number } | null;
      rotation?: { __typename?: 'Coordinates'; x: number; y: number; z: number } | null;
      user: {
        __typename?: 'User';
        user_id: number;
        name: string;
        profile_image: { __typename?: 'File'; url: string; thumbnail?: string | null };
      };
    }>;
    input_file: { __typename?: 'File'; url: string; thumbnail?: string | null };
  };
};

export type SaveModalDefaultPositionMutationVariables = Exact<{
  scan_id: Scalars['Int']['input']
  data: SetDefaultPointDtoInput
}>

export type SaveModalDefaultPositionMutation = {
  __typename?: 'Mutation'
  set_default_point: {
    __typename?: 'Scan'
    model: {
      __typename?: 'ScanModel'
      position?: {
        __typename?: 'Coordinates'
        x: number
        y: number
        z: number
      } | null
      rotation?: {
        __typename?: 'Coordinates'
        x: number
        y: number
        z: number
      } | null
    }
  }
}

export type GetProjectByIdQueryVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type GetProjectByIdQuery = {
  __typename?: 'Query'
  project: {
    __typename?: 'Project'
    project_id: number
    name: string
    description: string
    default: boolean
    created_at: any
    thumbnail: { __typename?: 'File'; url: string; thumbnail?: string | null }
    folders: Array<{
      __typename?: 'Folder'
      folder_id: number
      name: string
      scans: Array<{
        __typename?: 'Scan'
        scan_id: number
        name: string
        created_at: any
        capture_intent: string
        category: string
        user: {
          __typename?: 'User'
          user_id: number
          name: string
          profile_image: { __typename?: 'File'; url: string }
        }
        input_file: { __typename?: 'File'; thumbnail?: string | null }
      }>
    }>
  }
}

export type SubmitSnapshotMutationVariables = Exact<{
  data: CreateSnapshotInput
}>

export type SubmitSnapshotMutation = {
  __typename?: 'Mutation'
  create_snapshot: { __typename?: 'Snapshot'; snapshot_id: number }
}

export type DeleteSnapshotMutationVariables = Exact<{
  id: Scalars['Int']['input']
}>

export type DeleteSnapshotMutation = {
  __typename?: 'Mutation'
  delete_snapshot: { __typename?: 'Snapshot'; snapshot_id: number }
}

export type StartViewerMutationVariables = Exact<{
  data: StartModelInput
}>

export type StartViewerMutation = {
  __typename?: 'Mutation'
  start_viewer: {
    __typename?: 'StartViewerResponse'
    port: {
      __typename?: 'ViewerPort'
      port_id: number
      port: number
      is_available: boolean
      scan_id?: number | null
      user_id?: number | null
      pod_id?: string | null
      worker_id?: string | null
      user?: {
        __typename?: 'User'
        user_id: number
        name: string
        email: string
        profile_image: {
          __typename?: 'File'
          url: string
          thumbnail?: string | null
        }
      } | null
    }
    scan: {
      __typename?: 'Scan'
      scan_id: number
      name: string
      capture_intent: string
      category: string
      dataset_type: string
      default: boolean
      visibility: string
      project_id: number
      folder_id: number
      created_at: any
      model: {
        __typename?: 'ScanModel'
        model_id: number
        status: string
        is_failed: boolean
        failed_reason: string
        processing_time: number
        training_time: number
        position?: {
          __typename?: 'Coordinates'
          x: number
          y: number
          z: number
        } | null
        rotation?: {
          __typename?: 'Coordinates'
          x: number
          y: number
          z: number
        } | null
        default_pos_img?: {
          __typename?: 'File'
          url: string
          thumbnail?: string | null
        } | null
      }
      user: {
        __typename?: 'User';
        user_id: number;
        name: string;
        email: string;
        profile_image: { __typename?: 'File'; url: string; thumbnail?: string | null };
      };
      input_file: { __typename?: 'File'; url: string; thumbnail?: string | null };
    };
    watermark: { __typename?: 'Watermark'; url: string };
  };
};

export type GetViewerPortQueryVariables = Exact<{
  port_id: Scalars['Float']['input']
}>

export type GetViewerPortQuery = {
  __typename?: 'Query'
  viewer_port: {
    __typename?: 'ViewerPort'
    port: number
    pod_id?: string | null
  }
}

export const MemberDetailsFragmentDoc = `
    fragment MemberDetails on TeamMember {
  member_id
  email
  user {
    user_id
    name
    email
    profile_image {
      thumbnail
    }
    scanning_devices {
      name
      scanning_device_id
    }
  }
  profile {
    username
  }
  team_id
  role
  status
  requested_at
  accepted_at
  invited_at
}
    `
export const ProfileDetailsFragmentDoc = `
    fragment ProfileDetails on Profile {
  profile_id
  name
  type
  username
  member {
    ...MemberDetails
  }
  profile_image {
    url
  }
}
    ${MemberDetailsFragmentDoc}`
export const AddressDetailsFragmentDoc = `
    fragment AddressDetails on Address {
  street
  city
  zip
  country
}
    `
export const ScanningDeviceDetailsFragmentDoc = `
    fragment ScanningDeviceDetails on ScanningDevice {
  scanning_device_id
  name
  category
}
    `
export const UserTokenDetailsFragmentDoc = `
    fragment UserTokenDetails on UserWithToken {
  user_id
  token
  name
  email
  phone
  active
  token
  profile {
    ...ProfileDetails
  }
  address {
    ...AddressDetails
  }
  profile_image {
    url
  }
  scanning_devices {
    ...ScanningDeviceDetails
  }
}
    ${ProfileDetailsFragmentDoc}
${AddressDetailsFragmentDoc}
${ScanningDeviceDetailsFragmentDoc}`
export const ConfigurationsFragmentDoc = `
    fragment Configurations on Configurations {
  viewer_watermark
}
    `
export const UserDetailsFragmentDoc = `
    fragment UserDetails on User {
  user_id
  name
  email
  phone
  active
  address {
    ...AddressDetails
  }
  profile_image {
    url
  }
  scanning_devices {
    ...ScanningDeviceDetails
  }
}
    ${AddressDetailsFragmentDoc}
${ScanningDeviceDetailsFragmentDoc}`
export const ScanSnapShotFragmentDoc = `
    fragment ScanSnapShot on Snapshot {
  snapshot_id
  name
  image {
    url
    thumbnail
  }
  user {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`
export const PermissionSettingFragmentDoc = `
    fragment PermissionSetting on UserPermission {
  scans {
    submit {
      overall
      video
      video_360
      zipped_photos
    }
    edit
    delete
    embeds
    snapshots
    share
    model {
      download_model
      view_scan_in_viewer
    }
  }
  projects {
    create
    edit
    delete
    folder {
      create
      edit
      delete
    }
  }
  team {
    members {
      get
      invite
      remove
      transfer_ownership
      approve_request
    }
    edit
    delete
    leave
  }
}
    `
export const PermissionsFragmentDoc = `
    fragment Permissions on Permissions {
  team_owner {
    ...PermissionSetting
  }
  team_manager {
    ...PermissionSetting
  }
  team_member {
    ...PermissionSetting
  }
}
    ${PermissionSettingFragmentDoc}`
export const TeamDetailsFragmentDoc = `
    fragment TeamDetails on Team {
  team_id
  name
  bio
  brand_color
  logo {
    url
  }
  members {
    ...MemberDetails
  }
  setting {
    configurations {
      viewer_watermark
    }
    permissions {
      ...Permissions
    }
  }
  trial_period {
    start_date
    end_date
    days_remaining
  }
  credits {
    credits
  }
  social_accounts {
    facebook
    twitter
    instagram
    linkedin
  }
}
    ${MemberDetailsFragmentDoc}
${PermissionsFragmentDoc}`
export const GoogleLoginDocument = `
    mutation GoogleLogin($data: GoogleLoginInput!) {
  google_login(data: $data) {
    user_id
    name
    email
    token
  }
}
    `
export const AppleLoginDocument = `
    mutation AppleLogin($data: AppleLoginInput!) {
  apple_login(data: $data) {
    user_id
    name
    email
    token
  }
}
    `
export const SwitchProfileDocument = `
    mutation SwitchProfile($id: Int!) {
  switch_profile(id: $id) {
    user_id
    name
    email
    phone
    active
    address {
      city
      country
      street
      zip
    }
    profile_image {
      url
    }
    scanning_devices {
      scanning_device_id
      name
      category
    }
    token
    profile {
      profile_id
      name
      type
      username
      profile_image {
        url
      }
    }
    waiting_list
    auth_type
    phone_verified
    email_verified
  }
}
    `
export const LoginDocument = `
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    user_id
    name
    email
    phone
    active
    address {
      city
      country
      street
      zip
    }
    profile_image {
      url
    }
    scanning_devices {
      scanning_device_id
      name
      category
    }
    token
    profile {
      profile_id
      name
      type
      username
      profile_image {
        url
      }
    }
    waiting_list
    auth_type
    phone_verified
    email_verified
  }
}
    `
export const VerifyTokenDocument = `
    query VerifyToken {
  verify_token {
    ...UserDetails
    waiting_list
    auth_type
    phone_verified
    email_verified
  }
  team {
    ...TeamDetails
  }
  profile {
    profile_id
    name
    type
    username
    profile_image {
      url
      thumbnail
    }
  }
}
    ${UserDetailsFragmentDoc}
${TeamDetailsFragmentDoc}`
export const GetPublicScanByIdDocument = `
    query GetPublicScanByID($id: Int!) {
  public_scan(id: $id) {
    scan_id
    name
    dataset_type
    model {
      training_time
      processing_time
    }
    project_id
    folder_id
    snapshots {
      snapshot_id
      image {
        url
      }
    }
  }
}
    `
export const GetMyScansByIdDocument = `
    query GetMyScansByID($id: Int!) {
  scan(id: $id) {
    scan_id
    name
    dataset_type
    model {
      training_time
      processing_time
    }
    project_id
    folder_id
    snapshots {
      snapshot_id
      name
      image {
        url
        thumbnail
      }
      position {
        x
        y
        z
      }
      rotation {
        x
        y
        z
      }
      user {
        user_id
        name
        profile_image {
          url
          thumbnail
        }
      }
    }
    input_file {
      url
      thumbnail
    }
  }
}
    `
export const SaveModalDefaultPositionDocument = `
    mutation SaveModalDefaultPosition($scan_id: Int!, $data: SetDefaultPointDtoInput!) {
  set_default_point(scan_id: $scan_id, data: $data) {
    model {
      position {
        x
        y
        z
      }
      rotation {
        x
        y
        z
      }
    }
  }
}
    `
export const GetProjectByIdDocument = `
    query GetProjectByID($id: Int!) {
  project(id: $id) {
    project_id
    name
    description
    default
    created_at
    thumbnail {
      url
      thumbnail
    }
    folders {
      folder_id
      name
      scans {
        scan_id
        name
        user {
          user_id
          name
          profile_image {
            url
          }
        }
        input_file {
          thumbnail
        }
        created_at
        capture_intent
        category
      }
    }
  }
}
    `
export const SubmitSnapshotDocument = `
    mutation SubmitSnapshot($data: CreateSnapshotInput!) {
  create_snapshot(data: $data) {
    snapshot_id
  }
}
    `
export const DeleteSnapshotDocument = `
    mutation DeleteSnapshot($id: Int!) {
  delete_snapshot(snapshot_id: $id) {
    snapshot_id
  }
}
    `
export const StartViewerDocument = `
    mutation StartViewer($data: StartModelInput!) {
  start_viewer(data: $data) {
    port {
      port_id
      port
      is_available
      scan_id
      user_id
      pod_id
      worker_id
      user {
        user_id
        name
        email
        profile_image {
          url
          thumbnail
        }
      }
    }
    scan {
      scan_id
      name
      capture_intent
      category
      dataset_type
      default
      visibility
      project_id
      folder_id
      created_at
      model {
        model_id
        status
        is_failed
        failed_reason
        processing_time
        training_time
        position {
          x
          y
          z
        }
        rotation {
          x
          y
          z
        }
        default_pos_img {
          url
          thumbnail
        }
      }
      user {
        user_id
        name
        email
        profile_image {
          url
          thumbnail
        }
      }
      input_file {
        url
        thumbnail
      }
    }
    watermark {
      url
    }
  }
}
    `
export const GetViewerPortDocument = `
    query GetViewerPort($port_id: Float!) {
  viewer_port(port_id: $port_id) {
    port
    pod_id
  }
}
    `

const injectedRtkApi = gqlApi.injectEndpoints({
  endpoints: build => ({
    GoogleLogin: build.mutation<
      GoogleLoginMutation,
      GoogleLoginMutationVariables
    >({
      query: variables => ({ document: GoogleLoginDocument, variables })
    }),
    AppleLogin: build.mutation<AppleLoginMutation, AppleLoginMutationVariables>(
      {
        query: variables => ({ document: AppleLoginDocument, variables })
      }
    ),
    SwitchProfile: build.mutation<
      SwitchProfileMutation,
      SwitchProfileMutationVariables
    >({
      query: variables => ({ document: SwitchProfileDocument, variables })
    }),
    Login: build.mutation<LoginMutation, LoginMutationVariables>({
      query: variables => ({ document: LoginDocument, variables })
    }),
    VerifyToken: build.query<
      VerifyTokenQuery,
      VerifyTokenQueryVariables | void
    >({
      query: variables => ({ document: VerifyTokenDocument, variables })
    }),
    GetPublicScanByID: build.query<
      GetPublicScanByIdQuery,
      GetPublicScanByIdQueryVariables
    >({
      query: variables => ({ document: GetPublicScanByIdDocument, variables })
    }),
    GetMyScansByID: build.query<
      GetMyScansByIdQuery,
      GetMyScansByIdQueryVariables
    >({
      query: variables => ({ document: GetMyScansByIdDocument, variables })
    }),
    SaveModalDefaultPosition: build.mutation<
      SaveModalDefaultPositionMutation,
      SaveModalDefaultPositionMutationVariables
    >({
      query: variables => ({
        document: SaveModalDefaultPositionDocument,
        variables
      })
    }),
    GetProjectByID: build.query<
      GetProjectByIdQuery,
      GetProjectByIdQueryVariables
    >({
      query: variables => ({ document: GetProjectByIdDocument, variables })
    }),
    SubmitSnapshot: build.mutation<
      SubmitSnapshotMutation,
      SubmitSnapshotMutationVariables
    >({
      query: variables => ({ document: SubmitSnapshotDocument, variables })
    }),
    DeleteSnapshot: build.mutation<
      DeleteSnapshotMutation,
      DeleteSnapshotMutationVariables
    >({
      query: variables => ({ document: DeleteSnapshotDocument, variables })
    }),
    StartViewer: build.mutation<
      StartViewerMutation,
      StartViewerMutationVariables
    >({
      query: variables => ({ document: StartViewerDocument, variables })
    }),
    GetViewerPort: build.query<GetViewerPortQuery, GetViewerPortQueryVariables>(
      {
        query: variables => ({ document: GetViewerPortDocument, variables })
      }
    )
  })
})

export { injectedRtkApi as gqlApi }
