import useThemeImages from 'assets/images'
import { useMemo, useState } from 'react'
import { IsStagingServer } from 'shared/constants/const'
import useAuth from 'shared/hooks/useAuth'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { ProfileTypes } from 'shared/types/profile/profile.type'
import useWalkthroughSteps from 'shared/utils/walkthrough'
import { toggleSidebar } from 'store/slices/app/appSlice'
import { selectOverlayTab } from 'store/slices/dashboard/dashboardSlice'
import { setData as scanSetData } from 'store/slices/scan/scanReducer'
import { showTutorial } from 'store/slices/utils/tutorialSlice'

interface ISidebarItem {
  icon: any
  title: string
  url?: string
  selector?: string
  iconActiveClass: string
  onClick?: any
}

const useSidebar = () => {
  const { user, userProfile, currentTeam } = useAuth()
  const {
    HomeIcon,
    ImageIcon,
    NotesIcon,
    PlayCircleIcon,
    RoamingIcon,
    VideoIcon
  } = useThemeImages()

  const clientId = useAppSelector(
    state => state.threeJs.websocketState.client_id
  )
  const spectatingClientId = useAppSelector(
    state => state.threeJs.websocketState.spectating_client_id
  )

  const { HOMEPAGE_STEPS } = useWalkthroughSteps()

  const dispatch = useAppDispatch()
  const { is_logged_in, open, is_visible, has_project } = useAppSelector(
    state => ({
      is_logged_in: state.userSignInStatus.isLoggedIn,
      open: state.app.sidebar.is_open,
      is_visible: state.app.sidebar.is_visible,
      has_project: state.scan.scan?.project_id !== undefined
    })
  )

  const isTeamProfile = useMemo(() => {
    return (
      userProfile != null &&
      [ProfileTypes.TeamMember, ProfileTypes.TeamOwner].includes(
        userProfile.type as any
      )
    )
  }, [userProfile])

  const isStarterProfile = useMemo(() => {
    return (
      userProfile != null &&
      [ProfileTypes.Starter].includes(userProfile.type as any)
    )
  }, [userProfile])

  const { scan_name } = useAppSelector((state) => ({
    scan_name: state.scan.scan?.name ?? '',
  }));

  const [selectedTab, setSelectedTab] = useState(1)

  const dashboardSelectedTab = useAppSelector(
    state => state.dashboard.selected_tab
  )

  const menus = useMemo<ISidebarItem[]>(() => {
    const isTeamMember =
      userProfile != null &&
      ProfileTypes.TeamMember === (userProfile.type as any)

    return [
      {
        icon: <RoamingIcon />,
        title: 'Free Roam',
        // url: ROOT_PAGE_URL.FREE_ROAM,
        selector: 'free_roam_tab',
        iconActiveClass: 'group-[.active]:text-[#6CBF64]',
        onClick: () => {
          dispatch(selectOverlayTab('free_roam_tab'))
        }
      },
      ...(!isStarterProfile &&
      (currentTeam?.setting?.permissions[
        isTeamMember ? 'team_member' : 'team_owner'
      ]?.scans?.snapshots ??
        true)
        ? [
            {
              icon: <ImageIcon height={24} width={24} />,
              title: 'Snapshots',
              selector: 'snapshots_tab',
              iconActiveClass: 'group-[.active]:text-[#C6BA54]',
              onClick: () => {
                dispatch(selectOverlayTab('snapshots_tab'))
              }
            }
          ]
        : []),
      ...(IsStagingServer
        ? [
            {
              icon: <VideoIcon />,
              title: 'Video Capture',
              // url: ROOT_PAGE_URL.VIDEO_CAPTURE,
              selector: 'video_capture_tab',
              iconActiveClass: 'group-[.active]:text-[#7977F5]'
            },
            {
              icon: <HomeIcon height={22} width={22} />,
              title: 'Object Detection',
              // url: ROOT_PAGE_URL.OBJECT_DETECTION,
              selector: 'object_detection_tab',
              iconActiveClass: 'group-[.active]:text-[#AB6BEB]'
            },
            {
              icon: <NotesIcon />,
              title: 'Notes',
              // url: ROOT_PAGE_URL.NOTES,
              selector: 'notes_tab',
              iconActiveClass: 'group-[.active]:text-[#D24C5C]'
            },
            {
              icon: <PlayCircleIcon />,
              title: 'Recordings',
              // url: ROOT_PAGE_URL.RECORDINGS,
              selector: 'recordings_tab',
              iconActiveClass: 'group-[.active]:text-[#CF8A6C]'
            }
          ]
        : [])
    ]
  }, [isStarterProfile])

  const toggleSidebarVisibility = () => {
    dispatch(toggleSidebar())
  }

  const handleHelpClick = () => {
    setSelectedTab(1)
    dispatch(showTutorial(HOMEPAGE_STEPS))
  }

  const handleControlsClick = () => {
    dispatch(
      scanSetData({
        path: 'show_instructions',
        data: true
      })
    )
  }

  return {
    is_logged_in,
    open,
    is_visible,
    toggleSidebarVisibility,
    menus,
    user,
    handleControlsClick,
    handleHelpClick,
    isTeamProfile,
    has_project,
    selectedTab,
    setSelectedTab,
    scan_name,
    dashboardSelectedTab,
    clientId,
    spectatingClientId
  }
}

export default useSidebar
