import { Button, type ButtonProps, CircularProgress } from '@mui/material'

export interface ILoaderButtonProps extends ButtonProps {
  loading?: boolean
  loadingText?: string
}

const LoaderButton = (props: ILoaderButtonProps) => {
  const { loading = false, loadingText = 'Loading...', ...others } = props

  return (
    <Button disabled={loading} {...others}>
      <CircularProgress
        size={20}
        color="inherit"
        sx={{ mr: 1 }}
        style={{ display: loading ? 'inline-block' : 'none' }}
      />
      {loading ? loadingText : props.children}
    </Button>
  )
}

export default LoaderButton
