import { FormControlLabel } from '@mui/material'
import { useState } from 'react'

import ThemeCheckbox from '../ThemeCheckbox/ThemeCheckbox'

interface I {
  name: string
  value?: boolean
  onChange: (e: any) => void
  label: string
  sxStyle?: any
}

const CheckboxWithLabel = ({
  name,
  value,
  onChange,
  label,
  sxStyle = {}
}: I) => {
  const [checked, setChecked] = useState(value ?? false)

  return (
    <FormControlLabel
      onChange={(e: any) => {
        setChecked(e.target.checked)
        onChange(e)
      }}
      sx={sxStyle}
      className={`inline-checkbox p-4 ${checked ? 'checked' : ''}`}
      control={<ThemeCheckbox className="pl-0" defaultChecked={value} />}
      label={label}
      name={name}
    />
  )
}

export default CheckboxWithLabel
