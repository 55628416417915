import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import axios from 'axios'
import { useEffect, useRef } from 'react'
import {
  removeVideoFromUploads,
  setInitialUpload,
  setUploadLoading,
  setUploadProgress
} from 'store/slices/utils/fileUploadSlice'

import { useAppDispatch, useAppSelector } from './useRedux'

const CHUNK_SIZE = 1024 * 1024 * 10

const ENDPOINT = process.env.REACT_APP_FILE_UPLOAD_ENDPOINT ?? ''

const useFileUploader = () => {
  const uppyRef = useRef(new Uppy({ debug: true, autoProceed: true }))
  const dispatch = useAppDispatch()
  const loading = useAppSelector(state => state.fileUpload.loading)

  useEffect(() => {
    return () => {
      uppyRef.current.cancelAll()
    }
  }, [])

  const uploadFile = async (file?: File): Promise<any> => {
    if (file == null) throw new Error('No file provided')
    dispatch(setUploadLoading(true))
    return await new Promise((resolve, reject) => {
      try {
        dispatch(
          setInitialUpload({
            videoId: file.name,
            payload: {
              uploadStatus: 'uploading'
            }
          })
        )

        // send this url to uppy with the file
        const uppy = new Uppy({ debug: true, autoProceed: true })
        uppy.addFile({
          name: file?.name,
          type: file?.type,
          data: file
        })
        uppy.use(Tus, {
          endpoint: ENDPOINT,
          chunkSize: CHUNK_SIZE,
          headers: {
            'file-name': file?.name,
            'file-type': file?.type
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        uppy.on('complete', async (result: any) => {
          if (result.successful?.length > 0) {
            let uuid = result.successful[0].uploadURL.split('/').pop()
            uuid = uuid?.split('?').shift() ?? ''
            if (uuid != null && file != null) {
              const res = await axios.get(`${ENDPOINT}/metadata/${uuid}`)
              if (res.status === 200) {
                dispatch(
                  removeVideoFromUploads({
                    videoId: file.name,
                    payload: ''
                  })
                )
                resolve(res)
              } else {
                dispatch(
                  removeVideoFromUploads({
                    videoId: file.name,
                    payload: ''
                  })
                )
                reject(res)
              }
            }
          }
          dispatch(setUploadLoading(false))
        })
        uppy.on('progress', (e: any) => {
          dispatch(
            setUploadProgress({
              videoId: file.name,
              payload: {
                progress: e
              }
            })
          )
        })
        uppy.on('error', (error: any) => {
          dispatch(
            removeVideoFromUploads({
              videoId: file.name,
              payload: ''
            })
          )
          reject(error)
        })
      } catch (error) {
        dispatch(setUploadLoading(false))
        dispatch(
          removeVideoFromUploads({
            videoId: file.name,
            payload: ''
          })
        )
        reject(error)
      }
    })
  }

  return { uploadFile, loading }
}

export default useFileUploader
