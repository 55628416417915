import { Box, Button, Modal } from '@mui/material'
import Step1 from 'assets/images/control-images/1.png'
import Step2 from 'assets/images/control-images/2.png'
import Step3 from 'assets/images/control-images/3.png'
import { ReactComponent as Soar3dIcon } from 'assets/images/control-images/Layer_1-2.svg'
import Controls from 'assets/images/controls.png'
import Logo from 'assets/images/logo.png'
import { memo, useEffect, useState } from 'react'
import CheckboxWithLabel from 'shared/components/CheckboxWithLabel/CheckboxWithLabel'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import useScreen from 'shared/hooks/useScreen'
import { toggleShowControlsOnStart } from 'store/slices/auth/userInfoSlice'
import { setData } from 'store/slices/scan/scanReducer'

const InstructionsDialog = () => {
  const dispatch = useAppDispatch()
  const [step, setStep] = useState(0)

  const isShowControlsOnStart = useAppSelector(
    state => state.userInfo.isShowControlsOnStart
  )

  const handleClose = () => {
    dispatch(
      setData({
        path: 'show_instructions',
        data: false
      })
    )
  }

  const open = useAppSelector(state => state.scan.show_instructions)

  useEffect(() => {
    if (!open) {
      setStep(0)
    }
  }, [open])

  const { isMobile } = useScreen()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="z-[1000]"
    >
      <Box
        className="ViewportConstrolsModal-box"
        height={isMobile ? '100%' : 'auto'}
        width={isMobile ? '100%' : '800px'}
        borderRadius={isMobile ? 0 : '20px'}
      >
        <center className="hidden lg:block">
          <img
            style={{ height: 37, margin: 'auto' }}
            src={Logo}
            alt="The favicon."
          />
          <img
            style={{ width: '100%', paddingTop: '30px', margin: 'auto' }}
            src={Controls}
            alt="Controls diagram"
          />
          {/* <p>Feel free to click out of this tab. Please wait 10-15 seconds for the content to load in.</p> */}
          <div className="mt-6">
            <CheckboxWithLabel
              name="controls-visibility"
              sxStyle={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                  color: '#BFBEBE'
                }
              }}
              onChange={(e: any) => {
                if (e.target.checked) {
                  window.localStorage.setItem('controls', 'false');
                } else {
                  window.localStorage.removeItem('controls');
                }
                
                dispatch(toggleShowControlsOnStart(!e.target.checked));
              }}
              value={!isShowControlsOnStart}
              label="Don't show me again"
            />
          </div>
        </center>
        <div className="flex h-full flex-col lg:hidden">
          <div className="flex items-start justify-between mb-6">
            <Soar3dIcon />
            <span
              className="bg-white bg-opacity-20 h-6 px-[14px] inline-flex justify-center items-center text-white text-sm rounded-[20px]"
              onClick={() => {
                handleClose()
              }}
            >
              Skip
            </span>
          </div>
          <div className="flex-1">
            <ContentTab
              step={step}
              index={0}
              title="Change View"
              content="To look around the 3d model, you can use single finger touch."
              img={Step1}
              onClick={() => {
                setStep(1)
              }}
            />
            <ContentTab
              step={step}
              index={1}
              title="Translate"
              content="To move around the 3d model, you can move double fingers in the same direction."
              img={Step2}
              onClick={() => {
                setStep(2)
              }}
            />
            <ContentTab
              step={step}
              index={2}
              title="Zoom"
              content="To zoom in and out around the 3d model, you can move double fingers in the opposite directions."
              img={Step3}
              onClick={() => {
                handleClose()
              }}
            />
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default memo(InstructionsDialog)

interface Props {
  step: number
  index: number
  title: string
  content: string
  img?: string
  onClick?: () => void
}

const ContentTab = (props: Props) => {
  const { step, index, title, content, img, onClick } = props

  const dispatch = useAppDispatch()

  const isShowControlsOnStart = useAppSelector(
    state => state.userInfo.isShowControlsOnStart
  )

  if (step !== index) return null

  return (
    <div className="flex h-full flex-col">
      <div className="text-white text-center">
        <h4 className="text-lg leading-6 font-medium">{title}</h4>
        <hr className="my-4 border-white border-opacity-20" />
        <p className="text-md leading-5">{content}</p>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <img src={img} />
      </div>
      <div className="flex-col flex items-center text-white space-y-2">
        <CheckboxWithLabel
          name="controls-visibility-mobile"
          sxStyle={{
            '&': {
              margin: 0
            },
            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
              color: '#DDDDDD'
            }
          }}
          onChange={(e: any) => {
            dispatch(toggleShowControlsOnStart(e.target.checked))
          }}
          value={!isShowControlsOnStart}
          label="Don't show me again"
        />
        <Button
          variant="contained"
          className="h-[52px] !rounded-[20px]"
          fullWidth
          onClick={onClick}
        >
          {step === 2 ? 'Start Your Viewing Experience' : 'Next'}
        </Button>
      </div>
    </div>
  )
}
