import ProfilePic from 'assets/images/dummy-profile.png'

import { type IPerson } from '../ShareViewer.type'

interface IPersonDetailsListItemProps {
  person: IPerson
}

const PersonDetailsListItem = (props: IPersonDetailsListItemProps) => {
  const { person } = props

  return (
    <div className="flex items-center">
      <img
        src={person?.user?.profile_image?.url ?? ProfilePic}
        className="w-8 h-8 rounded-full border-[0.42px] border-[#E9E9E9] overflow-hidden"
      />
      <div className="flex-1 text-sm text-[#5E5D5D] mx-3">{person?.user?.name ?? person.email}</div>
    </div>
  )
}

export default PersonDetailsListItem
