import { Badge, Box, Button, Typography } from '@mui/material';
import useThemeImages from 'assets/images';
import LOGO from 'assets/images/logo.png';
import ReactPlayer from 'react-player/lazy';
import DotLoader from 'shared/loader/DotLoader';

import useEmbedScreen from './useEmbedScreen';

const EmbedScreen = () => {
  const {
    scan,
    onStartViewer,
    isLoading,
    play,
    togglePlay,
    isReady,
    readyToPlay,
  } = useEmbedScreen();

  const { PlayButtonIcon, CloseBlackIcon, LogoFirstLetter, ArrowUpRight } = useThemeImages();

  return (
    <div className="w-full h-[var(--app-height)] relative">
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'var(--app-height)',
            color: 'white',
            fontSize: '1.5rem',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 1000,
            backgroundColor: '#1E1E1E',
          }}
        >
          <img
            src={LOGO}
            style={{
              height: 40,
            }}
            alt="Logo"
          />

          <span style={{ marginTop: 20, textAlign: 'center' }} className="text-lg lg:text-[20px]">
            Please Wait
            <DotLoader />
          </span>
        </Box>
      ) : (
        <>
          <div className="absolute top-4 left-4 z-[100]">
            <LogoFirstLetter height="50px" width="50px" />
          </div>
          <Button
            aria-describedby="start_viewer"
            variant="contained"
            color="primary"
            className="!font-normal !text-lg !w-fit !h-fit !leading-normal !absolute !top-4 !right-2 !z-[5] !bg-transparent"
            onClick={onStartViewer}
          >
            <Badge
              color="success"
              badgeContent="Beta"
              sx={{
                '& .MuiBadge-badge': {
                  left: -28,
                  top: 0,
                  right: 'unset',
                  position: 'absolute',
                  width: 35,
                  height: 15,
                  fontSize: 10,
                },
              }}
            >
              <div className="flex items-center justify-center pl-1 gap-3 !bg-[#e4e6ff] w-[142px] h-[40px] rounded-lg">
                <Typography
                  style={{
                    fontSize: '15px',
                    fontFamily: 'inherit',
                    color: '#7680FF',
                  }}
                >
                  Start Viewer
                </Typography>
                <ArrowUpRight width={11} height={11} />
              </div>
            </Badge>
          </Button>
          {scan && scan?.input_file?.thumbnail != undefined && (
            <img src={scan!.input_file.thumbnail!} className="w-full h-full object-cover" />
          )}
          {scan && scan!.input_file?.url != undefined && (
            <div className="video-player animate-fade-in h-full w-full max-h-full group-[.play-video]:block absolute top-0 left-0 video-cover">
              <ReactPlayer
                url={scan!.input_file.url}
                width="100%"
                height="100%"
                playing={play}
                loop
                muted
                onReady={readyToPlay}
                style={{
                  height: 'unset !important',
                  width: 'unset !important',
                }}
              />
            </div>
          )}
          {!isReady ? (
            <></>
          ) : play ? (
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center space-x-10 w-full">
              <div
                className="bg-white bg-opacity-70 rounded-full h-[64px] w-[64px] flex items-center justify-center cursor-pointer z-[4]"
                onClick={togglePlay}
              >
                <CloseBlackIcon width="30px" height="30px" />
              </div>
            </div>
          ) : (
            scan &&
            scan!.input_file?.url != undefined && (
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center space-x-10 w-full">
                <div
                  className="bg-white bg-opacity-70 rounded-full h-[64px] w-[64px] flex items-center justify-center cursor-pointer z-[4]"
                  onClick={togglePlay}
                >
                  <PlayButtonIcon width="30px" height="30px" />
                </div>
              </div>
            )
          )}
          <div
            className={`absolute bottom-0 left-0 z-[100] w-[calc(100%-40px)] mx-[20px] mb-[20px] rounded-[12px] py-4 px-6 text-md text-[#DDDDDD]`}
            style={{
              background: 'linear-gradient(0deg, #1E1E1E 0%, #1E1E1E 100%), #FFF',
              boxShadow:
                '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
            }}
          >
            <span className="text-[#7680FF]">{scan?.scan_name}</span> Captured by{' '}
            {scan?.user?.name ?? 'Anonymous'}
          </div>
        </>
      )}
    </div>
  );
};

export default EmbedScreen;
