import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

interface IPayload {
  videoId: string
  payload: any
}

interface IUpload {
  uploadStatus: 'idle'
  progress: number
}

interface IUploads {
  uploads: Record<string, IUpload>
  loading: boolean
}

const initialUploadState: IUpload = {
  uploadStatus: 'idle',
  progress: 0
}

const initialState: IUploads = {
  uploads: {},
  loading: false
}

const fileUploadSlice = createSlice({
  name: 'fileUploadSlice',
  initialState,
  reducers: {
    setInitialUpload: (state, action: PayloadAction<IPayload>) => {
      const { videoId, payload } = action.payload

      if (!(videoId in state.uploads)) {
        const newUpload: IUpload = {
          ...initialUploadState,
          ...payload
        }

        state.uploads[videoId] = newUpload
      }
    },
    setUploadLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setUploadStatus: (state, action: PayloadAction<IPayload>) => {
      const { videoId, payload } = action.payload

      state.uploads[videoId].uploadStatus = payload
    },
    setUploadProgress: (state, action: PayloadAction<IPayload>) => {
      const { videoId, payload } = action.payload

      state.uploads[videoId].progress = payload.progress
    },
    removeVideoFromUploads: (state, action: PayloadAction<IPayload>) => {
      const { videoId } = action.payload

      if (videoId in state.uploads) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete state.uploads[videoId]
      }
    }
  }
})

export const {
  setInitialUpload,
  setUploadStatus,
  setUploadProgress,
  setUploadLoading,
  removeVideoFromUploads
} = fileUploadSlice.actions

export default fileUploadSlice.reducer
