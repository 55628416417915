import { useGoogleLogin } from '@react-oauth/google'
import { useEffect, useMemo } from 'react'
import { useGoogleLoginMutation } from 'shared/graphql/utils/enhanced'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { rtkHandler } from 'shared/utils/handlers'
import { openLogInDialog } from 'store/slices/app/appSlice'

const useBanner = () => {
  const dispatch = useAppDispatch()

  const [sendGoogleLoginCode, googleLoginRes] = useGoogleLoginMutation()

  const { isLoggedIn, isSideBarOpen, isSideBarVisible } = useAppSelector(
    state => ({
      isLoggedIn: state.userSignInStatus.isLoggedIn,
      isSideBarOpen: state.app.sidebar.is_open,
      isSideBarVisible: state.app.sidebar.is_visible
    })
  )

  const { client_id, spectating_client_id, viewers } = useAppSelector(
    state => state.threeJs.websocketState
  )

  const doGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      sendGoogleLoginCode({
        data: {
          code: codeResponse.code,
          redirect_uri: process.env.REACT_APP_SOCIAL_AUTH_REDIRECT_URI ?? ''
        }
      })
        .then(() => {
          console.log('success')
        })
        .catch(() => {
          console.log('error')
        })
    },
    flow: 'auth-code'
  })

  const doEmailSignIn = () => {
    dispatch(openLogInDialog())
  }

  const spectateClientName = useMemo(() => {
    if (viewers.length > 0) {
      const spectator = viewers.filter(
        viewer => viewer.client_id === spectating_client_id
      )

      return spectator.length > 0 ? spectator[0].name : 'Anonymous'
    }

    return 'Anonymous'
  }, [spectating_client_id, viewers])

  useEffect(() => {
    rtkHandler(googleLoginRes, {
      onSuccess(res) {
        if (
          ('profile' in res.google_login && res.google_login.profile == null) ||
          !('profile' in res.google_login)
        ) {
          window.open(
            `${process.env.REACT_APP_HOME_PAGE_URL}/account-creation`,
            '_self'
          )
        }
      },
      onError(res) {
        console.log('Error')
      }
    })
  }, [googleLoginRes])

  return {
    isLoggedIn,
    isSideBarOpen,
    isSideBarVisible,
    doGoogleLogin,
    doEmailSignIn,
    client_id,
    spectating_client_id,
    spectateClientName
  }
}

export default useBanner
