import { Badge, Button, Tooltip, Typography } from '@mui/material'

import { type ITextIconButtonProps } from './TextIconButton.type'
import useTextIconButton from './useTextIconButton'

const TextIconButton = (props: ITextIconButtonProps) => {
  const { styles, badegStyles } = useTextIconButton(props)

  const {
    text,
    icon,
    size = 'medium',
    badgeCount,
    badgeColor = 'primary',
    className,
    onClick,
    tooltipTitle,
    tooltipPosition = 'top',
    disabled = false
  } = props

  const Wrapper = ({ children }: { children: any }) => {
    if (disabled) {
      return <div>{children}</div>
    }
    return (
      <Tooltip title={tooltipTitle} arrow placement={tooltipPosition}>
        {children}
      </Tooltip>
    )
  }

  return (
    <Wrapper>
      <Button
        onClick={onClick}
        size={size}
        // color={color}
        sx={styles}
        className={'!w-fit ' + className}
        disabled={disabled}
      >
        <Badge color={badgeColor} badgeContent={badgeCount} sx={badegStyles}>
          <div className="flex items-center justify-center pl-1 gap-2">
            <Typography style={{ fontSize: '12px', fontFamily: 'inherit' }}>
              {text}
            </Typography>
            {icon}
          </div>
        </Badge>
      </Button>
    </Wrapper>
  )
}

export default TextIconButton
