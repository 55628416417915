import { type DefaultToastOptions, toast } from 'react-hot-toast'

export const successToast = (
  message: string,
  options?: DefaultToastOptions
) => {
  toast.success(message, options ?? {})
}

export const errorToast = (message: string, options?: DefaultToastOptions) => {
  toast.error(message, options ?? {})
}
