import { Outlet } from 'react-router-dom'
import { useAppSelector } from 'shared/hooks/useRedux'
import useThemeSidebar from 'shared/hooks/useThemeSidebar'

const GuestLayout = () => {
  const { offsetPadding } = useThemeSidebar()
  const { isSideBarOpen, isSideBarVisible, isBannerVisible } = useAppSelector(
    state => ({
      isSideBarOpen: state.app.sidebar.is_open,
      isSideBarVisible: state.app.sidebar.is_visible,
      isBannerVisible: state.app.banner.is_visible
    })
  )

  return (
    <div
      className={`flex fixed top-0 left-0 w-full h-full z-10 pointer-events-none duration-300 transition-all ${offsetPadding}`}
    >
      <div
        className={`hidden md:flex sidebar sidebar--transparent ${
          !isSideBarOpen && 'sidebar--collapsed'
        } ${!isSideBarVisible && 'md:hidden'}`}
      ></div>
      <div
        className={
          'flex-1 w-full overflow-hidden pointer-events-none ' +
          (isBannerVisible ? 'mt-[80px]' : '')
        }
      >
        <Outlet />
      </div>
    </div>
  )
}

export default GuestLayout
