import { MenuItem, Select } from '@mui/material'
import useThemeImages from 'assets/images'
import { useContext } from 'react'
import { type UseControllerReturn } from 'react-hook-form'

import FieldControllerContext from '../FieldController.context'
import { type IFieldControllerProps } from '../FieldController.type'

const FSelect = () => {
  const { SelectArrow } = useThemeImages()
  const { methods, props }: any = useContext(FieldControllerContext)

  const {
    field: { name, value, onChange }
  }: UseControllerReturn = methods
  const {
    placeholder,
    options = [],
    inputVariant = 'standard'
  }: IFieldControllerProps = props

  return (
    <Select
      IconComponent={SelectArrow}
      fullWidth
      defaultValue={'Standard'}
      displayEmpty
      value={value}
      name={name}
      onChange={onChange}
      MenuProps={{
        disablePortal: true
      }}
      variant={inputVariant}
      sx={{
        '.MuiPaper-root': {
          transition: 'none !important',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
        },
        '.MuiSelect-select': {
          color: value == null ? '#dddddd' : 'currentColor',
          fontWeight: 'normal'
        }
      }}
    >
      {placeholder != null && (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default FSelect
