import type React from 'react'

interface IFieldLabelProps {
  className?: string
  children: React.ReactNode
}

const FieldLabel = ({ className, children }: IFieldLabelProps) => {
  return (
    <label
      className={`text-[#1E293B] text-sm lg:text-md font-medium block mb-1 whitespace-nowrap ${className}`}
    >
      {children}
    </label>
  )
}

export default FieldLabel
