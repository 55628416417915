import { createSlice } from '@reduxjs/toolkit'

interface Props {
  isLoggedIn: boolean
}

const initialState: Props = {
  isLoggedIn: false
}

const userSignInStatusSlice = createSlice({
  name: 'userSignInfo',
  initialState,
  reducers: {
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload
    }
  }
})

export default userSignInStatusSlice.reducer

export const { setLoggedIn } = userSignInStatusSlice.actions
