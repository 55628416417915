import { Button } from '@mui/material';
import useThemeImages from 'assets/images';
import useThemeSidebar from 'shared/hooks/useThemeSidebar';
import ShareViewer from '../ShareViewer/ShareViewer';
import useBanner from './useBanner';
import { useAppDispatch } from 'shared/hooks/useRedux';
import { useContext } from 'react';
import { WebSocketContext, sendWebsocketMessage } from 'shared/socket/WebSocket';
import { setCommonModal, setCloseCommonModal } from 'store/slices/utils/commonModalSlice';
import { setData } from 'store/slices/scan/threeJSReducer';
import IsStagingComponent from 'shared/components/IsStagingComponent/IsStagingComponent';

const Banner = () => {
  const { offsetPadding } = useThemeSidebar()
  const { GoogleIcon, LogoutIcon } = useThemeImages()

  const {
    isLoggedIn,
    doGoogleLogin,
    doEmailSignIn,
    client_id,
    spectating_client_id,
    spectateClientName
  } = useBanner()

  const dispatch = useAppDispatch()

  const websocket: any = useContext(WebSocketContext)

  return (
    <div
      className={`h-[60px] lg:h-[80px] bg-[#1E1E1E] relative z-10 border-b border-b-[#D0D5DD40] w-full flex items-center justify-between px-6 duration-300 transition-all ${offsetPadding}`}
      onClick={e => {
        if (client_id !== spectating_client_id) {
          e.stopPropagation()
          dispatch(
            setCommonModal({
              icon: <LogoutIcon width={48} height={48} />,
              title: 'Leave Spectator View',
              content: 'Do you want to leave spectator view?',
              actionButtonsDir: 'row',
              dialogPaperProps: {
                className: '!max-w-[360px]'
              },
              buttons: [
                {
                  children: 'Leave',
                  color: 'error',
                  onClick: () => {
                    sendWebsocketMessage(websocket, {
                      type: 'SpectateMessage',
                      client_id: client_id ?? 0
                    })
                    dispatch(
                      setData({
                        path: 'websocketState/spectating_client_id',
                        data: client_id ?? 0
                      })
                    )
                    dispatch(setCloseCommonModal())
                  }
                }
              ]
            })
          )
        }
      }}
    >
      <div className="md:hidden"></div>
      {isLoggedIn ? (
        client_id === spectating_client_id ? (
          <div className="hidden md:block"></div>
        ) : (
          <div className="hidden md:flex items-center gap-x-4">
            <span className="text-[#BFBEBE] text-lg">
              <span className="font-medium text-[#FFFFFFE5]">
                Spectator View:{' '}
              </span>
              You are following {spectateClientName}.
            </span>
          </div>
        )
      ) : (
        <div className="hidden md:flex items-center gap-x-4">
          <span className="text-[#FFFFFFE5] text-lg">
            <span className="font-medium">Welcome to XSpada Community!</span>
          </span>
          <div className="flex items-center gap-x-2">
            <IsStagingComponent>
              <Button
                onClick={() => doGoogleLogin()}
                variant="outlined"
                color="primary"
                sx={{
                  '&': {
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: '400',
                  },
                }}
                startIcon={<GoogleIcon width={17} height={17} />}
              >
                Continue with Google
              </Button>
            </IsStagingComponent>
            <Button
              onClick={() => {
                doEmailSignIn()
              }}
              variant="outlined"
              color="primary"
              sx={{
                '&': {
                  color: 'white',
                  fontSize: '10px',
                  fontWeight: '400'
                }
              }}
            >
              Sign up with email
            </Button>
          </div>
        </div>
      )}
      <div className="flex justify-between">
        <ShareViewer />
      </div>
    </div>
    // </div>
  )
}

export default Banner
