import { createTheme } from '@mui/material/styles'

import COLOR_PALLETE from './colorPallete'

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PALLETE.primary.DEFAULT
    },
    error: {
      main: COLOR_PALLETE.error.DEFAULT
    },
    success: {
      main: COLOR_PALLETE.success.DEFAULT
    },
    info: {
      main: COLOR_PALLETE.grey.DEFAULT
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif'
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {}
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '.MuiAutocomplete-inputRoot': {
            padding: '0 !important'
          },
          '.MuiInputBase-input': {
            paddingLeft: '10px !important',
            paddingRight: '10px !important'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true
      },
      styleOverrides: {
        root: {
          '.MuiInputBase-input': {
            height: '44px',
            padding: '6px 10px',
            boxSizing: 'border-box',
            fontSize: 14,

            '::placeholder': {
              color: '#AEADAD'
            }
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '6px',
            borderColor: '#D0D5DD'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          fontSize: 14
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14
        }
      }
    },
    MuiButton: {
      defaultProps: {
        type: 'button'
      },
      styleOverrides: {
        root: {
          borderRadius: 7,
          boxShadow: 'none !important',
          textTransform: 'none',
          whiteSpace: 'nowrap',

          '&.MuiButton-colorInherit': {
            border: '1px solid #D0D5DD',
            color: '#344054'
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(132, 137, 147, 0.7)',
            backdropFilter: 'blur(4px)'
          }
        },
        paperWidthSm: {
          maxWidth: '512px'
        },
        paperWidthXs: {
          maxWidth: '400px'
        },
        paper: {
          boxShadow:
            '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
          borderRadius: 12,
          textAlign: 'center',
          width: '100%'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 24,
          paddingBottom: 16,

          '& .MuiDialogActions-root': {
            padding: 0,
            paddingTop: 8,
            paddingBottom: 16
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
          paddingTop: 16
        }
      }
    }
  }
})

export default theme
