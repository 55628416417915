import { Dialog, DialogContent } from '@mui/material'
import { useAppSelector } from 'shared/hooks/useRedux'

import CommonModalActionButton from './partials/CommonModalActionButton'
import useCommonModal from './useCommonModal'

const CommonModal = () => {
  const {
    topElement: TopElement,
    topElementProps,
    icon,
    title,
    content,
    contentComponent,
    children,
    dialogueContentClassName,
    dialogueActionClassName,
    buttons,
    showActionButtons,
    actionButtonsDir,
    hasNegativeButton,
    negativeButtonLabel,
    negativeButtonAction,
    dialogPaperProps,
    maxWidth = 'xs',
    hasBackdrop = true
  } = useAppSelector(state => state.commonModal)
  const { open, closeModal } = useCommonModal()

  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      PaperProps={dialogPaperProps}
      sx={{
        ...(hasBackdrop
          ? {}
          : {
              '& > .MuiBackdrop-root': {
                display: 'none'
              }
            })
      }}
    >
      <DialogContent className={dialogueContentClassName}>
        {TopElement != null && <TopElement {...topElementProps} />}
        {icon != null && (
          <div className="mb-3 flex items-center justify-center">{icon}</div>
        )}
        {title != null && (
          <div className="font-semibold text-[#3F3E3E] text-xl">{title}</div>
        )}
        <div className="text-[#667085] text-md mt-2 font-thin flex flex-col space-y-5">
          {content != null && <div>{content}</div>}
          {contentComponent != null && <>{contentComponent}</>}
          {children != null && <div>{children}</div>}
        </div>
      </DialogContent>
      {showActionButtons != null && (
        <CommonModalActionButton
          buttons={buttons ?? []}
          className={dialogueActionClassName}
          cancelBtnLabel={negativeButtonLabel}
          onCancelBtnClick={() => {
            closeModal()
            if (negativeButtonAction != null) {
              negativeButtonAction()
            }
          }}
          direction={actionButtonsDir}
          hasNegativeButton={hasNegativeButton}
        />
      )}
    </Dialog>
  )
}

export default CommonModal
