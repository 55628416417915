import { CircularProgress } from '@mui/material'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { type ISnapshot } from 'shared/types/snapshot/snapshot.type'

import SnapshotCard from './partials/SnapshotCard'
import useSnapshotsScreen from './useSnapshotsScreen'

const SnapshotsScreen = () => {
  const { snapshots, isLoading } = useSnapshotsScreen()

  return (
    <div className="w-full h-full bg-[#1E1E1E] p-10 overflow-hidden">
      <h4 className="text-[#DDDDDD] text-[20px] font-semibold mb-6">
        Snapshots
      </h4>

      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <span className="text-white mx-auto">
            <CircularProgress color="inherit" size="48px" />
          </span>
        </div>
      ) : snapshots.length === 0 ? (
        <div className="flex items-center justify-center w-full h-full">
          <span className="text-white mx-auto text-lg">
            No Snapshots found...
          </span>
        </div>
      ) : (
        <PerfectScrollbar
          options={{
            suppressScrollX: true
          }}
          className={'grid grid-cols-3 gap-x-8 gap-y-6 pb-14'}
        >
          {snapshots.map((snapshot: ISnapshot) => (
            <SnapshotCard key={snapshot._id} snapshot={snapshot} />
          ))}
        </PerfectScrollbar>
      )}
    </div>
  )
}

export default SnapshotsScreen
