import { type SxProps, type Theme } from '@mui/material'
import COLOR_PALLETE from 'shared/utils/colorPallete'

import { type IAppIconButtonProps } from './AppIconButton.type'

const useAppIconButton = (props: IAppIconButtonProps) => {
  const { variant = 'default', color = 'default', bgColor } = props

  const styles: SxProps<Theme> = {
    bgcolor: variant === 'filled' ? bgColor ?? `${color}.main` : 'transparent',
    border: `${
      variant === 'filled'
        ? `1px solid ${color}.main`
        : variant === 'outlined'
        ? `1px solid ${COLOR_PALLETE.border2}`
        : ''
    }`,
    boxShadow:
      variant === 'default' ? 'none' : '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '7px',
    width: 32,
    height: 32,
    ':hover': {
      bgcolor: variant === 'filled' ? `${color}.main` : 'transparent'
    }
  }

  const badegStyles: SxProps<Theme> = {
    '& > .MuiBadge-badge': {
      height: 12,
      minWidth: 12,
      padding: '2px 3px',
      fontSize: 9,
      right: 5
    }
  }

  return { styles, badegStyles }
}

export default useAppIconButton
