import { ButtonBase } from '@mui/material'

import useGroupChat from './useGroupChat'

const GroupChat = () => {
  const { tab, onClickTab } = useGroupChat()

  return (
    <div>
      <div className="bg-[#555767] p-1 rounded-lg flex items-center relative space-x-1">
        <ButtonBase
          disableRipple
          onClick={() => {
            onClickTab('messages')
          }}
          className={`w-full h-9 !rounded-md transition-all duration-300 ${
            tab === 'messages' ? '!bg-[#1E1E1E]' : ''
          }`}
        >
          <span className="relative z-10">Messages</span>
        </ButtonBase>
        <ButtonBase
          disableRipple
          onClick={() => {
            onClickTab('comments')
          }}
          className={`w-full h-9 !rounded-md transition-all duration-300 ${
            tab === 'comments' ? '!bg-[#1E1E1E]' : ''
          }`}
        >
          <span className="relative z-10">Comments</span>
        </ButtonBase>
      </div>
    </div>
  )
}

export default GroupChat
