import 'react-perfect-scrollbar/dist/css/styles.css';

import { ThemeProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import DisableDevtool from 'disable-devtool';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import CommonModal from 'shared/components/CommonModal';
import LogInDialog from 'shared/components/LogInDialog/LogInDialog';
import ThreeJsSceneProvider from 'shared/components/ThreeJsSceneProvider/ThreeJsSceneProvider';
import WalkthroughLayout from 'shared/components/Walkthrough/WalkthroughLayout';
import { IsStagingServer } from 'shared/constants/const';
import theme from 'shared/utils/theme';

import AppRoutes from './shared/routes/routes';
import { persistor, store } from './store';

const ClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '';

function App() {
  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();

    // if (!IsStagingServer) {
    //   DisableDevtool({
    //     disableIframeParents: false
    //   })
    // }

    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThreeJsSceneProvider>
            <ThemeProvider theme={theme}>
              <GoogleOAuthProvider clientId={ClientId}>
                <Router>
                  <WalkthroughLayout>
                    <AppRoutes />
                  </WalkthroughLayout>
                  <LogInDialog />
                </Router>
                <CommonModal />
                <Toaster />
              </GoogleOAuthProvider>
            </ThemeProvider>
          </ThreeJsSceneProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
