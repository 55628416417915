import { createSlice } from '@reduxjs/toolkit'
import { type ISnapshot } from 'shared/types/snapshot/snapshot.type'

interface Props {
  selected_tab: string
  files: ISnapshot[]
  selected_file: ISnapshot | null
}

const initialState: Props = {
  selected_tab: 'free_roam_tab',
  files: [],
  selected_file: null
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    selectOverlayTab(state, action) {
      state.selected_tab = action.payload
    },
    setAllFiles(state, action) {
      state.files = action.payload
    },
    setSelectedFile(state, action) {
      state.selected_file = action.payload
    }
  }
})

export default dashboardSlice.reducer

export const { selectOverlayTab, setAllFiles, setSelectedFile } =
  dashboardSlice.actions
