import { Checkbox, type CheckboxProps } from '@mui/material'
import useThemeImages from 'assets/images'

const ThemeCheckbox = (props: CheckboxProps) => {
  const { CheckboxInActive, CheckboxActive } = useThemeImages()

  return (
    <div className="h-5 w-5 inline-flex items-center justify-center mr-2">
      <Checkbox
        icon={<CheckboxInActive />}
        checkedIcon={<CheckboxActive />}
        {...props}
      />
    </div>
  )
}

export default ThemeCheckbox
