import { createSlice } from '@reduxjs/toolkit'

interface QueryParamsState {
  model_id: string | null
}

const initialState: QueryParamsState = {
  model_id: null
}

const queryParamSlice = createSlice({
  name: 'queryParam',
  initialState,
  reducers: {
    setQueryParams: (state, action) => {
      const updatedParams = { ...state }

      state = { ...updatedParams, ...action.payload }
    }
  }
})

export const { setQueryParams } = queryParamSlice.actions

export default queryParamSlice.reducer
