import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import PageLoader from 'shared/components/PageLoader/PageLoader'
import { ROOT_PAGE_URL } from 'shared/constants/navigator'
import {
  useSwitchProfileMutation,
  useVerifyTokenQuery
} from 'shared/graphql/utils/enhanced'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { rtkHandler } from 'shared/utils/handlers'
import { logout, setUserSignInInfo } from 'store/slices/auth/authSlice'
import { setLoggedIn } from 'store/slices/auth/userSignInStatusSlice'

const Auth = () => {
  const [Component, setComponent] = useState(<PageLoader />)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const scanId = urlParams.get('scan_id')
  const userId = urlParams.get('user_id')
  const userEmail = urlParams.get('user_email')
  const embed = urlParams.get('embed')
  const profileId = urlParams.get('profile_id')
  const token = urlParams.get('token')
  const snapshotId = urlParams.get('snapshot_id')
  const mobile = urlParams.get('mobile')

  const [isTokenSkip, setIsTokenSkip] = useState(true)

  const { isLoggedIn, userDetails, existingToken } = useAppSelector(state => ({
    isLoggedIn: state.userSignInStatus.isLoggedIn,
    userDetails: state.auth.user,
    existingToken: state.auth.token
  }))

  const verifyRes = useVerifyTokenQuery(undefined, {
    skip:
      (token != null && isTokenSkip) ||
      isLoggedIn ||
      (existingToken == null && userId == null)
  })

  const [switchProfile, switchProfileRes] = useSwitchProfileMutation()

  useEffect(() => {
    if (verifyRes.data != null && !isLoggedIn) {
      if (
        (userId != null &&
          (verifyRes.data as any).verify_token.user_id !== userId) ||
        (userEmail != null &&
          (verifyRes.data as any).verify_token.email !== userEmail)
      ) {
        const queryParams = {}

        if (scanId != null) (queryParams as any).scan_id = scanId
        if (userId != null) (queryParams as any).user_id = userId
        if (userEmail != null) (queryParams as any).user_email = userEmail
        if (embed != null) (queryParams as any).embed = embed
        if (profileId != null) (queryParams as any).profile_id = profileId
        if (snapshotId != null) (queryParams as any).snapshot_id = snapshotId
        if (mobile != null) (queryParams as any).mobile = mobile

        dispatch(logout())
        navigate(
          ROOT_PAGE_URL.LOGIN +
            '?' +
            new URLSearchParams(queryParams).toString(),
          {
            replace: true
          }
        )
      } else {
        if (
          ('profile' in (verifyRes.data as any) &&
            (verifyRes.data as any).profile == null) ||
          !('profile' in (verifyRes.data as any))
        ) {
          window.open(
            `${process.env.REACT_APP_HOME_PAGE_URL}/account-creation`,
            '_self'
          )
        }

        if (
          profileId != null &&
          (verifyRes.data as any).profile.profile_id !== profileId
        ) {
          switchProfile({
            id: Number.parseInt(profileId)
          })
            .then(() => {
              console.log('Profile Switched')
            })
            .catch(() => {
              console.log('Error')
            })
        } else {
          dispatch(setLoggedIn(true))

          setComponent(<Outlet />)
        }
      }
    }
    if (
      !verifyRes.isLoading &&
      !switchProfileRes.isLoading &&
      (token == null || !isTokenSkip)
    ) {
      setComponent(<Outlet />)
    }
  }, [verifyRes, switchProfileRes, isLoggedIn, dispatch])

  useEffect(() => {
    if (verifyRes.error != null && token == null) {
      dispatch(logout())
      // if (!inviteeEmail) {
      const queryParams = {}

      if (scanId != null) (queryParams as any).scan_id = scanId
      if (userId != null) (queryParams as any).user_id = userId
      if (userEmail != null) (queryParams as any).user_email = userEmail
      if (embed != null) (queryParams as any).embed = embed
      if (profileId != null) (queryParams as any).profile_id = profileId
      if (snapshotId != null) (queryParams as any).snapshot_id = snapshotId
      if (mobile != null) (queryParams as any).mobile = mobile

      navigate(
        ROOT_PAGE_URL.LOGIN + '?' + new URLSearchParams(queryParams).toString(),
        {
          replace: true
        }
      )
      // }
    }
  }, [verifyRes, dispatch, navigate])

  useEffect(() => {
    if (userDetails == null && userId != null) {
      if (token != null) return

      const queryParams = {}

      if (scanId != null) (queryParams as any).scan_id = scanId
      if (userId != null) (queryParams as any).user_id = userId
      if (userEmail != null) (queryParams as any).user_email = userEmail
      if (embed != null) (queryParams as any).embed = embed
      if (profileId != null) (queryParams as any).profile_id = profileId
      if (snapshotId != null) (queryParams as any).snapshot_id = snapshotId
      if (mobile != null) (queryParams as any).mobile = mobile

      navigate(
        ROOT_PAGE_URL.LOGIN + '?' + new URLSearchParams(queryParams).toString(),
        {
          replace: true
        }
      )
    }
  }, [userDetails, navigate])

  useEffect(() => {
    rtkHandler(switchProfileRes, {
      showSuccessToast: false,
      onSuccess(res) {
        dispatch(setLoggedIn(true))
      }
    })
  }, [switchProfileRes])

  useEffect(() => {
    if (token != null && !isLoggedIn) {
      dispatch(
        setUserSignInInfo({
          user: null,
          token
        })
      )

      setTimeout(() => {
        setIsTokenSkip(false)
      }, 0)
    }
  }, [token, isLoggedIn])

  return Component
}

export default Auth
