import { type Context, createContext } from 'react'

import { type IFieldControllerContext } from './FieldController.type'

const FieldControllerContext: Context<IFieldControllerContext> = createContext({
  methods: {},
  props: {}
})

export default FieldControllerContext
