import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROOT_PAGE_URL } from 'shared/constants/navigator'
import { useGetProjectByIDQuery } from 'shared/graphql/utils/enhanced'
import { type Project, type Scan } from 'shared/graphql/utils/generated'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { rtkHandler } from 'shared/utils/handlers'
import { setData } from 'store/slices/scan/threeJSReducer'

const useFilesTab = () => {
  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const scanId = urlParams.get('scan_id')
  const userId = urlParams.get('user_id')
  const userEmail = urlParams.get('user_email')
  const embed = urlParams.get('embed')
  const profileId = urlParams.get('profile_id')

  const { _id: scan_id, project_id } = useAppSelector((state) => ({
    _id: state.scan.scan?.scan_id ?? '',
    project_id: state.scan.scan?.project_id ?? '',
  }));

  const projectRes = useGetProjectByIDQuery({
    id: Number.parseInt(project_id)
  })

  const [scans, setScans] = useState<any[]>([])

  useEffect(() => {
    rtkHandler(projectRes, {
      showToast: false,
      onSuccess(res) {
        const projectTemp: Project = res.project as Project
        let projectScans: Scan[] = []

        projectTemp.folders.forEach(folder => {
          projectScans = [...projectScans, ...folder.scans]
        })

        setScans(
          projectScans.filter(
            (scan: Scan) =>
              scan.model != null && scan.model.status === 'Completed'
          )
        )
      }
    })
  }, [projectRes])

  const onClickScan = (scan: any) => {
    const queryParams = {}

    if (scanId != null) (queryParams as any).scan_id = scan.scan_id
    if (userId != null) (queryParams as any).user_id = userId
    if (userEmail != null) (queryParams as any).user_email = userEmail
    if (embed != null) (queryParams as any).embed = embed
    if (profileId != null) (queryParams as any).profile_id = profileId

    dispatch(
      setData({
        path: 'websocketState/isInitiateClose',
        data: true
      })
    )

    setTimeout(() => {
      navigate(
        ROOT_PAGE_URL.DASHBOARD +
          '?' +
          new URLSearchParams(queryParams).toString(),
        {
          replace: true
        }
      )
    }, 0)
  }

  return { scan_id, scans, isScansLoading: projectRes.isLoading, onClickScan }
}

export default useFilesTab
