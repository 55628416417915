import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import apiSlice from 'shared/apis/apiSlice/apiSlice'
import gqlApi from 'shared/apis/apiSlice/gqlApi'

import appSlice from './slices/app/appSlice'
import authSlice, { logout } from './slices/auth/authSlice'
import userInfoSlice from './slices/auth/userInfoSlice'
import userSignInStatusSlice from './slices/auth/userSignInStatusSlice'
import dashboardSlice from './slices/dashboard/dashboardSlice'
import scanReducer from './slices/scan/scanReducer'
import threeJsReducer from './slices/scan/threeJSReducer'
import commonModalSlice from './slices/utils/commonModalSlice'
import fileUploadSlice from './slices/utils/fileUploadSlice'
import queryParamsSlice from './slices/utils/queryParamsSlice'
import tutorialSlice from './slices/utils/tutorialSlice'

const key = process.env.REACT_APP_REDUX_PERSIST_SECRET_KEY ?? 'soar3dweb'

const persistConfig = {
  key: 'soar3d-synthetic-web-app',
  storage,
  transforms: [
    encryptTransform({
      secretKey: key,
      onError: function () {
        const { dispatch } = store
        dispatch(logout())
      }
    })
  ],
  whitelist: ['auth', 'userInfo']
}

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [gqlApi.reducerPath]: gqlApi.reducer,
  auth: authSlice,
  userSignInStatus: userSignInStatusSlice,
  userInfo: userInfoSlice,
  app: appSlice,
  dashboard: dashboardSlice,
  scan: scanReducer,
  threeJs: threeJsReducer,
  tutorial: tutorialSlice,
  queryParam: queryParamsSlice,
  commonModal: commonModalSlice,
  fileUpload: fileUploadSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      serializableCheck: false
    }),
    apiSlice.middleware,
    gqlApi.middleware
  ]
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
