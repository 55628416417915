import { Button, Popover } from '@mui/material'
import IsStagingComponent from 'shared/components/IsStagingComponent/IsStagingComponent'

import PeopleDetailsList from './partials/PeopleDetailsList'
import PeopleList from './partials/PeopleList'
import useShareViewer from './useShareViewer'

const ShareViewer = () => {
  const {
    people,
    anchorElRef,
    isOpen,
    openShareDetails,
    closeShareDetails,
    client_id,
    spectating_client_id,
    onExitSpectatorViewClickedHandler
  } = useShareViewer()

  return (
    <div className="flex items-center gap-2">
      <IsStagingComponent>
        <PeopleList />
      </IsStagingComponent>
      {client_id === spectating_client_id ? (
        <>
          <Button
            ref={anchorElRef}
            aria-describedby="share_btn"
            variant="contained"
            color="primary"
            className="font-normal text-[10.67px] w-[75px] h-[32px] leading-normal"
            onClick={openShareDetails}
          >
            Invitees
          </Button>
          <Popover
            id="share_btn"
            open={isOpen}
            anchorEl={anchorElRef.current}
            onClose={closeShareDetails}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            style={{
              marginTop: '16px'
            }}
            classes={{
              paper: '!left-5 lg:!left-auto !top-14 lg:!right-6'
            }}
            sx={{
              '.MuiPaper-rounded': {
                borderRadius: '8px'
              }
            }}
          >
            <div className="min-w-[calc(100vw-40px)] lg:min-w-[424px] max-h-[334.5px] flex flex-col bg-[#FFFFFFE5] rounded-lg p-4 overflow-hidden">
              <PeopleDetailsList people={people} />
            </div>
          </Popover>
        </>
      ) : (
        <IsStagingComponent>
          <Button
            aria-describedby="exit_spectator_view_btn"
            variant="outlined"
            color="primary"
            className="!font-normal !text-[10.67px] !text-[#FFFFFF] h-[32px] !leading-normal"
            onClick={e => {
              e.stopPropagation()
              onExitSpectatorViewClickedHandler()
            }}
          >
            Exit Spectator View
          </Button>
        </IsStagingComponent>
      )}
    </div>
  )
}

export default ShareViewer
