import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { splitPath } from 'shared/utils/utils'

interface WebsocketState {
  isConnected: boolean;
  isInitiateClose: boolean;
  websocket_url: string;
  port_id: number | null;
  client_id: number | null;
  spectating_client_id: number | null;
  watermark_url: string;
  viewers: {
    client_id: number;
    id: string;
    name: string;
    img: string;
  }[];
  spectators: number[];
}

interface RenderingState {
  camera_choice: string
  render_height: number
  render_width: number
  field_of_view: number
  camera_type: string
  training_state: string
  step: number
  eval_res: string
  clipping_enabled: boolean
  clipping_center: number[]
  clipping_box_scale: number[]
  crop_enabled: boolean
  crop_bg_color: { r: number; g: number; b: number }
  crop_scale: number[]
  crop_center: number[]
  use_time_conditioning: boolean
  output_options: string[]
}

interface SceneState {
  sceneBox: null | any
  cameras: null | any
}

interface FilePathInfoState {
  config_base_dir: string
  data_base_dir: string
  export_path_name: string
}

interface CustomGuiState {
  guiNames: any[]
  guiConfigFromName: any
  guiSetQueue: any
}

interface RootState {
  websocketState: WebsocketState
  camera_path_payload: null | any
  populate_paths_payload: boolean
  render_img: null | any
  file_path_info: FilePathInfoState
  custom_gui: CustomGuiState
  all_camera_paths: null | any
  renderingState: RenderingState
  sceneState: SceneState
}

export const initialState: RootState = {
  // the websocket connection state
  websocketState: {
    isConnected: false,
    isInitiateClose: false,
    websocket_url: '',
    port_id: null,
    client_id: null,
    spectating_client_id: null,
    watermark_url: '',
    viewers: [],
    spectators: []
  },

  // for sending actual commands to the client
  camera_path_payload: null,
  populate_paths_payload: false,

  render_img: null, // The rendered images

  custom_gui: {
    guiNames: [],
    guiConfigFromName: {},
    guiSetQueue: {}
  },

  file_path_info: {
    config_base_dir: 'config_base_dir', // the base directory of the config file
    data_base_dir: 'data_base_dir', // the base directory of the images for saving camera path with the data
    export_path_name: 'export_path_name' // export name for render and camera_path
  },

  all_camera_paths: null, // object containing camera paths and names

  // the rendering state
  renderingState: {
    // cameras
    camera_choice: 'Main Camera', // the camera being used to render the scene

    // camera path information
    render_height: 1080,
    render_width: 1920,
    field_of_view: 50,
    camera_type: 'perspective',

    training_state: 'training',

    step: 0,
    eval_res: '?',

    // export options
    clipping_enabled: true,
    clipping_center: [0, 0, 0],
    clipping_box_scale: [2, 2, 2],

    // Crop Box Options
    crop_enabled: false,
    crop_bg_color: { r: 38, g: 42, b: 55 },
    crop_scale: [2, 2, 2],
    crop_center: [0, 0, 0],

    // Time options
    use_time_conditioning: false,

    // Currently just used for mesh panel, should be removed
    output_options: []
  },

  // the scene state
  sceneState: {
    sceneBox: null,
    cameras: null
  }
}

const threeJsSlice = createSlice({
  name: 'threeJs',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<{ path: string; data: any }>) => {
      const path = splitPath(action.payload.path)
      const data = action.payload.data
      const newState = { ...state }
      setDataHelper(newState, state, path, data)
      return newState
    },
    resetThreeJs: state => {
      return initialState
    }
  }
})

function setDataHelper(newState: any, state: any, path: string[], data: any) {
  if (path.length === 1) {
    newState[path[0]] = data
  } else {
    newState[path[0]] = { ...state[path[0]] }
    setDataHelper(newState[path[0]], state[path[0]], path.slice(1), data)
  }
}

export const { setData, resetThreeJs } = threeJsSlice.actions

export default threeJsSlice.reducer
