import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useThreeJsCamera = (props: { sceneTree: any }) => {
  // const websocket = useContext(WebSocketContext).socket;
  // const isTraining = useSelector((state) => state.renderingState.isTraining);
  const sceneTree = props.sceneTree

  // const isWebsocketConnected = useSelector(
  // 	(state) => state.websocketState.isConnected
  // );

  const camera_choice = useSelector(
    state => (state as any).threeJs.renderingState.camera_choice
  )

  // logic for toggling visibility of the entire scene and just the training images
  const [is_scene_visible] = useState(false)
  const [is_images_visible] = useState(false)
  sceneTree.object.visible = is_scene_visible && camera_choice === 'Main Camera'
  if (sceneTree.find_no_create(['Training Cameras']) !== null) {
    sceneTree.find_no_create(['Training Cameras']).object.visible =
      is_images_visible
  }

  useEffect(() => {
    sceneTree.object.traverse((obj: any) => {
      if (obj.name === 'CAMERA_LABEL') {
        // eslint-disable-next-line no-param-reassign
        obj.visible = is_scene_visible && camera_choice === 'Main Camera'
      }
    })
  }, [camera_choice, is_scene_visible])

  return {}
}

export default useThreeJsCamera
