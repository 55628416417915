import { styled, Tab, Tabs } from '@mui/material';
import useThemeImages from 'assets/images';
import LOGO from 'assets/images/logo.png';
import { useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FieldController from 'shared/components/FieldController/FieldController';
import LoaderButton from 'shared/components/LoaderButton/LoaderButton';
import PhoneNumberPicker from 'shared/components/PhoneNumberPicker/PhoneNumberPicker';

import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleLogin from 'react-apple-login';
import { IsStagingServer } from 'shared/constants/const';
import useLoginScreen from './useLoginScreen';
import IsStagingComponent from 'shared/components/IsStagingComponent/IsStagingComponent';

const LoginScreen = () => {
  const {
    isLoading,
    control,
    socialLoading,
    doGoogleLogin,
    doAppleLogin,
    handleSubmit,
    onSubmit,
    hasUserEmail,
  } = useLoginScreen();
  const fieldType = useWatch({ control, name: 'type' });
  const { GoogleIcon, AppleIcon } = useThemeImages();

  return (
    <div className="w-full h-[var(--app-height)] bg-[#1E1E1E] flex flex-col justify-between p-4 lg:p-8">
      <div>
        <img src={LOGO} width={150} />
      </div>
      <div className="max-w-[454px] w-full p-4 lg:p-8 rounded-xl bg-white mx-auto">
        <h4 className="text-[#101828] font-medium text-[20px] lg:text-[24px] mb-3 text-center">
          Confirm Access
        </h4>
        <p className="text-[#667085] text-md lg:text-lg font-normal text-center">
          Please sign in again to confirm your access to the viewer.
        </p>
        <form
          action=""
          className="block mt-8"
          autoComplete="off"
          onSubmit={() => handleSubmit(onSubmit)}
        >
          <div className="p-1 flex bg-[#F1F1F1] rounded-lg mb-4">
            <FieldController
              control={control}
              name="type"
              formGroup={false}
              render={({ field: { value, onChange } }) => {
                return (
                  <CustomTabs
                    scrollButtons="auto"
                    value={value}
                    onChange={(_, val) => {
                      onChange(val);
                    }}
                    className="flex items-center overflow-auto w-full"
                  >
                    <Tab label={<span>Email</span>} value={'email'} {...a11yProps(0)} />
                    <Tab label={<span>Phone Number</span>} value="phone" {...a11yProps(1)} />
                  </CustomTabs>
                );
              }}
            />
          </div>

          {fieldType === 'email' && (
            <FieldController
              control={control}
              label="Email"
              placeholder="Enter your email"
              name="email"
              inputVariant="outlined"
              inputTextProps={{
                disabled: hasUserEmail,
              }}
            />
          )}

          {fieldType === 'phone' && (
            <FieldController
              control={control}
              label="Phone Number"
              name="phone_number"
              render={({ field: { value, onChange } }) => (
                <PhoneNumberPicker
                  value={value}
                  onChange={(val) => {
                    onChange(val);
                  }}
                />
              )}
            />
          )}
          <FieldController
            formGroup={false}
            control={control}
            label="Password"
            placeholder="Enter your password"
            name="password"
            inputVariant="outlined"
            type="password"
          />
          <div className="flex space-x-2 items-center justify-between my-2">
            <FieldController
              formGroup={false}
              control={control}
              label="Remember me"
              name="remember_me"
              type="checkbox-label"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                },
              }}
            />
            <Link
              className="text-primary inline-block text-md whitespace-nowrap"
              to={'https://app.xspada.com/forgot-password'}
            >
              Forgot password?
            </Link>
          </div>
          <LoaderButton
            loading={isLoading}
            loadingText="Signing in..."
            variant="contained"
            fullWidth
            type="submit"
            className="text-lg font-medium !h-11"
          >
            Sign In
          </LoaderButton>
          <IsStagingComponent>
            <div className="w-full flex mt-4 gap-x-3 border-t border-[#EAECF0] pt-4">
              <LoaderButton
                onClick={() => doGoogleLogin()}
                variant="outlined"
                fullWidth
                loading={socialLoading}
                className="text-lg font-medium !h-11 w-full"
                loadingText="Verifying..."
              >
                <GoogleIcon />
              </LoaderButton>
              {/* <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID ?? ''}
                onSuccess={(response) => {
                  doFacebookLogin(response.accessToken);
                }}
                render={({ onClick }) => (
                  <LoaderButton
                    onClick={onClick}
                    variant="outlined"
                    fullWidth
                    loading={socialLoading}
                    className="text-lg font-medium !h-11 w-full"
                    loadingText="Verifying..."
                  >
                    <FacebookIcon />
                  </LoaderButton>
                )}
              ></FacebookLogin> */}
              <AppleLogin
                clientId={process.env.REACT_APP_APPLE_CLIENT_ID ?? ''}
                redirectURI={process.env.REACT_APP_SOCIAL_AUTH_REDIRECT_URI ?? ''}
                usePopup={true}
                responseMode="form_post"
                scope="name email"
                render={({ onClick }) => (
                  <LoaderButton
                    onClick={onClick}
                    variant="outlined"
                    fullWidth
                    loading={socialLoading}
                    className="text-lg font-medium !h-11 w-full"
                    loadingText="Verifying..."
                  >
                    <AppleIcon />
                  </LoaderButton>
                )}
                callback={(response) => {
                  if (response.authorization) {
                    doAppleLogin(response.authorization.code);
                  }
                }}
              />
            </div>
          </IsStagingComponent>
          <p
            className={`mt-4 text-md text-center ${
              IsStagingServer ? 'border-t border-[#EAECF0] pt-4' : ''
            }`}
          >
            Don't have an account?{' '}
            <Link className="text-primary" to={`${process.env.REACT_APP_HOME_PAGE_URL}/register`}>
              Sign up
            </Link>{' '}
          </p>
        </form>
      </div>
      <div className="text-[#667085] text-md">© XSpada 2023</div>
    </div>
  );
};

export default LoginScreen;

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const CustomTabs = styled(Tabs)({
  minHeight: 'auto !important',

  // indicator
  '& .MuiTabs-indicator': {
    height: '36px',
    backgroundColor: '#fff',
    borderRadius: '8px',
  },
  '& .MuiTab-root': {
    textAlign: 'center',
    width: '50%',
    marginLeft: '0 !important',
    color: '#5E5D5D !important',
    fontWeight: 500,
    padding: '9px 0 !important',
    minHeight: 'auto !important',
    textTransform: 'capitalize',

    '& span': {
      position: 'relative',
      zIndex: 1,
    },
  },
});
