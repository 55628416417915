import { closeLogInDialog, setLoaderBranding } from 'store/slices/app/appSlice'
import { setUserSignInInfo } from 'store/slices/auth/authSlice'
import { setLoggedIn } from 'store/slices/auth/userSignInStatusSlice'

import { gqlApi as generatedApi, type Profile, type User } from './generated'

export const gqlApi = generatedApi.enhanceEndpoints({
  endpoints: {
    Login: {
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          let profileId = null
          if ('profile' in arg) {
            profileId = arg.profile
          }

          const {
            data: { login }
          } = await queryFulfilled
          const { token, profile, ...others }: any = login

          // dispatch(apiSlice.util.resetApiState());
          dispatch(
            setUserSignInInfo({
              user: others,
              token,
              userProfile: profile
            })
          )
          if ('team_id' in profile && typeof profile.team_id === 'object') {
            dispatch(setLoaderBranding(profile.team_id))
          }
          dispatch(closeLogInDialog())
          if (
            profileId == null ||
            (profile != null && profile._id === profileId)
          ) {
            dispatch(setLoggedIn(true))
          }
        } catch {}
      }
    },

    SwitchProfile: {
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { switch_profile }
          } = await queryFulfilled
          const { token, profile, ...others }: any = switch_profile

          dispatch(
            setUserSignInInfo({
              user: others,
              token,
              userProfile: profile
            })
          )
          dispatch(setLoggedIn(true))
          dispatch(closeLogInDialog())
        } catch {}
      }
    },

    VerifyToken: {
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          const {
            data: { verify_token, team, profile }
          } = await queryFulfilled
          dispatch(
            setUserSignInInfo({
              user: verify_token as User,
              token: null,
              userProfile: profile as Profile,
              currentTeam: team
            })
          )

          if ('team_id' in team && typeof team === 'object') {
            dispatch(setLoaderBranding(team))
          }

          // dispatch(setLoggedIn(true));
        } catch {}
      }
    },

    GoogleLogin: {
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          let profileId = null
          if ('profile' in arg) {
            profileId = arg.profile
          }

          const {
            data: { google_login }
          } = await queryFulfilled
          const { token, profile, ...others }: any = google_login

          // dispatch(apiSlice.util.resetApiState());
          dispatch(
            setUserSignInInfo({
              user: others,
              token,
              userProfile: profile
            })
          )
          if ('team_id' in profile && typeof profile.team_id === 'object') {
            dispatch(setLoaderBranding(profile.team_id))
          }
          dispatch(closeLogInDialog())
          if (
            profileId == null ||
            (profile != null && profile._id === profileId)
          ) {
            dispatch(setLoggedIn(true))
          }
        } catch {}
      }
    },

    AppleLogin: {
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          let profileId = null
          if ('profile' in arg) {
            profileId = arg.profile
          }

          const {
            data: { apple_login }
          } = await queryFulfilled
          const { token, profile, ...others }: any = apple_login

          // dispatch(apiSlice.util.resetApiState());
          dispatch(
            setUserSignInInfo({
              user: others,
              token,
              userProfile: profile
            })
          )
          if ('team_id' in profile && typeof profile.team_id === 'object') {
            dispatch(setLoaderBranding(profile.team_id))
          }
          dispatch(closeLogInDialog())
          if (
            profileId == null ||
            (profile != null && profile._id === profileId)
          ) {
            dispatch(setLoggedIn(true))
          }
        } catch {}
      }
    },
    GetMyScansByID: {
      providesTags: ({ scan }: any) => {
        return scan.snapshots.length > 0
          ? [
              ...scan.snapshots.map(({ snapshot_id }: any) => ({
                type: 'Snapshots' as const,
                id: snapshot_id
              })),
              'Snapshots',
              'Invitees'
            ]
          : ['Snapshots', 'Invitees']
      }
    },
    SubmitSnapshot: {
      invalidatesTags: ['Snapshots']
    },
    DeleteSnapshot: {
      invalidatesTags: ['Snapshots']
    }
  }
})

export const {
  useGoogleLoginMutation,
  useAppleLoginMutation,
  useLoginMutation,
  useVerifyTokenQuery,
  useLazyVerifyTokenQuery,
  useSwitchProfileMutation,
  useGetMyScansByIDQuery,
  useGetPublicScanByIDQuery,
  useGetProjectByIDQuery,
  useSubmitSnapshotMutation,
  useDeleteSnapshotMutation,
  useSaveModalDefaultPositionMutation,
  useStartViewerMutation,
  useGetViewerPortQuery
} = gqlApi
