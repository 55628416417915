import { useContext } from 'react'
import { ReactReduxContext } from 'react-redux'

export function useSubscribeToChanges(selectorFn, callbackFn) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { store } = useContext(ReactReduxContext)

  let previousState
  const handleChange = () => {
    const currentState = selectorFn(store.getState())
    if (previousState !== currentState) {
      callbackFn(previousState, currentState)
      previousState = currentState
    }
  }

  store.subscribe(handleChange)
}
