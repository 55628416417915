import { Dialog, DialogContent } from '@mui/material'
import { type CommonModalState } from 'store/slices/utils/commonModalSlice'

import CommonModalActionButton from '../CommonModal/partials/CommonModalActionButton'

interface ThemeModalProps extends CommonModalState {
  open: boolean
  onCloseModal?: () => void
}

const ThemeModal = (props: ThemeModalProps) => {
  const {
    topElement: TopElement,
    topElementProps,
    icon,
    title,
    content,
    contentComponent,
    children,
    dialogueContentClassName,
    dialogueActionClassName,
    dialogueActionButtonsFontSize,
    buttons,
    showActionButtons,
    actionButtonsDir,
    negativeButtonLabel,
    negativeButtonAction,
    dialogPaperProps,
    maxWidth = 'xs',
    cancelBtnProps = {},
    onCloseModal,
    open,
    ...rest
  } = props

  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      PaperProps={dialogPaperProps}
      {...rest}
    >
      <DialogContent className={dialogueContentClassName}>
        {TopElement != null && <TopElement {...topElementProps} />}
        {icon != null && (
          <div className="mb-3 flex items-center justify-center">{icon}</div>
        )}
        {title != null && (
          <div className="font-semibold text-[#3F3E3E] text-xl">{title}</div>
        )}
        <div className="text-[#667085] text-md mt-2 font-thin flex flex-col space-y-5">
          {content != null && <div>{content}</div>}
          {contentComponent != null && <>{contentComponent}</>}
          {children != null && <div>{children}</div>}
        </div>
      </DialogContent>
      {showActionButtons != null && (
        <CommonModalActionButton
          buttons={buttons ?? []}
          className={dialogueActionClassName}
          cancelBtnLabel={negativeButtonLabel}
          cancelBtnProps={cancelBtnProps}
          onCancelBtnClick={() => {
            onCloseModal != null && onCloseModal()
            if (negativeButtonAction != null) {
              negativeButtonAction()
            }
          }}
          direction={actionButtonsDir}
          fontSize={dialogueActionButtonsFontSize}
        />
      )}
    </Dialog>
  )
}

export default ThemeModal
