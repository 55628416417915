import ThreeJsLoader from 'screens/ThreeModel/partials/ThreeJsLoader/ThreeJsLoader'

const InvitationScreen = () => {
  return (
    <div className="w-full h-[var(--app-height)] relative">
      <ThreeJsLoader loadingStatus="Validating" />
    </div>
  )
}

export default InvitationScreen
