import { Button, CircularProgress } from '@mui/material';
import useThemeImages from 'assets/images';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import Banner from 'screens/ThreeModel/partials/Banner/Banner';
import DashboardOverlayTabs from 'shared/components/DashboardOverlayTabs/DashboardOverlayTabs';
import FileDetailsOverlay from 'shared/components/FileDetailsOverlay/FileDetailsOverlay';
import ThemeModal from 'shared/components/ThemeModal/ThemeModal';
import { useAppSelector } from 'shared/hooks/useRedux';
import Sidebar from 'shared/layouts/partials/Sidebar/Sidebar';

import WebSocketProvider from '../../shared/socket/WebSocket';
import ViewerWindow from '../../shared/viewer/ViewerWindow';
import InstructionsDialog from './partials/InstructionsDialog';
import EmbedFooter from './partials/ShareViewer/partials/EmbedFooter';
import SubmitSnapshotModalContent from './partials/SubmitSnapshotModalContent/SubmitSnapshotModalContent';
import ThreeJsLoader from './partials/ThreeJsLoader/ThreeJsLoader';
import ThreeModelContext from './ThreeModel.context';
import useThreeModel from './useThreeModel';
import useThreeJsCamera from 'shared/hooks/useThreeJsCamera';

const ThreeModel = () => {
  const {
    scanData = {},
    show,
    success,
    setSuccess,
    loadingStatus,
    is_embed,
    is_sidebar_open,
    is_sidebar_visible,
    sceneTree,
    saveCameraPosition,
    isScanOwner,
    isSnapshotDialogOpen,
    setIsSnapshotDialogOpen,
    getCameraPosition,
    updateCameraPosition,
    isStarterProfile,
    isScanSwitch,
    scan_images,
    clientId,
    spectatingClientId,
    cameraDefaults,
    isSnapshotAllowed,
    socketLoadingStatus,
  } = useThreeModel();
  const {} = useThreeJsCamera({ sceneTree });
  const { SnapshotIcon, LocationMarkerIcon, SnapshotLayerIcon } = useThemeImages();

  const isBannerVisible = useAppSelector((state) => state.app.banner.is_visible);

  return (
    <>
      {success && !is_embed && (
        <div className="absolute bottom-4 right-4 z-[1000] flex flex-col items-end group">
          {!isStarterProfile && isSnapshotAllowed && (
            <Button
              variant="contained"
              className="!bg-[#FFFFFFBF] !h-[40px] !min-w-[40px] !w-[40px] group-hover:!w-[143px] !transition-all !rounded-md !px-3 !text-sm !text-[#383838] !font-medium !mb-2 !overflow-hidden"
              onClick={() => {
                setIsSnapshotDialogOpen(true);
              }}
              sx={{
                '&': {
                  boxShadow: '0px 1px 4px 0px #1018283f !important',
                },
              }}
            >
              <SnapshotIcon className="w-[15px] h-[15px] group-hover:mr-[10px]" />
              <span className="hidden opacity-0 group-hover:inline-block group-hover:opacity-100">
                Take Snapshot
              </span>
            </Button>
          )}
          {isScanOwner && clientId === spectatingClientId && (
            <Button
              variant="contained"
              className="!bg-[#FFFFFFBF] !h-[40px] !min-w-[40px] !w-[40px] group-hover:!w-[227px] !transition-all !rounded-md !px-3 !text-sm !text-[#383838] !font-medium !mb-2 !overflow-hidden"
              fullWidth
              onClick={() => {
                saveCameraPosition()
                  .then(() => {
                    console.log('Sucesss');
                  })
                  .catch(() => {
                    console.log('Error');
                  });
              }}
              sx={{
                '&': {
                  boxShadow: '0px 1px 4px 0px #1018283f !important',
                },
              }}
            >
              <LocationMarkerIcon className="w-[15px] h-[15px] group-hover:mr-[10px]" />
              <span className="hidden opacity-0 group-hover:inline-block group-hover:opacity-100">
                Save as default starting point
              </span>
            </Button>
          )}
        </div>
      )}
      <ThreeModelContext.Provider
        value={{
          sceneTree,
          scanData,
          saveCameraPosition,
          isScanOwner,
          updateCameraPosition,
        }}
      >
        <ThemeModal
          icon={<SnapshotLayerIcon />}
          open={isSnapshotDialogOpen}
          onClose={() => {
            setIsSnapshotDialogOpen(false);
          }}
          title="Take Snapshot "
          content="Your snapshot is captured and will be saved with the scan you are viewing."
          contentComponent={
            <SubmitSnapshotModalContent
              onClose={() => {
                setIsSnapshotDialogOpen(false);
              }}
              getCameraPosition={getCameraPosition}
            />
          }
        />
        <div className="w-full h-[var(--app-height)] relative">
          {clientId != spectatingClientId ? (
            <></>
          ) : !success && !isScanSwitch ? (
            isScanSwitch ? (
              <div
                className={`absolute top-0 left-0 w-full h-full flex items-center justify-center overflow-hidden bg-white ${
                  is_sidebar_visible ? (is_sidebar_open ? 'lg:pl-[240px]' : 'lg:pl-[92px]') : ''
                }`}
              >
                {((cameraDefaults && cameraDefaults.default_pos_img != undefined) ||
                  (scan_images != undefined && scan_images.thumbnail)) && (
                  <img
                    src={
                      cameraDefaults && cameraDefaults?.default_pos_img != undefined
                        ? cameraDefaults!.default_pos_img.url
                        : scan_images!.thumbnail
                    }
                    className={`w-full ${
                      isBannerVisible ? 'h-[calc(100vh-80px)]' : 'h-full'
                    } object-cover blur-md scale-[1.1]`}
                  />
                )}
                <div className="absolute top-0 w-full h-full flex items-center justify-center flex-col">
                  <CircularProgress
                    color="secondary"
                    style={{ marginTop: isBannerVisible ? 80 : 0 }}
                  />
                  <p className="w-[25%] break-words text-[#9c27b0] text-md font-bold text-center mt-4 bg-[#FFFFFF44] p-3 rounded-lg">
                    <div>{socketLoadingStatus}</div>
                  </p>
                </div>
              </div>
            ) : (
              <ThreeJsLoader loadingStatus={loadingStatus} />
            )
          ) : (
            <></>
          )}
          {clientId === spectatingClientId && isScanSwitch && (
            <div
              className={`absolute top-0 left-0 w-full h-full flex items-center justify-center overflow-hidden bg-white ${
                is_sidebar_visible ? (is_sidebar_open ? 'lg:pl-[240px]' : 'lg:pl-[92px]') : ''
              }`}
            >
              {((cameraDefaults && cameraDefaults.default_pos_img != undefined) ||
                (scan_images != undefined && scan_images?.thumbnail)) && (
                <img
                  src={
                    cameraDefaults && cameraDefaults?.default_pos_img != undefined
                      ? cameraDefaults!.default_pos_img.url
                      : scan_images!.thumbnail
                  }
                  className={`w-full ${
                    isBannerVisible ? 'h-[calc(100vh-80px)]' : 'h-full'
                  } object-cover blur-md scale-[1.1]`}
                />
              )}
              <div className="absolute top-0 w-full h-full flex items-center justify-center flex-col">
                <CircularProgress
                  color="secondary"
                  style={{ marginTop: isBannerVisible ? 80 : 0 }}
                />
                <p className="w-[25%] break-words text-[#9c27b0] text-md font-bold text-center mt-4 bg-[#FFFFFF44] p-3 rounded-lg">
                  {socketLoadingStatus}
                </p>
              </div>
            </div>
          )}
          <InstructionsDialog />
          <div className="h-full flex flex-row">
            <div
              className={`sidebar sidebar--transparent hidden md:flex  ${
                !is_sidebar_open && 'sidebar--collapsed'
              } ${!is_sidebar_visible && 'md:hidden'} ${is_embed && '!hidden !md:hidden'}`}
            ></div>
            <div className="flex-1 h-full w-full relative">
              {/* The banner at the top of the page. */}
              {show && sceneTree != null && (
                <WebSocketProvider
                  sceneTree={sceneTree}
                  onSuccess={() => {
                    setSuccess(true);
                  }}
                >
                  {isBannerVisible ? <Banner /> : <></>}
                  <div
                    className={`App-body ${
                      isBannerVisible ? 'h-[calc(100vh-60px)] lg:h-[calc(100vh-80px)]' : 'h-full'
                    } relative`}
                  >
                    {/* Order matters here. The viewer window must be rendered first. */}
                    <ViewerWindow sceneTree={sceneTree} />

                    {is_embed && (
                      <a
                        href={process.env.REACT_APP_HOME_PAGE_URL}
                        target="_blank"
                        className={`w-[20%] h-[30%] z-[100] absolute top-0 right-0`}
                        rel="noreferrer"
                      ></a>
                    )}
                  </div>
                  {is_sidebar_visible && (
                    <div className="SidePanel">
                      <Sidebar />
                    </div>
                  )}
                </WebSocketProvider>
              )}
              {is_embed && (
                <div>
                  {/* <EmbedLogo /> */}
                  <EmbedFooter />
                </div>
              )}
            </div>

            {/* <img src={DummyModel} className="w-full h-screen object-cover object-center" alt="" /> */}
          </div>
        </div>
        <FileDetailsOverlay />
        <DashboardOverlayTabs />
      </ThreeModelContext.Provider>
      <Outlet />
    </>
  );
};

export default memo(ThreeModel);
// export default ThreeModel;
