import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useThemeImages from 'assets/images';
import TutorialDummyPlaceholderImage from 'assets/images/tutorial-dummy-placeholder-image.png';
import { useLocation, useNavigate } from 'react-router-dom';
import ThreeJsSceneProviderContext from 'shared/components/ThreeJsSceneProvider/ThreeJsSceneProvider.context';
import TutorialImagePlaceholder from 'shared/components/Walkthrough/partials/TutorialImagePlaceholder';
import { IsStagingServer } from 'shared/constants/const';
import { ROOT_PAGE_URL } from 'shared/constants/navigator';
import {
  useGetViewerPortQuery,
  useSaveModalDefaultPositionMutation,
  useStartViewerMutation,
} from 'shared/graphql/utils/enhanced';
import useFileUploader from 'shared/hooks/useFileUploader';
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux';
import type SceneNode from 'shared/scene/SceneNode';
import { ProfileTypes } from 'shared/types/profile/profile.type';
import { rtkHandler } from 'shared/utils/handlers';
import { dataURLtoFile } from 'shared/utils/helper';
import { errorToast } from 'shared/utils/toast';
import useWalkthroughSteps from 'shared/utils/walkthrough';
import { hideBanner, hideSidebar, setIsEmbed } from 'store/slices/app/appSlice';
import { setData as scanSetData } from 'store/slices/scan/scanReducer';
import { setData } from 'store/slices/scan/threeJSReducer';
import { setCloseCommonModal, setCommonModal } from 'store/slices/utils/commonModalSlice';
import { dismissTutorial, showTutorial } from 'store/slices/utils/tutorialSlice';
import * as THREE from 'three';

const useThreeModel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { WarningLayerIcon } = useThemeImages();

  const { HOMEPAGE_STEPS } = useWalkthroughSteps();

  const sceneTree = useContext<SceneNode | null>(ThreeJsSceneProviderContext);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const scanId = urlParams.get('scan_id');
  const embed = urlParams.get('embed');
  const mobile = urlParams.get('mobile');
  const snapshotId = urlParams.get('snapshot_id');

  const { uploadFile } = useFileUploader();

  const isLoggedIn = useAppSelector((state) => state.userSignInStatus.isLoggedIn);
  const loggedInUserId = useAppSelector((state) => state.auth?.user?.user_id ?? null);
  const userProfile = useAppSelector((state) => state.auth?.userProfile ?? null);
  const currentTeam = useAppSelector((state) => state.auth?.currentTeam ?? null);

  const [show, setShow] = useState(false);
  const [isScanSwitch, setIsScanSwitch] = useState(false);

  const is_embed = useAppSelector((state) => state.app.is_embed);
  const is_sidebar_visible = useAppSelector((state) => state.app.sidebar.is_visible);
  const is_sidebar_open = useAppSelector((state) => state.app.sidebar.is_open);
  const { viewer } = useAppSelector((state) => state.tutorial);

  const clientId = useAppSelector((state) => state.threeJs.websocketState.client_id);
  const spectatingClientId = useAppSelector(
    (state) => state.threeJs.websocketState.spectating_client_id,
  );

  const scan_images = useAppSelector((state) => state.scan.scan?.input_file);
  const isCameraMoved = useAppSelector((state) => state.scan.is_camera_moved);
  const cameraDefaults = useAppSelector((state) => state.scan.camera);

  const cameraDefaultsRef = useRef(cameraDefaults);
  cameraDefaultsRef.current = cameraDefaults;

  const [isCameraMovedTest, setIsCameraMovedTest] = useState(false);

  const [isScanOwner, setIsScanOwner] = useState(false);

  const [isSnapshotDialogOpen, setIsSnapshotDialogOpen] = useState(false);

  const [savePosition, savePositionRes] = useSaveModalDefaultPositionMutation();

  const [viewerRes, setViewerRes] = useState<any>();
  const [startViewer] = useStartViewerMutation();

  const [portId, setPortId] = useState(-1);
  const [isPollingComplete, setIsPollingComplete] = useState(false);
  const viewerPortRes = useGetViewerPortQuery(
    {
      port_id: portId,
    },
    { skip: portId == -1 },
  );

  const loadingStates = {
    path: 'websocketState/loadingStatus',
    data: 'Loading',
  };
  const [socketLoadingStatus, setSocketLoadingStatus] = useState(
    'Viewer is taking some time to load. Please hang on tight',
  );

  const [success, setSuccess] = useState(false);

  const loadingStatus = useAppSelector(
    (state) => (state as any).threeJs.websocketState.loadingStatus,
  );

  const waitingTimer = useRef<any>(null);

  const isShowControlsOnStart = useAppSelector((state) => state.userInfo.isShowControlsOnStart);

  const isStarterProfile = useMemo(() => {
    return userProfile != null && [ProfileTypes.Starter].includes(userProfile.type as any);
  }, [userProfile]);

  const isSnapshotAllowed = useMemo(() => {
    const isTeamMember =
      userProfile != null && ProfileTypes.TeamMember === (userProfile.type as any);

    return (
      currentTeam?.setting?.permissions[isTeamMember ? 'team_member' : 'team_owner']?.scans
        ?.snapshots ?? true
    );
  }, [userProfile]);

  const getScannerInvokeDetails = async () => {
    try {
      dispatch(setData({ ...loadingStates, data: 'Accessing Scan Details' }));

      const response = await startViewer({
        data: {
          user_id: loggedInUserId ?? undefined,
          snapshot_id: snapshotId == null ? undefined ?? undefined : Number.parseInt(snapshotId),
          position: embed != null && embed === 'true' ? 'top-right' : 'bottom-left',
          scan_id: Number.parseInt(scanId ?? '0'),
        },
      });

      dispatch(
        setData({
          ...loadingStates,
          data: 'Please sit tight. Your viewing experience will begin momentarily',
        }),
      );
      if ('data' in response) {
        setViewerRes(response.data.start_viewer);

        const newPortId = response.data.start_viewer.port.port_id;

        dispatch(
          setData({
            path: 'websocketState/port_id',
            data: portId,
          }),
        );

        setPortId(portId);
      }
    } catch (error) {
      dispatch(setData({ ...loadingStates, data: '' }));
      if ('response' in (error as any)) {
        const { status, data } = (error as any).response;
        if (status === 401) {
          if (data != null && 'error' in data) {
            errorToast(data.error);
          }

          localStorage.removeItem('soar-3d-app-token');

          const queryParams = {};
          if (scanId != null) (queryParams as any).scan_id = scanId;
          navigate(ROOT_PAGE_URL.LOGIN + '?' + new URLSearchParams(queryParams).toString(), {
            replace: true,
          });
        } else {
          if (
            'is_port_available' in (error as any).response.data &&
            (error as any).response.data.is_port_available === false
          ) {
            navigate(ROOT_PAGE_URL.SERVERS_BUSY);
          } else {
            if (embed != null && embed === 'true') {
              navigate(ROOT_PAGE_URL.SERVERS_BUSY);
            } else {
              navigate(`/404`);
            }
          }
        }
      } else {
        if (embed != null && embed === 'true') {
          navigate(ROOT_PAGE_URL.SERVERS_BUSY);
        } else {
          navigate(ROOT_PAGE_URL.PROCESSING_ERROR);
        }
      }
    }
  };

  const onPollingSuccess = async (podDetails: any) => {
    const userDetails = viewerRes.scan.user;

    setIsScanOwner(loggedInUserId === userDetails?.user_id ?? false);

    dispatch(
      scanSetData({
        path: 'scanner',
        data: {
          id: userDetails?.user_id ?? '',
          name: userDetails?.name ?? '',
          email: userDetails?.email ?? '',
          profile_image: userDetails?.profile_image?.url ?? '',
        },
      }),
    );

    dispatch(
      scanSetData({
        path: 'scan',
        data: viewerRes.scan,
      }),
    );

    const cameraPosition = viewerRes.scan.model.position;
    const cameraRotation = viewerRes.scan.model.rotation;
    const cameraImage = viewerRes.scan.model?.default_pos_img ?? undefined;

    if (cameraPosition != null && cameraRotation != null) {
      dispatch(
        scanSetData({
          path: 'camera',
          data: {
            position: cameraPosition,
            rotation: cameraRotation,
            default_pos_img: cameraImage,
          },
        }),
      );
    }

    dispatch(
      setData({
        path: 'websocketState/modal',
        data: true,
      }),
    );

    if (viewerRes.watermark != null) {
      dispatch(
        setData({
          path: 'websocketState/watermark_url',
          data: viewerRes.watermark.url,
        }),
      );
    }

    if (viewerRes.port.port != null) {
      if (viewerRes.port.port === 7000) {
        await getScannerInvokeDetails();
        return;
      }

      dispatch(
        setData({
          path: 'websocketState/websocket_url',
          data: `wss://${podDetails.pod_id}-${podDetails.port}.proxy.runpod.net`,
        }),
      );

      setShow(true);
    }

    if (!isScanSwitch) {
      setIsScanSwitch(true);
    }
  };

  const onTutorialClose = () => {
    // setTutorialStatus({
    //   application: 'viewer',
    //   tab: 'all_tabs',
    // });

    dispatch(dismissTutorial());
    if (!window.localStorage.getItem('controls')) {
      dispatch(
        setData({
          path: 'show_instructions',
          data: true,
        }),
      );
    }
  };

  const onIdleTimeout = () => {
    dispatch(
      setCommonModal({
        icon: <WarningLayerIcon width={48} height={48} />,
        title: 'Inactivity Alert!',
        content:
          'Your session has been closed because of inactivity for more than 2 minutes on viewer.',
        dialogueActionClassName: 'flex-row',
        hasNegativeButton: false,
        dialogPaperProps: {
          className: '!max-w-[400px]',
        },
        buttons: [
          {
            children: 'Reload Viewer',
            color: 'error',
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      }),
    );
  };

  const inactivityTimer = useIdleTimer({
    onIdle: onIdleTimeout,
    onActive: () => {},
    onAction: () => {},
    timeout: 120_000,
    startOnMount: false,
    startManually: true,
  });

  useEffect(() => {
    rtkHandler(viewerPortRes, {
      showToast: false,
      onSuccess(res) {
        if ('pod_id' in res.viewer_port && res.viewer_port.pod_id != null) {
          setIsPollingComplete(true);

          onPollingSuccess(res.viewer_port)
            .then(() => {
              console.log('onPollingSuccess');
            })
            .catch(() => {
              console.log('onPollingSuccess error');
            });
        }
      },
    });
  }, [viewerPortRes, portId]);

  useEffect(() => {
    if (embed != null && embed === 'true') {
      dispatch(setIsEmbed());
      dispatch(hideBanner());
      dispatch(hideSidebar());

      if (window.location === window.parent.location && !(mobile != null && mobile === 'true')) {
        navigate(ROOT_PAGE_URL.NOT_FOUND, {
          replace: true,
        });
      }
    }
  }, [embed, is_embed]);

  useEffect(() => {
    if (!success) {
      if (scanId == null) {
        const errorPath = window.location.pathname;
        if (
          !(
            errorPath.includes(ROOT_PAGE_URL.SERVERS_BUSY) ||
            errorPath.includes(ROOT_PAGE_URL.UNDER_MAINTENANCE)
          )
        ) {
          if (embed != null && embed === 'true') {
            navigate(ROOT_PAGE_URL.SERVERS_BUSY);
          } else {
            navigate(`/404`);
          }
        }
      } else {
        getScannerInvokeDetails()
          .then(() => {
            console.log('getScannerInvokeDetails');
          })
          .catch(() => {
            console.log('getScannerInvokeDetails error');
          });
      }
    }
  }, [scanId, loggedInUserId, isLoggedIn, success, location.search, userProfile]);

  useEffect(() => {
    if (show && success && !is_embed) {
      console.log(inactivityTimer.activate());
      console.log(inactivityTimer.start());
      if (!viewer.all_tabs) {
        IsStagingServer &&
          dispatch(
            setCommonModal({
              // topElement: VideoPlayer,
              topElement: TutorialImagePlaceholder,
              topElementProps: {
                src: TutorialDummyPlaceholderImage,
                className: 'w-full mb-5 rounded-lg',
                // showControls: false,
              },
              title: 'Welcome to XSpada Viewer',
              content:
                "We're glad to have you onboard. Here are some quick tips to get you up and running.",
              dialogPaperProps: {
                className: '!max-w-[360px]',
              },
              dialogueActionClassName: 'flex-col',
              actionButtonsDir: 'row',
              negativeButtonLabel: 'Skip',
              negativeButtonAction: () => {
                onTutorialClose();
                dispatch(setCloseCommonModal());
              },
              buttons: [
                {
                  children: 'Start Tutorial',
                  id: 'start-tutorial',
                  onClick: () => {
                    dispatch(setCloseCommonModal());
                    dispatch(showTutorial(HOMEPAGE_STEPS));
                  },
                },
              ],
            }),
          );
      } else if (isShowControlsOnStart) {
        if (!window.localStorage.getItem('controls')) {
          dispatch(
            scanSetData({
              path: 'show_instructions',
              data: true,
            }),
          );
        }
      }
    }
  }, [viewer, isShowControlsOnStart, is_embed, show, success]);

  const takingLongTimerRef = useRef<any>(null);
  const timeoutTimerRef = useRef<any>(null);

  useEffect(() => {
    if (!takingLongTimerRef.current) {
      takingLongTimerRef.current = setTimeout(() => {
        setSocketLoadingStatus('Viewer is taking some time to load. Please hang on tight');
      }, 30 * 1000);
    }

    // if (!timeoutTimerRef.current) {
    //   timeoutTimerRef.current = setTimeout(() => {
    //     dispatch(
    //       setCommonModal({
    //         icon: <WarningLayerIcon width={48} height={48} />,
    //         title: 'Some Error Occurred!',
    //         content: 'We are having some issues loading your scan. Please reload your tab',
    //         dialogueActionClassName: 'flex-row',
    //         hasNegativeButton: false,
    //         dialogPaperProps: {
    //           className: '!max-w-[400px]',
    //         },
    //         buttons: [
    //           {
    //             children: 'Reload Viewer',
    //             color: 'error',
    //             onClick: () => {
    //               window.location.reload();
    //             },
    //           },
    //         ],
    //       }),
    //     );
    //   }, 90 * 1000);
    // }

    return () => {
      if (show && success) {
        clearTimeout(takingLongTimerRef.current);
        clearTimeout(timeoutTimerRef.current);
      }
    };
  }, [show, success]);

  // useEffect(() => {
  //   apiHandler({
  //     data: tutorialStatusData,
  //     error: tutorialStatusError,
  //     showToast: false,
  //     onSuccess(data) {
  //       // dispatch(
  //       //   setInitialTutorial(
  //       //     'viewer' in data.data.tutorials ? data.data.tutorials['viewer'] : { all_tabs: false },
  //       //   ),
  //       // );
  //     },
  //   });
  // }, [tutorialStatusData, tutorialStatusError]);

  useEffect(() => {
    if (
      waitingTimer.current == null &&
      loadingStatus === 'Please sit tight. Your viewing experience will begin momentarily'
    ) {
      waitingTimer.current = setTimeout(() => {
        navigate(ROOT_PAGE_URL.SERVERS_BUSY, { replace: true });
      }, 5 * 60 * 1000);
    }

    if (show != null && !(loadingStatus !== '' && !success) && waitingTimer.current != null) {
      clearTimeout(waitingTimer.current);
      resetCameraPosition();
    }
  }, [loadingStatus, success, show]);

  const getCameraPosition = () => {
    const cameraPosition = (sceneTree as any).metadata.camera_controls._camera.position;
    const cameraRotation = (sceneTree as any).metadata.camera_controls.getTarget();

    const position = {
      x: cameraPosition.x,
      y: cameraPosition.y,
      z: cameraPosition.z,
    };
    const rotation = {
      x: cameraRotation.x,
      y: cameraRotation.y,
      z: cameraRotation.z,
    };

    return { position, rotation };
  };

  const saveCameraPosition = async () => {
    const render_img = document.querySelector('#background-image')?.getAttribute('src');

    const renderImgFile = await dataURLtoFile(render_img, `snapshot_${scanId}_${Date.now()}.png`);

    uploadFile(renderImgFile as File)
      .then((response: any) => {
        const photoId = response.data.file_id;

        savePosition({
          scan_id: Number.parseInt(scanId ?? ''),
          data: {
            default_pos_img_id: photoId,
            ...getCameraPosition(),
          },
        })
          .then(() => {
            console.log('savePosition');
          })
          .catch(() => {
            console.log('savePosition error');
          });
      })
      .then(() => {
        console.log('uploadFile');
      })
      .catch(() => {
        console.log('uploadFile error');
      });
  };

  const resetCameraPosition = () => {
    if (cameraDefaults != null) {
      const positionAllZero =
        cameraDefaults.position.x === 0 &&
        cameraDefaults.position.y === 0 &&
        cameraDefaults.position.z === 0;

      const desiredPos = positionAllZero
        ? new THREE.Vector3(0.7, -0.7, 0.3)
        : new THREE.Vector3(
            cameraDefaults.position.x,
            cameraDefaults.position.y,
            cameraDefaults.position.z,
          );
      const desiredRotation = new THREE.Vector3(
        cameraDefaults.rotation.x,
        cameraDefaults.rotation.y,
        cameraDefaults.rotation.z,
      );

      cameraDefaultsRef.current = cameraDefaults;
      (sceneTree as any).metadata.camera_controls.setPosition(
        desiredPos.x,
        desiredPos.y,
        desiredPos.z,
      );
      (sceneTree as any).metadata.camera_controls.setLookAt(
        desiredPos.x,
        desiredPos.y,
        desiredPos.z,
        desiredRotation.x,
        desiredRotation.y,
        desiredRotation.z,
      );
    }
  };

  const updateCameraPosition = (cameraPosition: any) => {
    const positionAllZero =
      cameraPosition.position.x === 0 &&
      cameraPosition.position.y === 0 &&
      cameraPosition.position.z === 0;

    const desiredPos = positionAllZero
      ? new THREE.Vector3(0.7, -0.7, 0.3)
      : new THREE.Vector3(
          cameraPosition.position.x,
          cameraPosition.position.y,
          cameraPosition.position.z,
        );
    const desiredRotation = new THREE.Vector3(
      cameraPosition.rotation.x,
      cameraPosition.rotation.y,
      cameraPosition.rotation.z,
    );

    (sceneTree as any).metadata.camera_controls.setPosition(
      desiredPos.x,
      desiredPos.y,
      desiredPos.z,
    );
    (sceneTree as any).metadata.camera_controls.setLookAt(
      desiredPos.x,
      desiredPos.y,
      desiredPos.z,
      desiredRotation.x,
      desiredRotation.y,
      desiredRotation.z,
    );
  };

  useEffect(() => {
    rtkHandler(savePositionRes, {
      onSuccess(res) {
        const cameraPosition = res.set_default_point.model.position;
        const cameraRotation = res.set_default_point.model.rotation;

        if (cameraPosition != null && cameraRotation != null) {
          dispatch(
            scanSetData({
              path: 'camera',
              data: {
                position: cameraPosition,
                rotation: cameraRotation,
              },
            }),
          );

          cameraDefaultsRef.current = {
            position: cameraPosition,
            rotation: cameraRotation,
          };
        }
      },
    });
  }, [savePositionRes]);

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event?.target?.classList != null && event?.target?.classList.length > 0) return;

      if (event.code === 'Space') {
        if (cameraDefaultsRef.current == null) {
          (sceneTree as any).metadata.camera_controls.setLookAt(0.7, -0.7, 0.3, 0, 0, 0);
        } else {
          updateCameraPosition(cameraDefaultsRef.current);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);
  }, [sceneTree]);

  useEffect(() => {
    if (location.pathname === ROOT_PAGE_URL.DASHBOARD) {
      setTimeout(() => {
        setSuccess(false);
        setShow(false);
        dispatch(setData({ ...loadingStates, data: 'Accessing Scan Details' }));
      }, 0);
    }
  }, [location.search]);

  const scanData = {};

  return {
    is_embed,
    is_sidebar_open,
    is_sidebar_visible,
    scanData,
    sceneTree,
    show,
    success,
    setSuccess,
    loadingStatus,
    saveCameraPosition,
    resetCameraPosition,
    isCameraMoved,
    isScanOwner,
    isCameraMovedTest,
    setIsCameraMovedTest,
    isSnapshotDialogOpen,
    setIsSnapshotDialogOpen,
    getCameraPosition,
    updateCameraPosition,
    isStarterProfile,
    isScanSwitch,
    scan_images,
    clientId,
    spectatingClientId,
    cameraDefaults,
    isSnapshotAllowed,
    socketLoadingStatus,
  };
};

export default useThreeModel;
