interface ITutorialPopupImageProps {
  src: string
  className?: string
}

const TutorialImagePlaceholder = (props: ITutorialPopupImageProps) => {
  const { src, className } = props

  return <img src={src} className={className} />
}

export default TutorialImagePlaceholder
