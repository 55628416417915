import useThemeImages from 'assets/images'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useGetMyScansByIDQuery } from 'shared/graphql/utils/enhanced'
import useAuth from 'shared/hooks/useAuth'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { sendWebsocketMessage, WebSocketContext } from 'shared/socket/WebSocket'
import { rtkHandler } from 'shared/utils/handlers'
import { setData } from 'store/slices/scan/threeJSReducer'
import {
  setCloseCommonModal,
  setCommonModal
} from 'store/slices/utils/commonModalSlice'

import { type IPerson } from './ShareViewer.type'

const useShareViewer = () => {
  const { user } = useAuth()

  const { LogoutIcon } = useThemeImages()
  const dispatch = useAppDispatch()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const scanId = urlParams.get('scan_id')

  const scanner_id = useAppSelector(state => state.scan.scanner.id)

  const websocket: any = useContext(WebSocketContext)

  const { client_id, spectating_client_id, viewers } = useAppSelector(
    state => state.threeJs.websocketState
  )

  const peopleRes = useGetMyScansByIDQuery(
    { id: Number.parseInt(scanId ?? '') },
    { skip: scanId === undefined }
  )

  const [people, setPeople] = useState<IPerson[]>([])
  const [link] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const anchorElRef = useRef(null)

  const openShareDetails = () => {
    setIsOpen(true)
  }
  const closeShareDetails = () => {
    setIsOpen(false)
  }

  const isScanOwner = useMemo(() => {
    return user != null && user.user_id === Number.parseInt(scanner_id)
  }, [user, scanner_id])

  const onExitSpectatorViewClickedHandler = () => {
    let spectateClientName = 'Anonymous'
    if (viewers.length > 0) {
      const spectator = viewers.filter(
        (viewer: {
          client_id: number
          id: string
          name: string
          img: string
        }) => viewer.client_id === spectating_client_id
      )

      if (spectator.length > 0) {
        spectateClientName = spectator[0].name
      }
    }

    dispatch(
      setCommonModal({
        icon: <LogoutIcon width={48} height={48} />,
        title: 'Leave Spectator View',
        content: `Do you want to leave spectator view with ${spectateClientName}?`,
        actionButtonsDir: 'row',
        dialogPaperProps: {
          className: '!max-w-[360px]'
        },
        buttons: [
          {
            children: 'Leave',
            color: 'error',
            onClick: () => {
              sendWebsocketMessage(websocket, {
                type: 'SpectateMessage',
                client_id: client_id ?? 0
              })
              dispatch(
                setData({
                  path: 'websocketState/spectating_client_id',
                  data: client_id ?? 0
                })
              )
              dispatch(setCloseCommonModal())
            }
          }
        ]
      })
    )
  }

  useEffect(() => {
    rtkHandler(peopleRes, {
      showToast: false,
      onSuccess(res) {
        setPeople('invitees' in res.scan ? res.scan.invitees : [])
      }
    })
  }, [peopleRes])

  return {
    people,
    link,
    anchorElRef,
    isOpen,
    openShareDetails,
    closeShareDetails,
    client_id,
    spectating_client_id,
    onExitSpectatorViewClickedHandler,
    isScanOwner
  }
}

export default useShareViewer
