import { yupResolver } from '@hookform/resolvers/yup'
import { useGoogleLogin } from '@react-oauth/google'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import {
  useAppleLoginMutation,
  useGoogleLoginMutation,
  useLoginMutation,
  useSwitchProfileMutation
} from 'shared/graphql/utils/enhanced'
import { useAppDispatch } from 'shared/hooks/useRedux'
import { type LoginPayload } from 'shared/types/auth/login.type'
import { rtkHandler } from 'shared/utils/handlers'
import yup from 'shared/utils/yup'

const useLoginScreen = () => {
  const [doLogin, loginRes] = useLoginMutation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [sendGoogleLoginCode, googleLoginRes] = useGoogleLoginMutation()
  const [sendAppleLoginCode, appleLoginRes] = useAppleLoginMutation()

  const [switchProfile, switchProfileRes] = useSwitchProfileMutation()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const userEmail = urlParams.get('user_email')
  const profileId = urlParams.get('profile_id')

  const hasUserEmail = useMemo(() => {
    return userEmail !== undefined
  }, [userEmail])

  const schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .test('required', 'This field is required', val => {
        const type = getValues('type')
        if (type === 'email') {
          return val != null
        }
        return true
      }),
    phone_number: yup
      .string()
      .test('required', 'This field is required', val => {
        const type = getValues('type')
        if (type === 'phone') {
          return val != null
        }
        return true
      })
      .test('phone', 'Invalid phone number', val => {
        if (val != null) {
          return isValidPhoneNumber(val)
        }
        return true
      }),
    password: yup.string().required(),
    remember_me: yup.boolean()
  })

  const { control, handleSubmit, watch, setValue, getValues, setError } =
    useForm<LoginPayload>({
      resolver: yupResolver(schema),
      defaultValues: {
        email: userEmail ?? '',
        phone_number: '',
        password: '',
        remember_me: false,
        type: 'email'
      }
    })

  const fieldType = watch('type')

  useEffect(() => {
    if (fieldType === 'email') {
      setValue('phone_number', '')
    } else {
      setValue('email', userEmail ?? '')
    }
  }, [fieldType])

  type PayloadWithoutType = keyof Omit<LoginPayload, 'type'>

  const onSubmit = (data: LoginPayload) => {
    const { type, ...rest } = data
    const formData = Object.keys(rest).reduce<LoginPayload>((acc, key) => {
      if (rest[key as PayloadWithoutType] !== '') {
        // @ts-expect-error - any type
        acc[key] = rest[key]
      }
      return acc
      // @ts-expect-error - any type
    }, {})

    if (profileId != null) {
      // @ts-expect-error - any type
      formData.profile_id = profileId
    }

    doLogin({
      data: {
        // @ts-expect-error - any type
        email: formData.email,
        // @ts-expect-error - any type
        password: formData.password
      }
    })
      .then(() => {
        console.log('success')
      })
      .catch(() => {
        console.log('error')
      })
  }

  useEffect(() => {
    rtkHandler(loginRes, {
      onSuccess(res) {
        console.log(res)
        if (
          ('profile' in res.login && res.login.profile == null) ||
          !('profile' in res.login)
        ) {
          window.open(
            `${process.env.REACT_APP_HOME_PAGE_URL}/account-creation`,
            '_self'
          )
        }

        if (
          'profile' in res.login &&
          res.login.profile != null &&
          profileId != null &&
          res.login.profile.profile_id !== profileId
        ) {
          setTimeout(() => {
            switchProfile({
              id: Number.parseInt(profileId)
            })
              .then(() => {
                console.log('success')
              })
              .catch(() => {
                console.log('error')
              })
          }, 0)
        }
      },
      onError(res) {
        console.log(res)
      }
    })
  }, [loginRes, navigate, dispatch, getValues, setError])

  useEffect(() => {
    rtkHandler(googleLoginRes, {
      onSuccess(res) {
        if (
          ('profile' in res.google_login && res.google_login.profile == null) ||
          !('profile' in res.google_login)
        ) {
          window.open(
            `${process.env.REACT_APP_HOME_PAGE_URL}/account-creation`,
            '_self'
          )
        }

        if (
          'profile' in res.google_login &&
          res.google_login.profile != null &&
          profileId != null &&
          res.google_login.profile.profile_id !== profileId
        ) {
          switchProfile({
            id: Number.parseInt(profileId)
          })
            .then(() => {
              console.log('success')
            })
            .catch(() => {
              console.log('error')
            })
        }
      },
      onError(res) {
        console.log('error')
      }
    })
  }, [googleLoginRes])

  useEffect(() => {
    rtkHandler(appleLoginRes, {
      onSuccess(res) {
        if (
          ('profile' in res.apple_login && res.apple_login.profile == null) ||
          !('profile' in res.apple_login)
        ) {
          window.open(
            `${process.env.REACT_APP_HOME_PAGE_URL}/account-creation`,
            '_self'
          )
        }

        if (
          'profile' in res.apple_login &&
          res.apple_login.profile != null &&
          profileId != null &&
          res.apple_login.profile.profile_id !== profileId
        ) {
          switchProfile({
            id: Number.parseInt(profileId)
          })
            .then(() => {
              console.log('success')
            })
            .catch(() => {
              console.log('error')
            })
        }
      },
      onError(res) {
        console.log('error')
      }
    })
  }, [appleLoginRes])

  useEffect(() => {
    rtkHandler(switchProfileRes, {
      onSuccess(res) {
        console.log('success')
      }
    })
  }, [switchProfileRes])

  const doGoogleLogin = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      sendGoogleLoginCode({
        data: {
          code: codeResponse.code,
          redirect_uri: process.env.REACT_APP_SOCIAL_AUTH_REDIRECT_URI ?? ''
        }
      })
        .then(() => {
          console.log('success')
        })
        .catch(() => {
          console.log('error')
        })
    },
    flow: 'auth-code'
  })

  const doAppleLogin = (code: string) => {
    sendAppleLoginCode({
      data: {
        code,
        type: 'apple',
        redirect_uri: process.env.REACT_APP_SOCIAL_AUTH_REDIRECT_URI ?? ''
      }
    })
      .then(() => {
        console.log('success')
      })
      .catch(() => {
        console.log('error')
      })
  }

  return {
    isLoading: loginRes.isLoading,
    control,
    socialLoading: googleLoginRes.isLoading && appleLoginRes.isLoading,
    doGoogleLogin,
    doAppleLogin,
    handleSubmit,
    onSubmit,
    hasUserEmail
  }
}

export default useLoginScreen
