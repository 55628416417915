import { Box } from '@mui/material'
import LOGO from 'assets/images/logo.png'
import DotLoader from 'shared/loader/DotLoader'

const PageLoader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: 'white',
        fontSize: '1.5rem',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1_000_000,
        backgroundColor: '#1E1E1E'
      }}
    >
      <img
        src={LOGO}
        style={{
          height: 40
        }}
        alt="Logo"
      />
      <span style={{ marginTop: 20 }}>
        <DotLoader />
      </span>
    </Box>
  )
}

export default PageLoader
