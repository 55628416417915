import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { type RootState } from 'store'

// create api slice
const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: async (headers, { getState }) => {
      const states = getState() as RootState
      const token = states.auth.token
      if (token != null) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
    async responseHandler(response) {
      const data = await response.json()
      if (response.ok) {
        return data
      }
      throw data
    }
  }),
  tagTypes: ['Invitees', 'Snapshots'],
  endpoints: builder => ({})
})

export default apiSlice
