import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROOT_PAGE_URL } from 'shared/constants/navigator'
import { useGetPublicScanByIDQuery } from 'shared/graphql/utils/enhanced'
import { type IScan } from 'shared/types/scan/scan.type'
import { rtkHandler } from 'shared/utils/handlers'

const useEmbedScreen = () => {
  const navigate = useNavigate()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const scanId = urlParams.get('scan_id')

  const scanRes = useGetPublicScanByIDQuery({
    id: Number.parseInt(scanId ?? '')
  })
  // const [isLoading, setIsLoading] = useState(true);

  const [scan, setScan] = useState<IScan | null>(null)
  const [play, setPlay] = useState(false)
  const [isReady, setIsReady] = useState(false)

  const togglePlay = () => {
    setPlay(prev => !prev)
  }

  const readyToPlay = () => {
    setIsReady(true)
  }

  const onStartViewer = () => {
    const queryParams = {}

    if (scanId != null) (queryParams as any).scan_id = scanId
    ;(queryParams as any).embed = 'true'

    navigate(
      ROOT_PAGE_URL.DASHBOARD +
        '?' +
        new URLSearchParams(queryParams).toString(),
      {
        replace: true
      }
    )
  }

  useEffect(() => {
    rtkHandler(scanRes, {
      showToast: false,
      onSuccess(res) {
        setScan(res.public_scan)
      },
      onError() {
        navigate(`/404`);
      },
    });
  }, [scanRes]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     setScan({
  //       _id: scanId!,
  //       scan_name: 'Scan Name',
  //       input_videos: [
  //         {
  //           thumbnail:
  //             'https://customer-5hb9qy2b7x24by0p.cloudflarestream.com/45fa34a905c0582d0b3f1fd75828b265/thumbnails/thumbnail.jpg?height=1200',
  //           url:
  //             'https://customer-5hb9qy2b7x24by0p.cloudflarestream.com/45fa34a905c0582d0b3f1fd75828b265/manifest/video.m3u8',
  //         },
  //       ],
  //       user_id: {
  //         name: 'Shehriyar Shariq',
  //       },
  //     });
  //   }, 5000);
  // }, []);

  return {
    scan,
    onStartViewer,
    isLoading: scanRes.isLoading,
    play,
    togglePlay,
    isReady,
    readyToPlay
  }
}

export default useEmbedScreen
