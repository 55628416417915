import { useState } from 'react'

const useGroupChat = () => {
  const [tab, setTab] = useState<'messages' | 'comments'>('messages')

  const onClickTab = (clickTab: 'messages' | 'comments') => {
    setTab(clickTab)
  }

  return { tab, onClickTab }
}

export default useGroupChat
