import { Badge, IconButton, Tooltip } from '@mui/material'

import { type IAppIconButtonProps } from './AppIconButton.type'
import useAppIconButton from './useAppIconButton'

const AppIconButton = (props: IAppIconButtonProps) => {
  const { styles, badegStyles } = useAppIconButton(props)

  const {
    icon,
    size = 'medium',
    color = 'default',
    badgeCount,
    badgeColor = 'primary',
    className,
    onClick,
    tooltipTitle,
    tooltipPosition = 'top',
    disabled = false
  } = props

  const Wrapper = ({ children }: { children: any }) => {
    if (disabled) {
      return <div>{children}</div>
    }
    return (
      <Tooltip title={tooltipTitle} arrow placement={tooltipPosition}>
        {children}
      </Tooltip>
    )
  }

  return (
    <Wrapper>
      <IconButton
        onClick={onClick}
        size={size}
        color={color}
        sx={styles}
        className={className}
        disabled={disabled}
      >
        <Badge color={badgeColor} badgeContent={badgeCount} sx={badegStyles}>
          {icon}
        </Badge>
      </IconButton>
    </Wrapper>
  )
}

export default AppIconButton
