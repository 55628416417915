import AppIconButton from 'shared/components/AppIconButtonWebApp'

const ActionButton = ({
  Icon,
  ActiveIcon,
  iconProps = {},
  text,
  textColor,
  activeText,
  activeTextColor,
  iconHoverClassName,
  textHoverClassName,
  onClick,
  isActive = false,
  isErrorButton = false,
  isAlt = false,
  width
}: {
  Icon: any
  ActiveIcon?: any
  iconProps: any
  text: string
  textColor?: string
  activeText?: string
  activeTextColor?: string
  iconHoverClassName?: string
  textHoverClassName?: string
  onClick: () => void
  isActive?: boolean
  isErrorButton?: boolean
  isAlt?: boolean
  width?: string
}) => {
  return (
    <div
      className={`${width ?? 'w-[60px]'} flex ${
        isAlt && 'flex-col justify-center'
      } items-center space-x-1 hover:cursor-pointer group`}
      onClick={e => {
        e.stopPropagation()
        onClick()
      }}
    >
      <AppIconButton
        icon={
          isActive && ActiveIcon != null ? (
            <ActiveIcon />
          ) : (
            <Icon
              {...iconProps}
              {...(isActive
                ? {
                    color: isErrorButton ? '#EB617A' : '#7680FF',
                    opacity: 1
                  }
                : { opacity: 1 })}
            />
          )
        }
        className={
          !isActive && iconHoverClassName != null
            ? iconHoverClassName
            : `${
                !isActive &&
                (isErrorButton
                  ? 'group-hover:action_button_delete_hover'
                  : 'group-hover:action_button_hover')
              }`
        }
      />
      <span
        className={`text-[13px] ${textColor ?? 'text-p'} text-opacity-50 ${
          textHoverClassName == null &&
          (isErrorButton
            ? 'group-hover:text-[#fa8c9f]'
            : 'group-hover:text-[#ADB3FF]')
        } ${textHoverClassName} ${
          isActive &&
          activeTextColor == null &&
          ' !text-[#7680FF] text-opacity-100 '
        } ${
          isActive &&
          activeTextColor == null &&
          isErrorButton &&
          ' !text-[#EB617A] '
        }`}
        style={
          isActive && activeTextColor == null ? { color: activeTextColor } : {}
        }
      >
        {isActive && activeText != null ? activeText : text}
      </span>
    </div>
  )
}

export default ActionButton
