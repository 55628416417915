import { Box } from '@mui/material'
import LOGO from 'assets/images/logo.png'
import { useAppSelector } from 'shared/hooks/useRedux'
import DotLoader from 'shared/loader/DotLoader'

import { type IThreeJsLoaderProps } from './ThreeJsLoader.type'

const ThreeJsLoader = (props: IThreeJsLoaderProps) => {
  const loaderBranding = useAppSelector(state => state.app.loader_branding)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: 'white',
        fontSize: '1.5rem',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 1000,
        backgroundColor: loaderBranding?.brand_color ?? '#1E1E1E'
      }}
    >
      <img
        src={loaderBranding?.team_logo?.url ?? LOGO}
        style={{
          height: loaderBranding?.team_logo == null ? 40 : 80
        }}
        alt="Logo"
      />
      {loaderBranding?.team_name == null ? (
        <span
          style={{ marginTop: 20, textAlign: 'center' }}
          className="text-lg lg:text-[20px]"
        >
          {props.loadingStatus}
          <DotLoader />
        </span>
      ) : (
        <>
          <div className="text-white text-[24px] font-medium mt-[20px]">
            {loaderBranding?.team_name}
          </div>
          {loaderBranding?.team_bio != null && (
            <div className="text-white text-[20px] opacity-75 mt-3">
              {loaderBranding?.team_bio}
            </div>
          )}
        </>
      )}
      {loaderBranding?.team_name != null && (
        <div className="absolute w-full bottom-0 left-0 grid grid-cols-3 items-end p-8">
          <div className="w-full"></div>
          <div className="w-full text-center">
            <span className="opacity-50 text-[20px] text-center">
              {props.loadingStatus}
              <DotLoader />
            </span>
          </div>
          <div className="w-full text-right text-white text-md">
            Powered By <span className="text-[#7680FF]">XSpada</span>
          </div>
        </div>
      )}
    </Box>
  )
}

export default ThreeJsLoader
