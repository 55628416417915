import { Button, type ButtonProps, DialogActions } from '@mui/material'
import LoaderButton from 'shared/components/LoaderButton/LoaderButton'
import { useAppSelector } from 'shared/hooks/useRedux'
import { type CommonModalButtons } from 'store/slices/utils/commonModalSlice'

const CommonModalActionButton = ({
  buttons = [],
  className,
  hasNegativeButton = true,
  cancelBtnLabel,
  onCancelBtnClick = () => {
    console.log('onCancelBtnClick')
  },
  direction = 'column',
  cancelBtnProps: { className: cancelBtnClassName, ...cancelBtnProps } = {},
  fontSize = '!text-md'
}: {
  buttons?: CommonModalButtons[]
  className?: string
  hasNegativeButton?: boolean
  cancelBtnLabel?: string
  onCancelBtnClick: () => void
  direction?: 'row' | 'column'
  cancelBtnProps?: ButtonProps
  fontSize?: string
}) => {
  const loadingBtn = useAppSelector(state => state.commonModal.loadingBtn)

  return (
    <DialogActions
      className={
        `w-full gap-3 ${className} ` +
        (direction === 'row'
          ? ' flex !flex-row-reverse items-center gap-3 '
          : ' ')
      }
    >
      {buttons != null && buttons?.length > 0 ? (
        <>
          {buttons.map(
            ({ children, id, onClick, isCloseBtn, ...others }, index) => {
              return (
                <LoaderButton
                  id={id ?? `common-modal-btn-${index}`}
                  key={index}
                  className="h-11 !ml-0"
                  variant="contained"
                  fullWidth
                  loading={id === loadingBtn}
                  disabled={id === loadingBtn && Boolean(loadingBtn)}
                  onClick={e => {
                    if (isCloseBtn != null) {
                      onCancelBtnClick()
                    }
                    onClick?.(e)
                  }}
                  {...others}
                >
                  {children}
                </LoaderButton>
              )
            }
          )}
          {hasNegativeButton && (
            <Button
              className="h-11 !ml-0"
              variant="outlined"
              fullWidth
              disabled={Boolean(loadingBtn)}
              onClick={onCancelBtnClick}
            >
              {cancelBtnLabel ?? 'Cancel'}
            </Button>
          )}
        </>
      ) : (
        <Button
          className="h-11 !ml-0"
          variant="outlined"
          fullWidth
          onClick={onCancelBtnClick}
        >
          Cancel
        </Button>
      )}
    </DialogActions>
  )
}

export default CommonModalActionButton
