import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type IAuthSliceStates } from 'shared/types/auth/authReducer.type'

const initialState: IAuthSliceStates = {
  user: null,
  token: null,
  userProfile: null
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUserSignInInfo(state, action: PayloadAction<IAuthSliceStates>) {
      state.user = action.payload.user
      if (action.payload.token != null) state.token = action.payload.token
      if (action.payload.userProfile != null)
        state.userProfile = action.payload.userProfile
    },
    setUserDetailInfo(
      state,
      { payload }: PayloadAction<IAuthSliceStates['user']>
    ) {
      state.user = {
        ...state.user,
        ...payload
      } as any
    },
    logout(state) {
      state.user = null
      state.token = null
    }
  }
})

export default authSlice.reducer

export const { setUserSignInInfo, setUserDetailInfo, logout } =
  authSlice.actions
