export const ROOT_PAGE_URL = {
  /*
   * Guest Routes
   */
  LOGIN: '/',

  INVITATION: '/invitation',

  EMBED_SCREEN: '/embed',

  /*
   * Error Routes
   */
  SERVERS_BUSY: '/server_busy',
  PROCESSING_ERROR: '/processing_error',
  UNDER_MAINTENANCE: '/503',
  NOT_FOUND: '/404',

  /*
   * Authenticated Routes
   */
  DASHBOARD: '/dashboard',
  // Free Roam
  FREE_ROAM: '/free-roam',
  // Video Capture
  VIDEO_CAPTURE: '/video-capture',
  // Object Detection
  OBJECT_DETECTION: '/object-detection',
  // Settings
  SNAPSHOTS: '/snapshots',
  // Settings
  NOTES: '/notes',
  // Settings
  RECORDINGS: '/recordings'
}
