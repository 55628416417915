import { useAppSelector } from 'shared/hooks/useRedux'

const useDashboardScreen = () => {
  const { is_embed, scannerDetails } = useAppSelector(state => ({
    is_embed: (state as any).app.is_embed,
    scannerDetails: (state as any).scan.scanner
  }))

  const { client_id, spectating_client_id } = useAppSelector(
    state => state.threeJs.websocketState
  )

  return { is_embed, scannerDetails, client_id, spectating_client_id }
}

export default useDashboardScreen
