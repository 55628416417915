import { useAppSelector } from './useRedux'

const useAuth = () => {
  const { auth } = useAppSelector(state => state)

  if (auth == null) {
    throw new Error('Auth is not defined')
  }

  return { ...auth }
}

export default useAuth
