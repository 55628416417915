import { Tooltip } from '@mui/material'
import ProfilePic from 'assets/images/dummy-profile.png'
import { useContext } from 'react'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { sendWebsocketMessage, WebSocketContext } from 'shared/socket/WebSocket'
import { compressSidebar } from 'store/slices/app/appSlice'
import { setData } from 'store/slices/scan/threeJSReducer'

interface IPersonItemProps {
  client_id: number
  id: string
  name: string
  img: string
}

const PersonItem = (props: IPersonItemProps) => {
  const dispatch = useAppDispatch()
  const websocket: any = useContext(WebSocketContext)

  const { spectating_client_id, spectators } = useAppSelector(
    state => state.threeJs.websocketState
  )

  const onPersonClickedHandler = (e: any) => {
    e.stopPropagation()
    if (props.client_id !== spectating_client_id) {
      sendWebsocketMessage(websocket, {
        type: 'SpectateMessage',
        client_id: props.client_id
      })
      dispatch(
        setData({
          path: 'websocketState/spectating_client_id',
          data: props.client_id
        })
      )
      dispatch(compressSidebar())
    }
  }

  return (
    <Tooltip
      title={spectators.includes(props.client_id) ? 'Spectators' : props.name}
      arrow
      placement="bottom"
    >
      <div
        className={`w-8 h-8 rounded-full hover:cursor-pointer bg-[#1E1E1E] ${
          spectators.includes(props.client_id) ||
          spectating_client_id === props.client_id
            ? ' border-2 border-[#15AA2C]'
            : 'border border-[#FFFFFF66]'
        } object-cover overflow-hidden -ml-[6px]`}
        onClick={onPersonClickedHandler}
      >
        <img src={props.img ?? ProfilePic} className="w-full h-full" />
      </div>
    </Tooltip>
  )
}

export default PersonItem
