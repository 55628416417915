import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { splitPath } from 'shared/utils/utils'

interface ScanState {
  scan?: {
    scan_id: string;
    name: string;
    project_id?: string;
    input_file?: {
      thumbnail: string;
      url: string;
    };
  };
  scanner: {
    id: string;
    name: string;
    email: string;
    profile_image: string;
  };
  link: {
    public_link: string
    is_loading: boolean
  }
  show_instructions: boolean
  camera?: {
    default_pos_img?: {
      url: string;
      thumbnail: string;
    };
    position: {
      x: number
      y: number
      z: number
    }
    rotation: {
      x: number
      y: number
      z: number
    }
  }
  is_camera_moved: boolean
}

const initialState: ScanState = {
  scan: undefined,
  scanner: {
    id: '',
    name: '',
    email: '',
    profile_image: '',
  },
  link: {
    public_link: '',
    is_loading: false
  },
  show_instructions: false,
  camera: undefined,
  is_camera_moved: false
}

const scanSlice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<{ path: string; data: any }>) => {
      const path = splitPath(action.payload.path)
      const data = action.payload.data
      const newState = { ...state }
      setDataHelper(newState, state, path, data)
      return newState
    }
  }
})

function setDataHelper(newState: any, state: any, path: string[], data: any) {
  if (path.length === 1) {
    newState[path[0]] = data
  } else {
    newState[path[0]] = { ...state[path[0]] }
    setDataHelper(newState[path[0]], state[path[0]], path.slice(1), data)
  }
}

export const { setData } = scanSlice.actions

export default scanSlice.reducer
