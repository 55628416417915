import { type StepType } from '@reactour/tour'
import { createSlice } from '@reduxjs/toolkit'

interface TutorialState {
  is_visible: boolean
  steps: StepType[]
  viewer: {
    all_tabs: boolean
  }
}

const initialState: TutorialState = {
  is_visible: false,
  steps: [],
  viewer: {
    all_tabs: true
  }
}

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setInitialTutorial: (state, action) => {
      state.viewer = action.payload
    },
    updateTutorialState: (state, action) => {
      ;(state.viewer as any)[action.payload as string] = true
    },
    showTutorial: (state, action) => {
      state.steps = action.payload
      state.is_visible = true
    },
    dismissTutorial: state => {
      state.is_visible = false
    }
  }
})

export const {
  setInitialTutorial,
  updateTutorialState,
  showTutorial,
  dismissTutorial
} = tutorialSlice.actions

export default tutorialSlice.reducer
