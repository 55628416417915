import useThemeImages from 'assets/images'
import { useState } from 'react'

interface IProgressiveImageProps {
  src: string
  className: string
  Loader: any
}

const ProgressiveImage = (props: IProgressiveImageProps) => {
  const { src, className, Loader } = props
  const { ProfileIcon } = useThemeImages()

  const [isLoading, setIsLoading] = useState(true)

  const rendered = () => {
    setIsLoading(false)
  }

  const startRender = () => {
    requestAnimationFrame(rendered)
  }

  const loaded = () => {
    requestAnimationFrame(startRender)
  }

  return src == null ? (
    <ProfileIcon className={className} />
  ) : (
    <div className="relative">
      <img
        src={src}
        className={`${className} ${isLoading && 'opacity-0'}`}
        onLoad={() => {
          loaded()
        }}
      />
      {isLoading && <div className="absolute top-0 left-0 z-[2]">{Loader}</div>}
    </div>
  )
}

export default ProgressiveImage
