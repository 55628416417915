import { TourProvider } from '@reactour/tour'

import Walkthrough from './partials/Walkthrough'
import { Badge, Close, Navigation } from './partials/WalkthroughElements'
import useWalkthroughLayout from './useWalkthroughLayout'

const WalkthroughLayout = ({ children }: any) => {
  const { onTutorialClose } = useWalkthroughLayout()

  return (
    <TourProvider
      steps={[]}
      components={{ Close, Badge, Navigation }}
      onClickClose={onTutorialClose}
      onClickMask={onTutorialClose}
    >
      <Walkthrough>{children}</Walkthrough>
    </TourProvider>
  )
}

export default WalkthroughLayout
