import { Tab, Tabs, Tooltip } from '@mui/material'
import useThemeImages from 'assets/images'
import ProfilePic from 'assets/images/dummy-profile.png'
import Logo from 'assets/images/logo.png'
import React, { useContext, useMemo } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAppDispatch } from 'shared/hooks/useRedux'
import useScreen from 'shared/hooks/useScreen'
import { sendWebsocketMessage, WebSocketContext } from 'shared/socket/WebSocket'
import { openLogInDialog } from 'store/slices/app/appSlice'
import { setData } from 'store/slices/scan/threeJSReducer'
import {
  setCloseCommonModal,
  setCommonModal
} from 'store/slices/utils/commonModalSlice'

import FilesTab from './partials/FilesTab/FilesTab'
import useSidebar from './useSidebar'

function Sidebar() {
  const {
    is_logged_in,
    open,
    is_visible,
    toggleSidebarVisibility,
    menus,
    user,
    handleControlsClick,
    handleHelpClick,
    isTeamProfile,
    has_project,
    selectedTab,
    setSelectedTab,
    scan_name,
    dashboardSelectedTab,
    clientId,
    spectatingClientId
  } = useSidebar()
  const { HelpCircleIcon, MenuIcon, ControlsIcon, EmailGrayIcon, LogoutIcon } =
    useThemeImages()
  const { isMobile } = useScreen()

  const websocket: any = useContext(WebSocketContext)

  const dispatch = useAppDispatch()

  const SidebarElement = ({ children, url, selector, onClick }: any) => {
    if (url != null) {
      return (
        <NavLink
          to={url}
          className={({ isActive }) => {
            return `${selector} group text-white pointer-events-none ${
              isActive ? 'text-opacity-100 active' : 'text-opacity-50'
            }`
          }}
        >
          {children}
        </NavLink>
      )
    }

    return (
      <div
        className={`${selector} group text-white ${
          onClick == null ? 'pointer-events-none' : 'hover:cursor-pointer'
        } ${
          selector === dashboardSelectedTab
            ? 'text-opacity-100 active'
            : 'text-opacity-50'
        }`}
        onClick={onClick}
      >
        {children}
      </div>
    )
  }

  const MenuItemsList = useMemo(() => {
    return menus.map(
      ({ icon, title, url, iconActiveClass, selector, onClick }, index) => {
        return (
          <SidebarElement
            key={index}
            url={url}
            selector={selector}
            onClick={onClick}
          >
            <Tooltip title={open ? '' : title} placement="right" arrow>
              <div className="flex items-center space-x-[10px]">
                <div
                  className={`min-w-[44px] w-11 h-11 justify-center items-center flex rounded-lg group-[.active]:bg-white group-[.active]:bg-opacity-5 opacity-50 group-[.active]:opacity-100 ${iconActiveClass}`}
                >
                  {icon}
                </div>
                <p className="active-hidden font-medium text-[#FFFFFF66] text-lg group-hover:text-opacity-100 transition-all duration-300">
                  {title}
                </p>
              </div>
            </Tooltip>
          </SidebarElement>
        )
      }
    )
  }, [dashboardSelectedTab])

  return (
    <div
      className={`group sidebar md:flex flex-col pointer-events-auto relative ${
        open ? '' : isMobile ? 'sidebar--hide' : 'sidebar--collapsed'
      } ${!is_visible && 'hidden'}`}
    >
      {clientId !== spectatingClientId && (
        <div
          className="w-full h-full absolute top-0 left-0 z-[1]"
          onClick={e => {
            e.stopPropagation()
            dispatch(
              setCommonModal({
                icon: <LogoutIcon width={48} height={48} />,
                title: 'Leave Spectator View',
                content: 'Do you want to leave spectator view?',
                actionButtonsDir: 'row',
                dialogPaperProps: {
                  className: '!max-w-[360px]'
                },
                buttons: [
                  {
                    children: 'Leave',
                    color: 'error',
                    onClick: () => {
                      sendWebsocketMessage(websocket, {
                        type: 'SpectateMessage',
                        client_id: clientId ?? 0
                      })
                      dispatch(
                        setData({
                          path: 'websocketState/spectating_client_id',
                          data: clientId ?? 0
                        })
                      )
                      dispatch(setCloseCommonModal())
                    }
                  }
                ]
              })
            )
          }}
        ></div>
      )}
      <div
        className={`flex items-center space-x-5 h-[60px] lg:h-[80px] px-2 lg:px-6 ${
          !is_logged_in && 'justify-center'
        }`}
      >
        {/* {is_logged_in && ( */}
        <button
          className="cursor-pointer min-w-[44px] w-11 flex items-center justify-center"
          onClick={() => {
            toggleSidebarVisibility()
          }}
        >
          <MenuIcon color="white" opacity={open ? 0.5 : 1} />
        </button>
        {/* )} */}
        {!isMobile && (
          <Link
            to={process.env.REACT_APP_HOME_PAGE_URL ?? ''}
            className={'whitespace-nowrap ' + (open ? '' : 'hidden')}
          >
            <img src={Logo} className="max-w-[112px]" />
          </Link>
        )}
      </div>
      <div className="flex flex-1 flex-col sidebar-body">
        <div className="flex-1 pt-2 pl-2 lg:pl-6 p-6">
          {is_logged_in && (
            <>
              {/* <label className="text-white text-opacity-40 text-md mb-4 block uppercase">Menu</label> */}
              {isTeamProfile &&
                has_project &&
                (open ? (
                  <Tabs
                    value={selectedTab}
                    onChange={(_, newValue: number) => {
                      setSelectedTab(newValue)
                    }}
                    className="w-full !min-h-[44px] !h-[44px] bg-[#555767] rounded-lg p-1 mb-3"
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        alignItems: 'center',
                        gapRow: '4px'
                      },
                      '& .MuiTabs-indicator': {
                        display: 'none'
                      }
                    }}
                  >
                    <Tab label="Files" {...a11yProps(0, selectedTab === 0)} />;
                    <Tab label="Tools" {...a11yProps(1, selectedTab === 1)} />;
                  </Tabs>
                ) : (
                  <div className="text-md text-[#FFFFFF66] uppercase mb-4">
                    {['Files', 'Tools'][selectedTab]}
                  </div>
                ))}

              <>
                <TabPanel value={selectedTab} index={0}>
                  <FilesTab />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                  <div>
                    {open && (
                      <div className="text-lg text-[#FFFFFF] mb-2 overflow-hidden text-ellipsis">
                        {scan_name}
                      </div>
                    )}
                    <div className="flex flex-col space-y-2">
                      {MenuItemsList}
                    </div>
                  </div>
                </TabPanel>
              </>
            </>
          )}
        </div>
        <div className="flex-col space-y-3 p-6">
          <FooterNavItem
            onClick={handleControlsClick}
            icon={<ControlsIcon width={14} height={14} />}
            title={'Viewing Controls'}
          />
          <FooterNavItem
            onClick={handleHelpClick}
            icon={<HelpCircleIcon width={14} height={14} />}
            title={'Watch Tutorial'}
            className="!hidden lg:!flex"
          />
          <a
            href="mailto:support@xspada.com"
            className="block"
            target="_blank"
            rel="noreferrer"
          >
            <FooterNavItem
              onClick={() => {
                console.log('clicked')
              }}
              icon={<EmailGrayIcon width={14} height={14} />}
              title={'Customer Support'}
            />
          </a>
          <div
            className={`flex items-center justify-center ${
              !is_logged_in && 'cursor-pointer'
            }`}
            onClick={() => {
              if (!is_logged_in) {
                dispatch(openLogInDialog())
              }
            }}
          >
            <img
              className="h-8 w-8 rounded-lg object-cover overflow-hidden"
              src={user && 'profile_image' in user ? (user! as any).profile_image.url : ProfilePic}
              alt="Profile Picture"
            />
            <div className="w-[calc(100%-32px)] space-y-1 text-white group-[.sidebar--collapsed]:pl-0 group-[.sidebar--collapsed]:!w-0 pl-4 transition-all duration-300 active-hidden delay-300">
              <h5 className="text-sm font-medium leading-4">
                {user?.name ?? 'Sign In'}
              </h5>
              <p className="text-white text-opacity-40 text-ellipsis overflow-hidden text-[11px] w-full">
                {user?.email ?? 'Click to sign into your account'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Sidebar)

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  icon: React.ReactNode
}

const FooterNavItem = ({ icon, title, className = '', ...rest }: Props) => {
  return (
    <div
      className={`flex items-center justify-center cursor-pointer ${className}`}
      {...rest}
    >
      <div
        className={`w-8 h-8 bg-[#FFFFFF1A] rounded-md flex items-center justify-center`}
      >
        {icon}
      </div>
      <div className="w-[calc(100%-32px)] space-y-1 text-white group-[.sidebar--collapsed]:pl-0 group-[.sidebar--collapsed]:!w-0 pl-4 transition-all duration-300 active-hidden delay-300">
        <p className="text-white text-opacity-40 text-ellipsis overflow-hidden text-[11px] w-full">
          {title}
        </p>
      </div>
    </div>
  )
}

const a11yProps = (index: number, is_active: boolean) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `flex-1 !min-h-[36px] !h-[36px] !text-[#DDDDDD] !font-medium !shadow-[0px_1px_2px_0px_#1018280f] ${
      is_active ? '!bg-[#1E1E1E] !rounded-md' : ''
    }`
  }
}

interface TabPanelProps {
  children?: any
  index: number
  value: number
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div id={`simple-tabpanel-${index}`} {...other}>
      {index === value && children}
    </div>
  )
}
