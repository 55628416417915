import { Drawer, Skeleton } from '@mui/material'
import useThemeImages from 'assets/images'
import DummySnapshotImage from 'assets/images/dummy-snapshot-details-image.png'
import DummyUser from 'assets/images/user-image.png'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useAppDispatch } from 'shared/hooks/useRedux'
import {
  selectOverlayTab,
  setSelectedFile
} from 'store/slices/dashboard/dashboardSlice'

import ProgressiveImage from '../ProgressiveImage/ProgressiveImage'
import TextIconButton from '../TextIconButton'
import ActionButton from './partials/ActionButton'
import FileDetailsFooter from './partials/FileDetailsFooter'
import SecondaryActionsMenu from './partials/SecondaryActionsMenu'
import useFileDetailsOverlay from './useFileDetailsOverlay'

const FileDetailsOverlay = () => {
  const {
    selectedFile,
    onDownloadClickHandler,
    onDeleteClickHandler,
    onSnapshotInViewerClickHandler
  } = useFileDetailsOverlay()

  const {
    ArrowUpRight,
    DownloadIcon,
    DeleteIcon,
    ContextMenuHorizontalGrayIcon
  } = useThemeImages()

  const dispatch = useAppDispatch()

  return (
    <Drawer
      open={Boolean(selectedFile)}
      anchor="bottom"
      onClose={() => {
        dispatch(setSelectedFile(null))
      }}
      classes={{
        paper:
          '!h-[calc(100vh-60px)] !top-[60px] rounded-t-3xl !duration-300 !bg-[#1E1E1E]'
      }}
    >
      <div className="max-w-[832px] h-[calc(100%-16px)] w-full mx-auto">
        <PerfectScrollbar
          options={{
            suppressScrollX: true
          }}
          className="!h-[calc(100%-70px)]"
        >
          {selectedFile == null ? (
            <></>
          ) : (
            <>
              <div className="w-full mt-6">
                <div className="flex items-center space-x-3">
                  <ProgressiveImage
                    src={selectedFile?.user?.profile_image?.url ?? DummyUser}
                    className="w-12 h-12 rounded-full object-cover drop-shadow-[0px_0px_1px_rgba(0,0,0,0.2)]"
                    Loader={
                      <Skeleton width={48} height={48} variant="circular" />
                    }
                  />

                  <div className="flex-1 w-[calc(100%-60px)]">
                    <div className="flex flex-col w-full justify-around h-12">
                      <h4 className="text-white text-lg font-medium leading-4 flex items-center">
                        <div className="overflow-hidden whitespace-nowrap text-ellipsis">
                          {selectedFile?.user?.name}
                        </div>
                      </h4>
                      {/* <div className="w-full flex items-center gap-x-1 whitespace-nowrap">
                        <span className="text-[#C4C4C4] text-md font-normal text-ellipsis overflow-hidden whitespace-nowrap">
                          {selectedFile?.profile_id.profile_type}
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <h4 className="mt-6 text-[20px] font-semibold leading-6 text-white">
                {selectedFile.name}
              </h4>
            </>
          )}

          <div className="mt-4 w-full aspect-[1.72] relative">
            <img
              src={selectedFile?.snapshot?.url ?? DummySnapshotImage}
              className="w-full h-full rounded-lg object-cover"
            />
            <div
              className={`absolute top-0 left-0 w-full h-full z-10 rounded-lg`}
              style={{
                background:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0) 50.45%, rgba(0, 0, 0, 0.64) 100%)'
              }}
            />
          </div>
        </PerfectScrollbar>
        <FileDetailsFooter />
      </div>
      <div className="absolute top-6 right-6">
        <TextIconButton
          text={'Snapshot in Viewer'}
          tooltipTitle={'Snapshot in Viewer'}
          className="snapshot_in_viewer_button !bg-[#7680FF] !bg-opacity-25"
          icon={<ArrowUpRight width={10} height={10} />}
          onClick={() => {
            dispatch(setSelectedFile(null))
            dispatch(selectOverlayTab('free_roam_tab'))
            setTimeout(() => {
              onSnapshotInViewerClickHandler()
            }, 0)
          }}
        />
        <div className="w-fit mt-6 flex flex-col gap-y-6 items-center ml-auto mr-0">
          <ActionButton
            Icon={DownloadIcon}
            iconProps={{
              color: '#AEAEAE',
              height: 18,
              width: 18
            }}
            onClick={onDownloadClickHandler}
            text="Download"
            isErrorButton={false}
            isAlt={true}
            textColor="text-[#AEAEAE]"
          />
          <ActionButton
            Icon={DeleteIcon}
            iconProps={{
              color: '#AEAEAE',
              height: 18,
              width: 18
            }}
            onClick={onDeleteClickHandler}
            text="Delete"
            isErrorButton={true}
            isAlt={true}
            textColor="text-[#EB617A]"
          />

          <SecondaryActionsMenu
            Icon={<ContextMenuHorizontalGrayIcon width={24} height={24} />}
            options={[
              // {
              //   icon: null,
              //   label: 'Option',
              //   onClick: () => {},
              // },
            ].filter(Boolean)}
          />
        </div>
      </div>
    </Drawer>
  )
}

export default FileDetailsOverlay
