import { type Store } from '@reduxjs/toolkit'
import { ReactComponent as LogoutIcon } from 'assets/images/icons/Logout-Icon.svg'
import { ReactComponent as WarningLayerIcon } from 'assets/images/icons/Warning-Layer-Icon.svg'
import AwaitLock from 'await-lock'
import { pack, unpack } from 'msgpackr'
import {
  createContext,
  type Dispatch,
  type MutableRefObject,
  type ReactNode,
  type RefObject,
  useEffect,
  useRef
} from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { IsStagingServer } from 'shared/constants/const'
import type SceneNode from 'shared/scene/SceneNode'
import { expandSidebar } from 'store/slices/app/appSlice'
import { resetThreeJs, setData } from 'store/slices/scan/threeJSReducer'
import {
  setCloseCommonModal,
  setCommonModal
} from 'store/slices/utils/commonModalSlice'
import { MathUtils } from 'three'

import { type Message } from './WebSocketMessages'

interface WebSocketContextFunctionProps {
  sceneTree: SceneNode
  onSuccess?: () => void
  children: ReactNode
}

const WebSocketContext = createContext<RefObject<WebSocket> | null>(null)

export { WebSocketContext }

/** Send message over websocket. */
export function sendWebsocketMessage(
  websocketRef: MutableRefObject<WebSocket | null>,
  message: Message
) {
  if (websocketRef.current === null) return
  websocketRef.current.send(pack(message))
}

/** Returns a function for sending messages, with automatic throttling. */
export function makeThrottledMessageSender(
  websocketRef: MutableRefObject<WebSocket | null>,
  throttleMilliseconds: number
) {
  let readyToSend = true
  let stale = false
  let latestMessage: Message | null = null

  function send(message: Message) {
    if (websocketRef.current == null) return
    latestMessage = message
    if (readyToSend) {
      websocketRef.current.send(pack(message))
      stale = false
      readyToSend = false

      setTimeout(() => {
        readyToSend = true
        if (!stale) return

        if (latestMessage != null) send(latestMessage)
      }, throttleMilliseconds)
    } else {
      stale = true
    }
  }
  return send
}

function handleMessage(
  message: Message,
  dispatch: Dispatch<any>,
  store: Store,
  sceneTree: SceneNode,
  socket: MutableRefObject<WebSocket | null>,
  loggedInUserId: string
) {
  switch (message.type) {
    case 'ConnectionIdMessage': {
      dispatch(
        setData({
          path: 'websocketState/client_id',
          data: message.client_id
        })
      )
      dispatch(
        setData({
          path: 'websocketState/spectating_client_id',
          data: message.client_id
        })
      )
      break
    }
    case 'CurrentViewersMessage': {
      const viewers: Array<{
        client_id: number
        id: string
        name: string
        img: string
      }> = JSON.parse(message.viewers)

      console.log(viewers)

      viewers.sort((a, b) => b.client_id - a.client_id)

      dispatch(
        setData({
          path: 'websocketState/viewers',
          data: viewers
        })
      )
      break
    }
    case 'SpectatedByMessage': {
      const spectators = JSON.parse(message.spectators)
      dispatch(
        setData({
          path: 'websocketState/spectators',
          data: spectators
        })
      )
      break
    }
    case 'SpectateNotAvailableMessage': {
      const currentViewerId = message.client_id
      dispatch(
        setCommonModal({
          icon: <LogoutIcon width={48} height={48} />,
          title: 'Spectator Mode Ended',
          content: `The user has left the viewer. Your spectator mode with your team member will also ended.`,
          showActionButtons: false,
          dialogPaperProps: {
            className: '!max-w-[360px]'
          }
        })
      )
      setTimeout(() => {
        dispatch(setCloseCommonModal())
        sendWebsocketMessage(socket, {
          type: 'SpectateMessage',
          client_id: currentViewerId
        })
        dispatch(
          setData({
            path: 'websocketState/spectating_client_id',
            data: currentViewerId
          })
        )
        dispatch(expandSidebar())
      }, 3000)
      break
    }
    // Add a background image.
    case 'BackgroundImageMessage': {
      // if (
      //   IsStagingServer &&
      //   message.user_id != '' &&
      //   loggedInUserId != '' &&
      //   message.user_id != loggedInUserId
      // ) {
      //   dispatch(
      //     setCommonModal({
      //       icon: <WarningLayerIcon width={48} height={48} />,
      //       title: 'Some Error Occurred!',
      //       content: 'Please reload the viewer to continue.',
      //       dialogueActionClassName: 'flex-row',
      //       hasNegativeButton: false,
      //       dialogPaperProps: {
      //         className: '!max-w-[400px]',
      //       },
      //       buttons: [
      //         {
      //           children: 'Reload Viewer',
      //           color: 'error',
      //           onClick: () => {
      //             window.location.reload();
      //           },
      //         },
      //       ],
      //     }),
      //   );
      // } else {
      document
        .getElementById('background-image')!
        .setAttribute('src', `data:${message.media_type};base64,${message.base64_data}`);
      // }

      break
    }
    // Add a GUI input.
    case 'GuiAddMessage': {
      const curGuiNames = store.getState().threeJs.custom_gui.guiNames
      const curGuiConfigFromName =
        store.getState().threeJs.custom_gui.guiConfigFromName
      dispatch(
        setData({
          path: 'custom_gui/guiNames',
          data: [...curGuiNames, message.name]
        })
      )
      dispatch(
        setData({
          path: 'custom_gui/guiConfigFromName',
          data: {
            ...curGuiConfigFromName,
            [message.name]: {
              folderLabels: message.folder_labels,
              levaConf: message.leva_conf,
              hidden: false
            }
          }
        })
      )
      break
    }
    // Set the hidden state of a GUI input.
    case 'GuiSetHiddenMessage': {
      const curGuiConfigFromName =
        store.getState().threeJs.custom_gui.guiConfigFromName
      const currentConf = curGuiConfigFromName[message.name]
      if (currentConf !== undefined) {
        dispatch(
          setData({
            path: 'custom_gui/guiConfigFromName',
            data: {
              ...curGuiConfigFromName,
              [message.name]: {
                ...currentConf,
                hidden: message.hidden
              }
            }
          })
        )
      }
      break
    }
    // Set the value of a GUI input.
    case 'GuiSetValueMessage': {
      const curGuiConfigFromName =
        store.getState().threeJs.custom_gui.guiConfigFromName
      const currentConf = curGuiConfigFromName[message.name]
      if (currentConf !== undefined) {
        dispatch(
          setData({
            path: 'custom_gui/guiConfigFromName',
            data: {
              ...curGuiConfigFromName,
              [message.name]: {
                ...currentConf,
                value: message.value
              }
            }
          })
        )
        //  To propagate change to the leva element, need to add to the guiSetQueue
        const curSetQueue = store.getState().threeJs.custom_gui.guiSetQueue
        dispatch(
          setData({
            path: 'custom_gui/guiSetQueue',
            data: {
              ...curSetQueue,
              [message.name]: message.value
            }
          })
        )
      }
      break
    }
    // Set leva conf of element.
    case 'GuiSetLevaConfMessage': {
      const curGuiConfigFromName =
        store.getState().threeJs.custom_gui.guiConfigFromName
      const currentConf = curGuiConfigFromName[message.name]
      if (currentConf !== undefined) {
        dispatch(
          setData({
            path: 'custom_gui/guiConfigFromName',
            data: {
              ...curGuiConfigFromName,
              [message.name]: {
                ...currentConf,
                levaConf: message.leva_conf
              }
            }
          })
        )
      }
      break
    }
    // Set camera position
    case 'SetCameraMessage': {
      if (message.fov !== null) {
        const cam = (sceneTree.metadata as any).camera
        const vExtentSlope = Math.tan(MathUtils.DEG2RAD * 0.5 * message.fov)

        const newFocalLength = (0.5 * cam.getFilmHeight()) / vExtentSlope
        cam.setFocalLength(newFocalLength)
      }
      if (message.look_at !== null && message.position !== null) {
        ;(sceneTree.metadata as any).camera_controls.setLookAt(
          message.position[0],
          message.position[1],
          message.position[2],
          message.look_at[0],
          message.look_at[1],
          message.look_at[2],
          !message.instant
        )
      } else {
        if (message.look_at !== null) {
          const p = message.look_at
          ;(sceneTree.metadata as any).camera_controls.setTarget(
            p[0],
            p[1],
            p[2],
            !message.instant
          )
        }
        if (message.position !== null) {
          const p = message.position
          ;(sceneTree.metadata as any).camera_controls.setPosition(
            p[0],
            p[1],
            p[2],
            !message.instant
          )
        }
      }
      break
    }
    // Remove a GUI input.
    case 'GuiRemoveMessage': {
      // TODO: not implemented.
      break
    }
    // Update scene box.
    case 'SceneBoxMessage': {
      dispatch(
        setData({
          path: 'sceneState/sceneBox',
          data: message
        })
      )
      break
    }
    // Add dataset image.
    case 'DatasetImageMessage': {
      const datasetPath = `sceneState/cameras/${message.idx}`
      dispatch(
        setData({
          path: datasetPath,
          data: message.json
        })
      )
      break
    }
    // Set training value.
    case 'TrainingStateMessage': {
      dispatch(
        setData({
          path: 'renderingState/training_state',
          data: message.training_state
        })
      )
      break
    }
    // Populate camera paths.
    case 'CameraPathsMessage': {
      dispatch(
        setData({
          path: 'all_camera_paths',
          data: message.payload
        })
      )
      break
    }
    // Set file path info.
    case 'FilePathInfoMessage': {
      dispatch(
        setData({
          path: 'file_path_info/config_base_dir',
          data: message.config_base_dir
        })
      )
      dispatch(
        setData({
          path: 'file_path_info/data_base_dir',
          data: message.data_base_dir
        })
      )
      dispatch(
        setData({
          path: 'file_path_info/export_path_name',
          data: message.export_path_name
        })
      )
      break
    }
    // Set crop parameters
    case 'CropParamsMessage': {
      dispatch(
        setData({
          path: 'renderingState/crop_enabled',
          data: message.crop_enabled
        })
      )
      dispatch(
        setData({
          path: 'renderingState/crop_bg_color',
          data: message.crop_bg_color
        })
      )
      dispatch(
        setData({
          path: 'renderingState/crop_scale',
          data: message.crop_scale
        })
      )
      dispatch(
        setData({
          path: 'renderingState/crop_center',
          data: message.crop_center
        })
      )
      break
    }
    // Handle status messages.
    case 'StatusMessage': {
      // if (
      //   IsStagingServer &&
      //   message.user_id != '' &&
      //   loggedInUserId != '' &&
      //   message.user_id != loggedInUserId
      // ) {
      //   dispatch(
      //     setCommonModal({
      //       icon: <WarningLayerIcon width={48} height={48} />,
      //       title: 'Some Error Occurred!',
      //       content: 'Please reload the viewer to continue.',
      //       dialogueActionClassName: 'flex-row',
      //       hasNegativeButton: false,
      //       dialogPaperProps: {
      //         className: '!max-w-[400px]',
      //       },
      //       buttons: [
      //         {
      //           children: 'Reload Viewer',
      //           color: 'error',
      //           onClick: () => {
      //             window.location.reload();
      //           },
      //         },
      //       ],
      //     }),
      //   );
      // } else {
      dispatch(
        setData({
          path: 'renderingState/eval_res',
          data: message.eval_res,
        }),
      );
      dispatch(
        setData({
          path: 'renderingState/step',
          data: message.step,
        }),
      );
      // }
      break;
    }
    // Handle time conditioning messages.
    case 'UseTimeConditioningMessage': {
      dispatch(
        setData({
          path: 'renderingState/use_time_conditioning',
          data: true
        })
      )
      break
    }
    case 'TimeConditionMessage': {
      dispatch(
        setData({
          path: 'renderingState/time_condition',
          data: message.time
        })
      )
      break
    }
    case 'OutputOptionsMessage': {
      dispatch(
        setData({
          path: 'renderingState/output_options',
          data: message.options
        })
      )
      break
    }
    default: {
      console.log('Received message did not match any known types:', message)
      break
    }
  }
}

function WebSocketContextFunction({
  sceneTree,
  onSuccess = () => {
    console.log('Websocket connected')
  },
  children
}: WebSocketContextFunctionProps) {
  const dispatch = useDispatch()
  const store = useStore()
  const socket = useRef<WebSocket | null>(null)

  // this code will rerender anytime the websocket url changes
  const websocketUrl = useSelector(
    (state: any) => state.threeJs.websocketState.websocket_url
  )
  const isConnected = useSelector(
    (state: any) => state.threeJs.websocketState.isConnected
  )
  const isInitiateClose = useSelector(
    (state: any) => state.threeJs.websocketState.isInitiateClose
  )

  const loggedInUserId = useSelector(
    (state: any) => state.auth?.user?.user_id ?? null
  )

  useEffect(() => {
    // Lock for making sure messages are handled in order.
    const orderLock = new AwaitLock()

    let done = false

    function tryConnect(): void {
      if (done) return

      socket.current = new WebSocket(websocketUrl)

      const connectingTimeout = setTimeout(() => {
        if (
          socket.current != null &&
          socket.current.readyState === WebSocket.CONNECTING
        ) {
          socket.current.close()
          console.log('WebSocket connection timed out')
        }
      }, 5000) // timeout after 5 seconds

      socket.current.addEventListener('open', () => {
        clearTimeout(connectingTimeout)
        dispatch(
          setData({
            path: 'websocketState/isConnected',
            data: true
          })
        )
        onSuccess()
      })

      socket.current.addEventListener('close', () => {
        if (isConnected != null) {
          dispatch(
            setData({
              path: 'websocketState/isConnected',
              data: false
            })
          )

          // Try to reconnect.
          // eslint-disable-next-line no-use-before-define
          // timeout = setTimeout(tryConnect, 5000);
          // dispatch(
          //   setCommonModal({
          //     icon: <WarningLayerIcon width={48} height={48} />,
          //     title: 'Some Error Occurred!',
          //     content: 'Please reload the viewer to continue.',
          //     dialogueActionClassName: 'flex-row',
          //     hasNegativeButton: false,
          //     dialogPaperProps: {
          //       className: '!max-w-[400px]',
          //     },
          //     buttons: [
          //       {
          //         children: 'Reload Viewer',
          //         color: 'error',
          //         onClick: () => {
          //           window.location.reload();
          //         },
          //       },
          //     ],
          //   }),
          // );
        }
      })

      // eslint-disable-next-line unicorn/prefer-add-event-listener
      socket.current.onmessage = async event => {
        // Reduce websocket backpressure.
        try {
          const message = (await unpack(
            new Uint8Array(await event.data.arrayBuffer())
          )) as Message
          await orderLock.acquireAsync({ timeout: 1000 })
          handleMessage(
            message,
            dispatch,
            store,
            sceneTree,
            socket,
            loggedInUserId ?? ''
          )
        } catch (error) {
          console.error(`Error handling message: ${error as string}`)
        } finally {
          if (orderLock.acquired) {
            orderLock.release()
          }
        }
      }

      // eslint-disable-next-line unicorn/prefer-add-event-listener
      socket.current.onerror = err => {
        console.log('Websocket error:', err)

        timeout = setTimeout(tryConnect, 5000)
      }
    }

    let timeout = setTimeout(tryConnect, 500)
    return () => {
      clearTimeout(timeout)
      if (socket.current != null) {
        done = true
        socket.current.close()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocketUrl, loggedInUserId, isConnected])

  useEffect(() => {
    if (isInitiateClose != null && socket !== null) {
      socket.current?.close()

      dispatch(resetThreeJs())
    }
  }, [isInitiateClose])

  // connect();

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  )
}

export default WebSocketContextFunction
