import { createSlice } from '@reduxjs/toolkit'

interface AppState {
  is_embed: boolean
  sidebar: {
    is_open: boolean
    is_visible: boolean
  }
  banner: {
    is_visible: boolean
  }
  is_login_dialog_open: boolean
  loader_branding: {
    brand_color?: string
    team_logo?: {
      url: string
    }
    team_name?: string
    team_bio?: string
  }
}

const IsMobile = window.innerWidth < 768

const initialState: AppState = {
  is_embed: false,
  sidebar: {
    is_open: !IsMobile,
    is_visible: true
  },
  banner: {
    is_visible: true
  },
  is_login_dialog_open: false,
  loader_branding: {}
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsEmbed(state) {
      state.is_embed = true
    },
    toggleSidebar(state) {
      state.sidebar.is_open = !state.sidebar.is_open
    },
    compressSidebar(state) {
      state.sidebar.is_open = false
    },
    expandSidebar(state) {
      state.sidebar.is_open = true
    },
    hideSidebar(state) {
      state.sidebar.is_visible = false
    },
    showBanner(state) {
      state.banner.is_visible = true
    },
    hideBanner(state) {
      state.banner.is_visible = false
    },
    openLogInDialog(state) {
      state.is_login_dialog_open = true
    },
    closeLogInDialog(state) {
      state.is_login_dialog_open = false
    },
    setLoaderBranding(state, action) {
      state.loader_branding = action.payload
    }
  }
})

export const {
  setIsEmbed,
  toggleSidebar,
  compressSidebar,
  expandSidebar,
  hideSidebar,
  showBanner,
  hideBanner,
  openLogInDialog,
  closeLogInDialog,
  setLoaderBranding
} = appSlice.actions

export default appSlice.reducer
