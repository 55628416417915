import { CircularProgress } from '@mui/material'
import DummyScan from 'assets/images/dummy-scan.png'

import useFilesTab from './useFilesTab'

const FilesTab = () => {
  const { scan_id, scans, isScansLoading, onClickScan } = useFilesTab()

  return (
    <div className="pt-1">
      <div className="pb-[10px] border-b border-[#353645]">
        <div className="text-[14px] text-[#FFFFFF]">Scans</div>
        <div className="mt-[14px] flex flex-col gap-y-5 px-1">
          {isScansLoading ? (
            <span className="text-white mx-auto">
              <CircularProgress color="inherit" size="25px" />
            </span>
          ) : scans.length === 0 ? (
            <div className="text-sm text-white mx-auto text-center">
              No Scans Found...
            </div>
          ) : (
            scans.map((scan: any, index) => (
              <div
                key={`scan_${index}`}
                className={`flex items-center gap-x-[10px] ${
                  scan_id !== scan.scan_id && 'hover:cursor-pointer'
                }`}
                onClick={() => {
                  if (scan_id !== scan.scan_id) {
                    onClickScan(scan)
                  }
                }}
              >
                <img
                  src={scan?.input_file?.thumbnail ?? DummyScan}
                  className="w-8 h-8 rounded-[3px] object-cover"
                />
                <div
                  className={`text-lg font-medium text-[#FFFFFF66] overflow-hidden text-ellipsis ${
                    scan_id === scan.scan_id && 'text-white'
                  }`}
                >
                  {scan.scan_name}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default FilesTab
