import useThemeImages from 'assets/images'
import DummySnapshot from 'assets/images/dummy-snapshot-image.png'
import useFileDetailsOverlay from 'shared/components/FileDetailsOverlay/useFileDetailsOverlay'
import { useAppDispatch } from 'shared/hooks/useRedux'
import { type ISnapshot } from 'shared/types/snapshot/snapshot.type'
import { setSelectedFile } from 'store/slices/dashboard/dashboardSlice'

interface ISnapshotCardProps {
  snapshot: ISnapshot
}

const SnapshotCard = (props: ISnapshotCardProps) => {
  const { snapshot } = props
  const { DownloadWhiteIcon, DeleteWhiteIcon } = useThemeImages()

  const dispatch = useAppDispatch()

  const { onDownloadClickHandler, onDeleteClickHandler } =
    useFileDetailsOverlay({
      fileByProp: snapshot
    })

  return (
    <div
      className="h-fit w-full cursor-pointer group"
      onClick={() => dispatch(setSelectedFile(snapshot))}
    >
      <div className="h-[216px] w-full rounded-xl relative mb-2">
        <img
          src={snapshot?.snapshot?.url ?? DummySnapshot}
          className="h-full w-full object-cover rounded-xl"
        />
        <div className="absolute top-0 left-0 w-full h-full z-10 rounded-lg flex items-end">
          <div
            className={`absolute top-0 left-0 w-full h-full z-[1] rounded-lg opacity-0 group-hover:opacity-100 transition-300`}
            style={{
              background:
                'linear-gradient(180deg, rgba(0, 0, 0, 0) 50.45%, rgba(0, 0, 0, 0.64) 100%)'
            }}
          />
          <div className="w-full mb-[10px] px-[10px] relative z-[1] hidden group-hover:flex flex-row items-center gap-x-[6px] overflow-hidden">
            <img
              src={snapshot.user.profile_image?.url}
              className="w-6 h-6 rounded-full object-cover"
            />
            <div className="flex-1 text-md text-white leading-5 text-ellipsis whitespace-nowrap">
              {snapshot.user.name ?? 'N/A'}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-x-4">
        <div className="text-sm font-medium text-white">{snapshot.name}</div>
        <div className="flex items-center gap-x-4">
          <DownloadWhiteIcon
            width="16px"
            height="16px"
            className="hover:cursor-pointer"
            onClick={e => {
              e.stopPropagation()
              onDownloadClickHandler()
            }}
          />
          <DeleteWhiteIcon
            width="16px"
            height="16px"
            className="hover:cursor-pointer"
            onClick={e => {
              e.stopPropagation()
              onDeleteClickHandler()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SnapshotCard
