import { Button } from '@mui/material'
import usePagination from '@mui/material/usePagination/usePagination'
import useThemeImages from 'assets/images'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { setSelectedFile } from 'store/slices/dashboard/dashboardSlice'
import { twMerge } from 'tailwind-merge'

const FileDetailsFooter = () => {
  const { ArrowRightNav, ArrowLeftNav } = useThemeImages()

  const dispatch = useAppDispatch()

  const { files } = useAppSelector(state => state.dashboard)

  const onChange = (e: any, page: any) => {
    console.log(files, files[page - 1])
    dispatch(setSelectedFile(files[page - 1]))
  }

  const { items } = usePagination({
    count: files.length,
    boundaryCount: 1,
    onChange
  })

  const nextButton = items.at(-1)
  const prevButton = items[0]

  const pages = items.slice(1, -1)

  return (
    <div className="w-full min-h-[52px] flex items-center justify-between px-6 mt-5 pb-7 pt-3 rounded-b-lg border-t border-[#9199FF33]">
      <Button
        onClick={prevButton.onClick}
        disabled={prevButton.disabled}
        variant="outlined"
        fullWidth
        className={twMerge(
          '!text-md !font-medium !h-9 !w-fit !text-[#7680FF] !bg-[#7680FF33] !border-none',
          prevButton.disabled
            ? '!cursor-not-allowed !text-opacity-50 !border-opacity-50'
            : '!hover:!bg-[#F7F8FA]'
        )}
        color="inherit"
        startIcon={
          <ArrowLeftNav
            width={16}
            height={16}
            opacity={prevButton.disabled ? 0.5 : 1}
          />
        }
      >
        Previous
      </Button>
      <div>
        {pages.map(({ onClick, page, type, selected }, index) => {
          let children = null

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…'
          } else if (type === 'page') {
            children = page
          } else {
            children = type
          }

          return (
            <Button
              key={index}
              onClick={onClick}
              variant="text"
              color={selected ? 'primary' : 'inherit'}
              className={twMerge(
                '!py-[10px] !px-4 !border-0 !min-w-0 !h-10 !w-10 !text-[#DFDFDF]',
                selected ? '!bg-[#7680FF33] !text-[#7680FF]' : ''
              )}
            >
              {children}
            </Button>
          )
        })}
      </div>
      <Button
        onClick={nextButton?.onClick}
        disabled={nextButton?.disabled}
        variant="outlined"
        fullWidth
        className={twMerge(
          '!text-md !font-medium !h-9 !w-fit !text-[#7680FF] !bg-[#7680FF33] !border-none',
          nextButton?.disabled == null
            ? '!cursor-pointer'
            : '!cursor-not-allowed !text-opacity-50 !border-opacity-50'
        )}
        color="inherit"
        endIcon={
          <ArrowRightNav
            width={16}
            height={16}
            opacity={nextButton?.disabled == null ? 1 : 0.5}
          />
        }
      >
        Next
      </Button>
    </div>
  )
}

export default FileDetailsFooter
