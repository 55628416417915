import IconButton from '@mui/material/IconButton'
import Menu, { type MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { alpha, styled } from '@mui/material/styles'
import useThemeImages from 'assets/images'
import * as React from 'react'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}))

interface ISecondaryActionsMenuProps {
  Icon?: any
  options: Array<
    | {
        icon: any
        label: string
        onClick: any
      }
    | undefined
  >
}

const SecondaryActionsMenu = ({
  Icon,
  options
}: ISecondaryActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (e: any) => {
    if (e != null) {
      e.stopPropagation()
    }
    setAnchorEl(null)
  }

  const { ContextMenuHorizontalGrayIcon } = useThemeImages()

  return (
    <div>
      <IconButton
        id="secondary-menu-button"
        aria-controls={open ? 'secondary-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={e => {
          e.stopPropagation()
          handleClick(e)
        }}
        sx={{
          padding: 0
        }}
      >
        {Icon ?? <ContextMenuHorizontalGrayIcon width={24} height={24} />}
      </IconButton>
      <StyledMenu
        id="secondary-menu"
        MenuListProps={{
          'aria-labelledby': 'secondary-menu-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={`menu_option_${index}`}
            onClick={e => {
              e.stopPropagation()
              option?.onClick()
              handleClose(null)
            }}
            disableRipple
          >
            {option?.icon}
            <span className="ml-3">{option?.label}</span>
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}

export default SecondaryActionsMenu
