import { useMemo } from 'react'
import { toggleSidebar } from 'store/slices/app/appSlice'

import { useAppDispatch, useAppSelector } from './useRedux'
import useScreen from './useScreen'

const useThemeSidebar = () => {
  const open = useAppSelector(state => (state as any).app.sidebar.is_open)
  const dispatch = useAppDispatch()

  const { isMobile } = useScreen()

  const offsetPadding = useMemo(() => {
    return isMobile ? (open == null ? 'lg:pl-[92px]' : 'lg:pl-[240px]') : ''
  }, [open, isMobile])

  const toggleSidebarVisibility = () => {
    dispatch(toggleSidebar())
  }

  return { open, offsetPadding, toggleSidebarVisibility }
}

export default useThemeSidebar
