import {
  type ButtonProps,
  type DialogProps,
  type PaperProps
} from '@mui/material'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ReactNode } from 'react'
import { type ILoaderButtonProps } from 'shared/components/LoaderButton/LoaderButton'

export interface CommonModalState extends DialogProps {
  topElement?: any
  topElementProps?: any | null
  icon?: ReactNode | null
  title?: string
  content?: string
  contentComponent?: ReactNode
  dialogPaperProps?: PaperProps
  dialogueContentClassName?: string
  dialogueActionClassName?: string
  dialogueActionButtonsFontSize?: string
  buttons?: CommonModalButtons[]
  showActionButtons?: boolean
  actionButtonsDir?: 'row' | 'column'
  hasNegativeButton?: boolean
  cancelBtnProps?: ButtonProps
  negativeButtonLabel?: string
  negativeButtonAction?: () => void
  loadingBtn?: string
  hasBackdrop?: boolean
}

export interface CommonModalButtons extends ILoaderButtonProps {
  isCloseBtn?: boolean
}

const initialState: CommonModalState = {
  children: null as any,
  topElement: null,
  topElementProps: {},
  icon: null,
  open: false,
  title: '',
  content: '',
  dialogPaperProps: {},
  dialogueContentClassName: '',
  dialogueActionClassName: '',
  buttons: [],
  showActionButtons: true,
  hasNegativeButton: true,
  actionButtonsDir: 'column',
  maxWidth: 'xs',
  loadingBtn: '',
  hasBackdrop: true
}

const commonModalSlice = createSlice({
  name: 'commonModal',
  initialState,
  reducers: {
    setCloseCommonModal(state) {
      state.open = false
    },
    resetCommonModal() {
      return { ...initialState }
    },
    setCommonModal: (
      state,
      { payload }: PayloadAction<Omit<CommonModalState, 'open'>>
    ) => {
      return { ...state, ...payload, open: true } as any
    },
    setCommonModalLoadingBtn(state, { payload }: PayloadAction<string>) {
      state.loadingBtn = payload
    }
  }
})

export const {
  setCloseCommonModal,
  resetCommonModal,
  setCommonModalLoadingBtn,
  setCommonModal
} = commonModalSlice.actions

export default commonModalSlice.reducer
