import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { type RootState } from 'store'

// create api slice
export const gqlApi = createApi({
  reducerPath: 'gqlApi',
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.REACT_APP_SERVER_URL ?? '',
    prepareHeaders: async (headers, { getState }) => {
      const states = getState() as RootState
      const token = states.auth.token
      if (token != null) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
    customErrors: ({ name, stack, response }) => {
      return response?.errors == null ? null : response?.errors[0]
    }
  }),
  tagTypes: ['Invitees', 'Snapshots'],
  endpoints: builder => ({})
})

export default gqlApi
