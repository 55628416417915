import { useMemo, useState } from 'react'
import { useAppSelector } from 'shared/hooks/useRedux'

import PersonItem from './PersonItem'

const PeopleList = () => {
  const [isHovered, setIsHovered] = useState(false)
  const { viewers, spectators } = useAppSelector(
    state => state.threeJs.websocketState
  )

  const spectatingPeople = useMemo(() => {
    if (spectators.length === 0) return <></>

    const selectedSpectators = viewers.filter(viewer =>
      spectators.includes(viewer.client_id)
    )

    return selectedSpectators.map((person, index) => (
      <PersonItem key={`person_${index}`} {...person} />
    ))
  }, [viewers, spectators])

  const peopleImages = useMemo(() => {
    if (viewers.length - spectators.length === 0) return <></>

    let selectedViewers = viewers.filter(
      viewer => !spectators.includes(viewer.client_id)
    )

    if (selectedViewers.length > 3 && !isHovered) {
      selectedViewers = selectedViewers.slice(0, 3)
    }

    return selectedViewers.map((person, index) => (
      <PersonItem key={`person_${index}`} {...person} />
    ))
  }, [viewers, isHovered, spectators])

  const extraPeople = useMemo(() => {
    if (viewers.length - spectators.length <= 3 || isHovered) return <></>

    let selectedViewers = viewers.filter(
      viewer => !spectators.includes(viewer.client_id)
    )

    selectedViewers = selectedViewers.slice(3)

    return (
      <div className="w-8 h-8 rounded-full bg-[#515259] border border-[#FFFFFF66] flex items-center justify-center text-lg text-[#FFFFFF66] -ml-[6px]">
        +{selectedViewers.length}
      </div>
    )
  }, [viewers, isHovered, spectators])

  return (
    <div
      className="flex"
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
    >
      <div className="ml-[6px]">{spectatingPeople}</div>
      {peopleImages}
      {extraPeople}
    </div>
  )
}

export default PeopleList
