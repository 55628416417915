import { useMemo } from 'react'
import SnapshotsScreen from 'screens/SnapshotsScreen/SnapshotsScreen'
import { useAppSelector } from 'shared/hooks/useRedux'

const DashboardOverlayTabs = () => {
  const selectedTab = useAppSelector(state => state.dashboard.selected_tab)

  const { isSideBarOpen, isSideBarVisible } = useAppSelector(state => ({
    isSideBarOpen: state.app.sidebar.is_open,
    isSideBarVisible: state.app.sidebar.is_visible
  }))

  const OverlayScreen = useMemo<React.ReactNode>(() => {
    if (selectedTab === 'snapshots_tab') {
      return <SnapshotsScreen />
    }

    return <></>
  }, [selectedTab])

  return (
    <div
      className={`${
        selectedTab === 'free_roam_tab' && 'hidden'
      } absolute top-0 left-0 h-[var(--app-height)] w-screen grid grid-cols-[auto_1fr] z-[1000] pointer-events-none`}
    >
      <div
        className={`hidden md:flex sidebar sidebar--transparent pointer-events-none opacity-0 invisible ${
          !isSideBarOpen && 'sidebar--collapsed'
        } ${!isSideBarVisible && 'md:hidden'}`}
      ></div>
      <div className="h-[var(--app-height)] w-full pointer-events-auto">
        {OverlayScreen}
      </div>
    </div>
  )
}

export default DashboardOverlayTabs
