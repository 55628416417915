import { ROOT_PAGE_URL } from 'shared/constants/navigator'
import { type APIResponse } from 'shared/types/api.type'
import { type HandlersInterface } from 'shared/types/handlers.type'

import { errorToast, successToast } from './toast'

export const apiHandler = (options: HandlersInterface) => {
  const {
    error,
    data,
    setError,
    onSuccess,
    onError,
    showToast = true,
    showErrorToast = true,
    showSuccessToast = true
  } = options

  const showToasts = (message: string, type: 'success' | 'error') => {
    if (type === 'success' && showToast && showSuccessToast) {
      successToast(message)
    } else if (type === 'error' && showToast && showErrorToast) {
      errorToast(message)
    }
  }

  if (error != null) {
    try {
      // @ts-expect-error - error is not null
      const response = JSON.parse(error.data) as APIResponse
      const { statusCode, status, error: resError } = response

      if ([500, 502].includes(statusCode)) {
        window.location.href = ROOT_PAGE_URL.SERVERS_BUSY
      } else {
        if (status == null) {
          console.log(response)
          onError != null && onError(response)
        } else {
          if (
            status === 'ValidationError' &&
            setError != null &&
            typeof resError === 'object'
          ) {
            Object.keys(resError).forEach(key => {
              const message = resError[key as keyof typeof resError]
              setError(key, {
                type: 'manual',
                message:
                  typeof message === 'string'
                    ? message
                    : message[0 as keyof typeof message]
              })
            })
          } else if (status === 'Error' && typeof resError === 'string') {
            showToasts(resError, 'error')
            onError != null && onError(response)
          } else {
            console.log(response)
            onError != null && onError(response)
          }
        }
      }
    } catch (error_) {
      console.log(error_)
    }
  }

  if (data != null && data.status === 'Success') {
    showToasts(data.message, 'success')
    onSuccess != null && onSuccess(data)
  }
}

export const rtkHandler = (response: any, options?: HandlersInterface) => {
  if (response == null) return

  const { data, error } = response
  const { showErrorToast = true } = options ?? {}

  if (error != null) {
    if (showErrorToast && error.message != null) {
      errorToast(error.message)
    }
    options?.onError != null && options.onError(error)
  } else if (data != null) {
    options?.successMessage != null && successToast(options.successMessage)
    options?.onSuccess != null && options.onSuccess(data)
  }
}
