import { Autocomplete, TextField } from '@mui/material'
import useThemeImages from 'assets/images'
import { useContext } from 'react'
import { type UseControllerReturn } from 'react-hook-form'

import FieldControllerContext from '../FieldController.context'
import { type IFieldControllerProps } from '../FieldController.type'

const FAutoComplete = () => {
  const { methods, props }: any = useContext(FieldControllerContext)
  const { SelectArrow } = useThemeImages()

  const {
    field: { value, onChange }
  }: UseControllerReturn = methods
  const {
    placeholder,
    options = [],
    autoCompleteProps,
    inputVariant
  }: IFieldControllerProps = props

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      popupIcon={<SelectArrow />}
      classes={{
        noOptions: 'text-md',
        listbox: 'text-md',
        loading: 'text-md'
      }}
      sx={{
        '.MuiSelect-select': {
          color: value == null ? '#dddddd' : 'currentColor',
          fontWeight: 'normal'
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={placeholder}
          variant={inputVariant}
        />
      )}
      onChange={(_, newValue) => {
        if (autoCompleteProps?.onChange != null) {
          // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
          onChange(autoCompleteProps?.onChange(_, newValue))
        }
        onChange(newValue)
      }}
      disableCloseOnSelect={autoCompleteProps?.multiple ?? false}
      value={value}
      {...autoCompleteProps}
    />
  )
}

export default FAutoComplete
