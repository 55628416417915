import useThemeImages from 'assets/images'

import { type IPerson } from '../ShareViewer.type'
import PersonDetailsListItem from './PersonDetailsListItem'

interface IPeopleDetailsListProps {
  people: IPerson[]
}

const PeopleDetailsList = (props: IPeopleDetailsListProps) => {
  const { people } = props
  const { UserIcon } = useThemeImages()

  return (
    <div className="flex-1 w-full overflow-auto">
      {people.length > 0 ? (
        people.map((person, index) => (
          <>
            <PersonDetailsListItem key={`person_${index}`} person={person} />
            {index < people.length - 1 && <div className="mb-4"></div>}
          </>
        ))
      ) : (
        <div className="w-full flex items-center gap-x-3">
          <div className="w-6 h-6 rounded-full bg-[#5E5D5D33] flex items-center justify-center">
            <UserIcon width={12} height={12} />
          </div>
          <div className="text-sm font-normal">No invited users found</div>
        </div>
      )}
    </div>
  )
}

export default PeopleDetailsList
