import { useMemo } from 'react'

const useScreen = () => {
  const screenSize = window.innerWidth
  const isMobile = useMemo(() => screenSize < 768, [screenSize, window])
  const isTablet = useMemo(
    () => screenSize >= 768 && screenSize < 960,
    [screenSize, window]
  )
  const isDesktop = useMemo(
    () => screenSize >= 960 && screenSize < 1200,
    [screenSize, window]
  )
  const isLargeDesktop = useMemo(() => screenSize >= 1200, [screenSize, window])

  // all required things to return
  return useMemo(() => {
    return {
      isMobile,
      isTablet,
      isDesktop,
      isLargeDesktop
    }
  }, [isMobile, isTablet, isDesktop, isLargeDesktop])
}

export default useScreen
