import UnderMaintenanceImage from 'assets/images/maintenance-placeholder-image.png'

import LOGO from '../../assets/images/logo.png'

const UnderMaintenance = () => {
  return (
    <div className="w-full h-[var(--app-height)] p-8 flex flex-col justify-between bg-[#1E1E1E]">
      <div>
        <img src={LOGO} className="w-[150px]" alt="logo" />
      </div>
      <div className="w-[90%] md:w-[390px] text-center mx-auto">
        <img src={UnderMaintenanceImage} className="w-[90%] mb-14" />
        <div className="text-[20px] text-white mb-3 font-semibold">
          This site is Under Maintenance.
        </div>
        <div className="text-md text-white">
          We are coming back soon with great updates for you. Send us an email
          at <span className="text-[#7680FF]">info@xspada.com</span> for
          immediate support.
        </div>
      </div>
      <div className="text-md text-[#667085]">© XSpada 2023</div>
    </div>
  )
}

export default UnderMaintenance
