import { useAppDispatch } from 'shared/hooks/useRedux';
import { setData } from 'store/slices/scan/scanReducer';
import { dismissTutorial } from 'store/slices/utils/tutorialSlice';

const useWalkthroughLayout = () => {
  const dispatch = useAppDispatch();

  const onTutorialClose = () => {
    // setTutorialStatus({
    //   application: 'viewer',
    //   tab: 'all_tabs',
    // });

    dispatch(dismissTutorial());
    if (!window.localStorage.getItem('controls')) {
      dispatch(
        setData({
          path: 'show_instructions',
          data: true,
        }),
      );
    }
  };

  return { onTutorialClose };
};

export default useWalkthroughLayout;
