import { ErrorMessage } from '@hookform/error-message'
import { FormHelperText } from '@mui/material'
import { useController } from 'react-hook-form'

import CheckboxWithLabel from '../CheckboxWithLabel/CheckboxWithLabel'
import FieldLabel from '../FieldLabel/FieldLabel'
import FieldControllerContext from './FieldController.context'
import { type IFieldControllerProps } from './FieldController.type'
import FAutoComplete from './partials/FAutoComplete'
import FSelect from './partials/FSelect'
import FTextField from './partials/FTextField'

const FieldController = (props: IFieldControllerProps) => {
  const {
    defaultValue = '',
    name,
    control,
    label,
    labelClassName = '',
    formGroup = true,
    type = 'text',
    inlineFlex = false,
    containerClassName = '',
    sx = {},
    helperText,
    render = null
  } = props

  const methods = useController({
    control,
    name,
    defaultValue
  })

  const {
    field: { value, onChange }
  } = methods

  return (
    <FieldControllerContext.Provider
      value={{
        methods,
        props
      }}
    >
      <div
        className={`w-full flex ${
          inlineFlex ? 'flex-col xl:flex-row space-x-4' : 'flex-col'
        } ${formGroup ? 'mb-5' : ''} ${containerClassName}`}
      >
        {label != null && !['checkbox-label'].includes(type) && (
          <FieldLabel className={labelClassName}>{label}</FieldLabel>
        )}
        <div className="w-full">
          {render == null ? (
            <>
              {['text', 'email', 'password', 'number'].includes(type) && (
                <FTextField />
              )}
              {type === 'select' && <FSelect />}
              {type === 'auto-complete' && <FAutoComplete />}
              {type === 'checkbox-label' && (
                <CheckboxWithLabel
                  name={name}
                  sxStyle={sx}
                  onChange={onChange}
                  value={value}
                  label="Remember me"
                />
              )}
            </>
          ) : (
            render(methods)
          )}
          {helperText != null && (
            <FormHelperText className="!mt-2">{helperText}</FormHelperText>
          )}
          <ErrorMessage
            errors={methods.formState.errors}
            name={name}
            render={({ message }) => (
              <p className="text-red-500 font-thin text-sm mt-1">{message}</p>
            )}
          />
        </div>
      </div>
    </FieldControllerContext.Provider>
  )
}

export default FieldController
