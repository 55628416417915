import * as THREE from 'three';

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import OpenWithIcon from '@mui/icons-material/OpenWith';
import PublicOffSharpIcon from '@mui/icons-material/PublicOffSharp';
import PublicSharpIcon from '@mui/icons-material/PublicSharp';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { IconButton } from '@mui/material';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  sendWebsocketMessage,
  makeThrottledMessageSender,
  WebSocketContext,
} from '../socket/WebSocket';
import { setCloseCommonModal, setCommonModal } from 'store/slices/utils/commonModalSlice';

import { ProfileTypes } from 'shared/types/profile/profile.type';
import axios from 'axios';
import useAuth from 'shared/hooks/useAuth';
import useThemeImages from 'assets/images';
import { changeResolution } from 'shared/utils/helper';
import { IsStagingServer } from 'shared/constants/const';
import IsStagingComponent from 'shared/components/IsStagingComponent/IsStagingComponent';

const msgpack = require('msgpack-lite');

function TransformIcons(props) {
  const sceneTree = props.sceneTree;
  const transform_controls = sceneTree.find_object(['Transform Controls']);
  // NOTE(ethan): I'm not sure why this is necessary, but it is
  // toggle back and forth between local and global transform
  const [world, setWorld] = React.useState(true);

  const toggleLocal = () => {
    transform_controls.setSpace(world ? 'local' : 'world');
    setWorld(!world);
  };

  return (
    <div>
      <div className="ViewerWindow-iconbutton">
        <IconButton
          size="large"
          onClick={() => {
            transform_controls.setMode('translate');
          }}
        >
          {/* translate */}
          <OpenWithIcon />
        </IconButton>
      </div>
      <div className="ViewerWindow-iconbutton">
        <IconButton
          size="large"
          onClick={() => {
            transform_controls.setMode('rotate');
          }}
        >
          {/* rotate */}
          <SyncOutlinedIcon />
        </IconButton>
      </div>
      <div className="ViewerWindow-iconbutton">
        <IconButton size="large" onClick={toggleLocal}>
          {world ? <PublicSharpIcon /> : <PublicOffSharpIcon />}
        </IconButton>
      </div>
    </div>
  );
}

// manages a camera
const ViewerWindow = (props) => {
  const { userProfile } = useAuth();

  const { LogoutIcon } = useThemeImages();

  const dispatch = useDispatch();
  const sceneTree = props.sceneTree;
  const scene = sceneTree.object;
  const renderer = sceneTree.metadata.renderer;

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const scanId = urlParams.get('scan_id');

  const userId = useSelector((state) => state.auth?.user?.user_id ?? null);

  const is_embed = useSelector((state) => state.app.is_embed);
  const watermark = useSelector((state) => state.threeJs.websocketState.watermark_url);

  const myRef = useRef(null);
  const websocket = useContext(WebSocketContext);
  const field_of_view = useSelector((state) => state.threeJs.renderingState.field_of_view);

  const is_sidebar_open = useSelector((state) => state.app.sidebar.is_open);

  const portId = useSelector((state) => state.threeJs.websocketState.port_id);
  const clientId = useSelector((state) => state.threeJs.websocketState.client_id);
  const spectatingClientId = useSelector(
    (state) => state.threeJs.websocketState.spectating_client_id,
  );

  const camera_choice = useSelector((state) => state.threeJs.renderingState.camera_choice);
  const camera_type = useSelector((state) => state.threeJs.renderingState.camera_type);
  const cameraDefaults = useSelector((state) => state.scan.camera);

  const isNewPacket = useRef(null);

  // listen to the viewport width
  const size = new THREE.Vector2();
  renderer.getSize(size);
  const [viewport_size, setDimensions] = React.useState({
    height: size.x,
    width: size.y,
  });
  const viewport_width = viewport_size.width;
  const viewport_height = viewport_size.height;

  // on change, update the camera and controls
  sceneTree.metadata.camera = sceneTree.find_object(['Cameras', camera_choice]);

  const get_window_width = () => {
    const width = myRef.current?.clientWidth ?? 0;
    return width - (width % 2);
  };

  const get_window_height = () => {
    return myRef.current?.clientHeight ?? 0;
  };

  const handleResize = () => {
    const viewportWidth = get_window_width();
    const viewportHeight = get_window_height();
    sceneTree.metadata.camera.aspect = viewportWidth / viewportHeight;
    sceneTree.metadata.camera.updateProjectionMatrix();
    renderer.setSize(viewportWidth, viewportHeight);
  };

  const clock = new THREE.Clock();

  const render = () => {
    const delta = clock.getDelta();
    handleResize();
    sceneTree.metadata.camera.updateProjectionMatrix();
    sceneTree.metadata.moveCamera();
    sceneTree.metadata.camera_controls.update(delta);
    requestAnimationFrame(render);
    renderer.render(scene, sceneTree.metadata.camera);
  };

  useEffect(() => {
    const handleNewDimensions = () => {
      setDimensions({
        height: get_window_height(),
        width: get_window_width(),
      });
    };

    setDimensions({
      height: get_window_height(),
      width: get_window_width(),
    });
    render();

    window.addEventListener('resize', handleNewDimensions);
    return () => {
      window.removeEventListener('resize', handleNewDimensions);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // start the three.js rendering loop
  // when the DOM is ready
  useEffect(() => {
    document.getElementById('background-image').onload = function () {
      if (scene) {
        const oldBackground = scene.background;

        const texture = new THREE.Texture(
          clientId != spectatingClientId
            ? changeResolution(this, {
                width: get_window_width(), // document.getElementById('spectate-canvas').clientWidth, // get_window_height(),
                height: get_window_height(), // document.getElementById('spectate-canvas').clientHeight, //get_window_width(),
              })
            : this,
        );
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.needsUpdate = true;
        scene.background = texture;
        if (oldBackground) {
          oldBackground.dispose();
        }
      }
    };
    myRef.current.append(renderer.domElement);
    render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, spectatingClientId]);

  const render_height = useSelector((state) => state.threeJs.renderingState.render_height);
  const render_width = useSelector((state) => state.threeJs.renderingState.render_width);

  let crop_w;
  let crop_h;
  const render_aspect = render_width / render_height;
  const viewport_aspect = viewport_width / viewport_height;
  let render_viewport_apsect_ratio = null;
  if (render_aspect > viewport_aspect) {
    // render width is the limiting factor
    crop_w = viewport_width;
    crop_h = viewport_width / render_aspect;
    render_viewport_apsect_ratio = viewport_aspect / render_aspect;
  } else {
    // render height is the limiting factor
    crop_w = viewport_height * render_aspect;
    crop_h = viewport_height;
    render_viewport_apsect_ratio = 1.0;
  }

  let display = null;
  if (camera_choice === 'Main Camera') {
    display = 'none';
  } else {
    display = 'flex';
  }

  const crop_style = {
    display,
    width: crop_w,
    height: crop_h,
  };

  // set the threejs field of view
  // such that the rendered video will match correctly
  if (camera_choice !== 'Main Camera') {
    const fl = 1.0 / Math.tan((field_of_view * Math.PI) / 360);
    const fl_new = fl * render_viewport_apsect_ratio;
    const fov = Math.atan(1 / fl_new) / (Math.PI / 360);
    sceneTree.metadata.camera.fov = fov;
  } else {
    sceneTree.metadata.camera.fov = 50;
  }

  let old_camera_matrix = null;
  let is_moving = false;
  const sendThrottledCameraMessage = makeThrottledMessageSender(websocket, 25);

  // update the camera information in the python server
  const sendCamera = () => {
    if (clientId == null || clientId != spectatingClientId) {
      return;
    }

    if (isEqual(old_camera_matrix, sceneTree.metadata.camera.matrix.elements)) {
      if (is_moving) {
        is_moving = false;
      } else {
        return;
      }
    } else {
      is_moving = true;
    }
    old_camera_matrix = sceneTree.metadata.camera.matrix.elements.slice();

    isNewPacket.current = old_camera_matrix;

    sendThrottledCameraMessage({
      id: clientId,
      type: 'CameraMessage',
      aspect: sceneTree.metadata.camera.aspect,
      render_aspect,
      fov: sceneTree.metadata.camera.fov,
      matrix: old_camera_matrix,
      camera_type,
      is_moving,
      timestamp: +new Date(),
    });
  };

  const isWatermarkToggledOn = useMemo(() => {
    const isTeamMember = userProfile && ProfileTypes.TeamMember == userProfile.type;

    return (
      userProfile?.team_id?.setting?.permissions[isTeamMember ? 'team_member' : 'team_owner']?.scans
        ?.scan_specific.model_specific.watermark_toggle ?? true
    );
  }, [userProfile]);

  // keep sending the camera often
  // rerun this when the websocket changes
  useEffect(() => {
    const fps = 24;
    const interval = 1000 / fps;
    const refreshIntervalId = setInterval(sendCamera, interval);
    return () => {
      clearInterval(refreshIntervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websocket, camera_choice, camera_type, render_aspect, clientId, spectatingClientId]);

  const isWebsocketConnected = useSelector((state) => state.threeJs.websocketState.isConnected);
  useEffect(() => {
    let autoMoveTimer = null;

    if (isWebsocketConnected && clientId != null) {
      old_camera_matrix = sceneTree.metadata.camera.matrix.elements.slice();

      console.log({
        id: clientId,
        type: 'CameraMessage',
        aspect: sceneTree.metadata.camera.aspect,
        render_aspect,
        fov: sceneTree.metadata.camera.fov,
        matrix: old_camera_matrix,
        camera_type,
        is_moving,
        timestamp: +new Date(),
      });

      sendThrottledCameraMessage({
        id: clientId,
        type: 'CameraMessage',
        aspect: sceneTree.metadata.camera.aspect,
        render_aspect,
        fov: sceneTree.metadata.camera.fov,
        matrix: old_camera_matrix,
        camera_type,
        is_moving,
        timestamp: +new Date(),
      });

      autoMoveTimer = setTimeout(() => {
        // if (cameraDefaults) {
        //   const positionAllZero =
        //     cameraDefaults.position.x == 0 &&
        //     cameraDefaults.position.y == 0 &&
        //     cameraDefaults.position.z == 0;
        //   const desiredPos = positionAllZero
        //     ? new THREE.Vector3(0.8, -0.8, 0.4)
        //     : new THREE.Vector3(
        //         cameraDefaults.position.x + 0.01,
        //         cameraDefaults.position.y + 0.01,
        //         cameraDefaults.position.z + 0.01,
        //       );
        //   const desiredRotation = new THREE.Vector3(
        //     cameraDefaults.rotation.x,
        //     cameraDefaults.rotation.y,
        //     cameraDefaults.rotation.z,
        //   );
        //   console.log(desiredPos.x, desiredPos.y, desiredPos.z);
        //   sceneTree.metadata.camera_controls.setPosition(desiredPos.x, desiredPos.y, desiredPos.z);
        //   sceneTree.metadata.camera_controls.setLookAt(
        //     desiredPos.x,
        //     desiredPos.y,
        //     desiredPos.z,
        //     desiredRotation.x,
        //     desiredRotation.y,
        //     desiredRotation.z,
        //   );
        // } else {
        //   const desiredPos = new THREE.Vector3(0.8, -0.8, 0.4);
        //   const desiredRotation = new THREE.Vector3(0, 0, 0);
        //   console.log('HMMM 2');
        //   sceneTree.metadata.camera_controls.setPosition(desiredPos.x, desiredPos.y, desiredPos.z);
        //   sceneTree.metadata.camera_controls.setLookAt(
        //     desiredPos.x,
        //     desiredPos.y,
        //     desiredPos.z,
        //     desiredRotation.x,
        //     desiredRotation.y,
        //     desiredRotation.z,
        //   );
        // }
      }, 2000);
    }

    return () => {
      if (autoMoveTimer) {
        clearTimeout(autoMoveTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWebsocketConnected, clientId, spectatingClientId, cameraDefaults]);

  useEffect(() => {
    if (isWebsocketConnected && scanId && portId) {
      const startSession = async () => {
        try {
          sendWebsocketMessage(websocket, {
            type: 'SessionDetailsMessage',
            user_id: userId,
            username: userProfile?.profile_name ?? 'Anonymous',
            user_img: userProfile?.profile_image?.url ?? '',
            show_watermark: false,
          });
        } catch (error) {}
      };

      startSession();
    }
  }, [isWebsocketConnected, scanId, userId, portId, userProfile]);

  return (
    <>
      <div className="RenderWindow"></div>
      <img id="background-image" alt="Render window" z-index="1" hidden />
      <div
        className={`canvas-container-main ${
          (!isWebsocketConnected || clientId != spectatingClientId) && 'opacity-0'
        }`}
        ref={myRef}
      >
        <div className="ViewerWindow-camera-toggle">{/* <CameraToggle /> */}</div>
      </div>
      <div className="ViewerWindow-buttons" style={{ display: 'none' }}>
        <TransformIcons sceneTree={sceneTree} />
      </div>
      <div className="ViewerWindow-render-crop-container">
        <div className="ViewerWindow-render-crop" style={crop_style} />
      </div>
      {isWatermarkToggledOn && clientId == spectatingClientId && watermark.length > 0 && (
        <img
          src={watermark}
          className={`absolute z-20 opacity-30`}
          style={{
            width: get_window_height() * 0.15,
            top: is_embed ? `${get_window_height() * 0.025}px` : 'none',
            right: is_embed ? `${get_window_height() * 0.025}px` : 'none',
            bottom: is_embed ? 'none' : `${get_window_height() * 0.05}px`,
            left: is_embed ? 'none' : `${get_window_height() * 0.05}px`,
          }}
        />
      )}
      <IsStagingComponent>
        <canvas
          id="spectate-canvas"
          className={`w-full object-cover object-top ${
            clientId == spectatingClientId ? 'opacity-0' : 'border-4 border-[#15AA2C]'
          } ${
            is_sidebar_open ? '!max-w-[calc(100vw-240px)]' : '!max-w-[calc(100vw-92px)]'
          } !max-h-[calc(100vh-80px)]`}
        ></canvas>
      </IsStagingComponent>
    </>
  );
};

// export default memo(ViewerWindow);
export default ViewerWindow;
