import { useRoutes } from 'react-router-dom'
import LoginScreen from 'screens/Auth/LoginScreen/LoginScreen'
import DashboardScreen from 'screens/DashboardScreen/DashboardScreen'
import EmbedScreen from 'screens/EmbedScreen'
import NotFoundPage from 'screens/Error/404'
import UnderMaintenance from 'screens/Error/503'
import ProcessingErrorPage from 'screens/Error/ProcessingError'
import ServersBusyError from 'screens/Error/ServersBusy'
import FreeRoamingScreen from 'screens/FreeRoamingScreen/FreeRoamingScreen'
import InvitationScreen from 'screens/InvitationScreen/InvitationScreen'
import NotesScreen from 'screens/NotesScreen/NotesScreen'
import ObjectDetectionScreen from 'screens/ObjectDetectionScreen/ObjectDetectionScreen'
import RecordingsScreen from 'screens/RecordingsScreen/RecordingsScreen'
import SnapshotsScreen from 'screens/SnapshotsScreen/SnapshotsScreen'
import ThreeModel from 'screens/ThreeModel'
import VideoCaptureScreen from 'screens/VideoCaptureScreen/VideoCaptureScreen'
import { ROOT_PAGE_URL } from 'shared/constants/navigator'
import GuestLayout from 'shared/layouts/GuestLayout'
import Auth from 'shared/middlewares/Auth'
import Guest from 'shared/middlewares/Guest'

const underMaintenance = false

const AppRoutes = () => {
  return useRoutes(
    underMaintenance
      ? [
          {
            path: '*',
            element: <UnderMaintenance />
          }
        ]
      : [
          {
            path: ROOT_PAGE_URL.EMBED_SCREEN,
            element: <EmbedScreen />
          },
          {
            path: '',
            element: <Guest />,
            children: [
              {
                path: ROOT_PAGE_URL.LOGIN,
                element: <LoginScreen />
              }
            ]
          },
          {
            path: '',
            element: <Auth />,
            children: [
              {
                path: '',
                element: <ThreeModel />,
                children: [
                  {
                    path: '',
                    element: <GuestLayout />,
                    children: [
                      {
                        path: ROOT_PAGE_URL.DASHBOARD,
                        element: <DashboardScreen />
                      },
                      {
                        path: ROOT_PAGE_URL.FREE_ROAM,
                        element: <FreeRoamingScreen />
                      },
                      {
                        path: ROOT_PAGE_URL.VIDEO_CAPTURE,
                        element: <VideoCaptureScreen />
                      },
                      {
                        path: ROOT_PAGE_URL.OBJECT_DETECTION,
                        element: <ObjectDetectionScreen />
                      },
                      {
                        path: ROOT_PAGE_URL.SNAPSHOTS,
                        element: <SnapshotsScreen />
                      },
                      { path: ROOT_PAGE_URL.NOTES, element: <NotesScreen /> },
                      {
                        path: ROOT_PAGE_URL.RECORDINGS,
                        element: <RecordingsScreen />
                      }
                    ]
                  }
                ]
              },
              {
                path: ROOT_PAGE_URL.INVITATION,
                element: <InvitationScreen />
              }
            ]
          },
          {
            path: ROOT_PAGE_URL.SERVERS_BUSY,
            element: <ServersBusyError />
          },
          {
            path: ROOT_PAGE_URL.UNDER_MAINTENANCE,
            element: <UnderMaintenance />
          },
          {
            path: ROOT_PAGE_URL.PROCESSING_ERROR,
            element: <ProcessingErrorPage />
          },
          {
            path: '*',
            element: <NotFoundPage />
          }
        ]
  )
}

export default AppRoutes
