import { useGetSceneTree } from 'shared/scene/Scene'

import ThreeJsSceneProviderContext from './ThreeJsSceneProvider.context'

const ThreeJsSceneProvider = ({ children }: any) => {
  const sceneTree = useGetSceneTree()

  return (
    <ThreeJsSceneProviderContext.Provider value={sceneTree}>
      {children}
    </ThreeJsSceneProviderContext.Provider>
  )
}

export default ThreeJsSceneProvider
