/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from 'react'

interface DotLoaderProps {
  success?: boolean
}

export default function DotLoader({ success = false }: DotLoaderProps) {
  const [loaderDot, setLoaderDot] = useState('.')

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined

    if (success) {
      if (interval != null) {
        clearInterval(interval)
      }
    } else {
      interval = setInterval(() => {
        if (loaderDot.length < 3) {
          setLoaderDot(prev => `${prev}.`)
        } else {
          setLoaderDot('.')
        }
      }, 500)
    }
    return () => {
      clearInterval(interval)
    }
  }, [loaderDot, success])

  return <>{loaderDot}</>
}
