import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { closeLogInDialog } from 'store/slices/app/appSlice'

const useLogInDialog = () => {
  const dispatch = useAppDispatch()

  const isLogInDialogVisible = useAppSelector(
    state => state.app.is_login_dialog_open
  )

  const onLogInDialogClose = () => {
    dispatch(closeLogInDialog())
  }

  return {
    isLogInDialogVisible,
    onLogInDialogClose
  }
}

export default useLogInDialog
