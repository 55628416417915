import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ROOT_PAGE_URL } from 'shared/constants/navigator'
import useAuth from 'shared/hooks/useAuth'
import { useAppSelector } from 'shared/hooks/useRedux'

const Guest = () => {
  const { user } = useAuth()
  const userProfile = useAppSelector(state => state.auth.userProfile)
  const navigate = useNavigate()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const scanId = urlParams.get('scan_id')
  const userId = urlParams.get('user_id')
  const userEmail = urlParams.get('user_email')
  const embed = urlParams.get('embed')
  const profileId = urlParams.get('profile_id')
  const token = urlParams.get('token')
  const snapshotId = urlParams.get('snapshot_id')
  const mobile = urlParams.get('mobile')

  useEffect(() => {
    if (user == null) {
      if (userId == null) {
        const queryParams = {}

        if (scanId != null) (queryParams as any).scan_id = scanId
        if (userId != null) (queryParams as any).user_id = userId
        if (userEmail != null) (queryParams as any).user_email = userEmail
        if (embed != null) (queryParams as any).embed = embed
        if (profileId != null) (queryParams as any).profile_id = profileId
        if (token != null) (queryParams as any).token = token
        if (snapshotId != null) (queryParams as any).snapshot_id = snapshotId
        if (mobile != null) (queryParams as any).mobile = mobile

        navigate(
          ROOT_PAGE_URL.DASHBOARD +
            '?' +
            new URLSearchParams(queryParams).toString(),
          {
            replace: true
          }
        )
      }
    } else {
      if (userProfile == null) {
        window.open(
          `${process.env.REACT_APP_HOME_PAGE_URL}/account-creation`,
          '_self'
        )
      } else {
        if (
          userId == null ||
          user.user_id === Number.parseInt(userId) ||
          (userEmail != null && user.email === userEmail)
        ) {
          const queryParams = {}

          if (scanId != null) (queryParams as any).scan_id = scanId
          if (userId != null) (queryParams as any).user_id = userId
          if (userEmail != null) (queryParams as any).user_email = userEmail
          if (embed != null) (queryParams as any).embed = embed
          if (profileId != null) (queryParams as any).profile_id = profileId
          if (snapshotId != null) (queryParams as any).snapshot_id = snapshotId
          if (mobile != null) (queryParams as any).mobile = mobile

          navigate(
            ROOT_PAGE_URL.DASHBOARD +
              '?' +
              new URLSearchParams(queryParams).toString(),
            {
              replace: true
            }
          )
        }
      }
    }
  }, [user, navigate, userProfile, token])

  return (
    <div>
      <Outlet />
    </div>
  )
}

export default Guest
