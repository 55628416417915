import UnderMaintenanceImage from 'assets/images/maintenance-placeholder-image.png'

import LOGO from '../../assets/images/logo.png'

const ServersBusyError = () => {
  return (
    <div className="w-full min-h-[var(--app-height)] p-8 flex flex-col justify-between bg-[#1E1E1E]">
      <div>
        <img src={LOGO} className="w-[150px]" alt="logo" />
      </div>
      <div className="w-[90%] md:w-[390px] text-center mx-auto">
        <img src={UnderMaintenanceImage} className="w-[90%] mb-14" />
        <div className="text-[20px] text-white mb-3 font-semibold">
          High Traffic Alert!.
        </div>
        <div className="text-md text-white">
          We are experiencing high traffic right now. If you would like to
          schedule a live demo, please request access{' '}
          <a
            href={`${process.env.REACT_APP_HOME_PAGE_URL}/early-access`}
            className="text-primary"
          >
            here
          </a>
          .
        </div>
      </div>
      <div className="text-md text-[#667085]">© XSpada 2023</div>
    </div>
  )
}

export default ServersBusyError
