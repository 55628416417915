import 'assets/styles/style.scss'
import '@fontsource/poppins'
import '@fontsource/poppins/500.css'

import ReactDOM from 'react-dom/client'

import App from './App'

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>,
)
