import { ReactComponent as AppleIcon } from './icons/Apple-Icon.svg'
import { ReactComponent as ArrowDown } from './icons/Arrow-Down.svg'
import { ReactComponent as ArrowLeftNav } from './icons/Arrow-Left-Nav.svg'
import { ReactComponent as ArrowRightIcon } from './icons/Arrow-Right-Icon.svg'
import { ReactComponent as ArrowRightNav } from './icons/Arrow-Right-Nav.svg'
import { ReactComponent as ArrowUpRight } from './icons/Arrow-Up-Right-Icon.svg'
import { ReactComponent as ArrowDownWhiteIcon } from './icons/Arrow-White-Down.svg'
import { ReactComponent as BoxIcon } from './icons/Box-Icon.svg'
import { ReactComponent as CaptureIcon } from './icons/Capture-Icon.svg'
import { ReactComponent as CheckCircleIcon } from './icons/Check-Circle-Icon.svg'
import { ReactComponent as CheckboxActive } from './icons/Checkbox-Active.svg'
import { ReactComponent as CheckboxInActive } from './icons/Checkbox-InActive.svg'
import { ReactComponent as CloseIcon } from './icons/Close-Icon.svg'
import { ReactComponent as CloseBlackIcon } from './icons/Close-Icon-Black.svg'
import { ReactComponent as ContextMenuHorizontalGrayIcon } from './icons/Context-Menu-Horizontal-Gray-Icon.svg'
import { ReactComponent as ControlsIcon } from './icons/Controls-Icon.svg'
import { ReactComponent as DeleteIcon } from './icons/Delete-Icon.svg'
import { ReactComponent as DeleteLayerIcon } from './icons/Delete-Layer-Icon.svg'
import { ReactComponent as DeleteWhiteIcon } from './icons/Delete-White-Icon.svg'
import { ReactComponent as DownloadIcon } from './icons/Download-Icon.svg'
import { ReactComponent as DownloadWhiteIcon } from './icons/Download-White-Icon.svg'
import { ReactComponent as EmailGrayIcon } from './icons/Email-Gray-Icon.svg'
import { ReactComponent as FacebookIcon } from './icons/Facebook-Icon.svg'
import { ReactComponent as FeaturedIcon } from './icons/Featured icon.svg'
import { ReactComponent as GoogleIcon } from './icons/Google-Icon.svg'
import { ReactComponent as HelpCircleIcon } from './icons/Help-Circle-Icon.svg'
import { ReactComponent as HomeIcon } from './icons/Home-Icon.svg'
import { ReactComponent as ImageIcon } from './icons/Image-Icon.svg'
import { ReactComponent as LinkIcon } from './icons/Link-Icon.svg'
import { ReactComponent as LocationMarkerGrayIcon } from './icons/Location-Marker-Gray-Icon.svg'
import { ReactComponent as LocationMarkerIcon } from './icons/Location-Marker-Icon.svg'
import { ReactComponent as LogoutIcon } from './icons/Logout-Icon.svg'
import { ReactComponent as MailIcon } from './icons/Mail-Icon.svg'
import { ReactComponent as MapPinIcon } from './icons/Map-Pin-Icon.svg'
import { ReactComponent as MenuIcon } from './icons/Menu-Icon.svg'
import { ReactComponent as MapLineIcon } from './icons/Menu-Line-Icon.svg'
import { ReactComponent as MessageCircleIcon } from './icons/Message-Circle-Icon.svg'
import { ReactComponent as NotesIcon } from './icons/Notes-Icon.svg'
import { ReactComponent as PauseIcon } from './icons/Pause-Icon.svg'
import { ReactComponent as PlayButtonIcon } from './icons/Play-Button.svg'
import { ReactComponent as PlayCircleFilledGrayIcon } from './icons/Play-Circle-Filled-Gray-Icon.svg'
import { ReactComponent as PlayCircleFilledIcon } from './icons/Play-Circle-Filled-Icon.svg'
import { ReactComponent as PlayCircleIcon } from './icons/Play-Circle-Icon.svg'
import { ReactComponent as PlayIcon } from './icons/Play-Icon.svg'
import { ReactComponent as PlusIcon } from './icons/Plus-Icon.svg'
import { ReactComponent as ProfileIcon } from './icons/PrivateProfile.svg'
import { ReactComponent as RoamingIcon } from './icons/Roaming-Icon.svg'
import { ReactComponent as SelectArrow } from './icons/Select-Arrow.svg'
import { ReactComponent as SendIcon } from './icons/Send-Icon.svg'
import { ReactComponent as SnapshotIcon } from './icons/Snapshot-Icon.svg'
import { ReactComponent as SnapshotLayerIcon } from './icons/Snapshot-Layer-Icon.svg'
import { ReactComponent as TagIcon } from './icons/Tag-Icon.svg'
import { ReactComponent as UserIcon } from './icons/User-Icon.svg'
import { ReactComponent as VideoIcon } from './icons/Video-Icon.svg'
import { ReactComponent as WarningLayerIcon } from './icons/Warning-Layer-Icon.svg'
import { ReactComponent as LogoFirstLetter } from './logo-first-letter.svg'
import { ReactComponent as LogoFull } from './logo-full.svg'

const useThemeImages = () => {
  return {
    MenuIcon,
    RoamingIcon,
    VideoIcon,
    CloseIcon,
    BoxIcon,
    CaptureIcon,
    CheckCircleIcon,
    FeaturedIcon,
    HomeIcon,
    ImageIcon,
    MailIcon,
    MapPinIcon,
    MapLineIcon,
    MessageCircleIcon,
    PauseIcon,
    PlayCircleFilledIcon,
    PlayCircleFilledGrayIcon,
    PlayCircleIcon,
    PlayIcon,
    PlusIcon,
    SendIcon,
    TagIcon,
    NotesIcon,
    LinkIcon,
    ArrowRightIcon,
    UserIcon,
    ArrowLeftNav,
    ArrowRightNav,
    HelpCircleIcon,
    SelectArrow,
    CheckboxActive,
    CheckboxInActive,
    ArrowDown,
    GoogleIcon,
    FacebookIcon,
    AppleIcon,
    ControlsIcon,
    LogoutIcon,
    LocationMarkerIcon,
    LocationMarkerGrayIcon,
    ArrowDownWhiteIcon,
    SnapshotIcon,
    SnapshotLayerIcon,
    DownloadIcon,
    DeleteIcon,
    ArrowUpRight,
    ContextMenuHorizontalGrayIcon,
    ProfileIcon,
    DeleteLayerIcon,
    DownloadWhiteIcon,
    DeleteWhiteIcon,
    EmailGrayIcon,
    WarningLayerIcon,
    PlayButtonIcon,
    CloseBlackIcon,

    LogoFirstLetter,
    LogoFull
  }
}

export default useThemeImages

// export {
//   MenuIcon,
//   RoamingIcon,
//   VideoIcon,
//   CloseIcon,
//   BoxIcon,
//   CaptureIcon,
//   CheckCircleIcon,
//   FeaturedIcon,
//   HomeIcon,
//   ImageIcon,
//   MailIcon,
//   MapPinIcon,
//   MapLineIcon,
//   MessageCircleIcon,
//   PauseIcon,
//   PlayCircleFilledIcon,
//   PlayCircleIcon,
//   PlayIcon,
//   PlusIcon,
//   SendIcon,
//   TagIcon,
//   NotesIcon,
//   LinkIcon,
//   ArrowRightIcon,
//   UserIcon,
//   ArrowLeftNav,
//   ArrowRightNav,
//   HelpCircleIcon,
// };
