import SideDrawerPanel from './SideDrawerPanel'
import useSideDrawer from './useSideDrawer'

export interface ISideDrawerProps {
  top?: string
  btnContent: React.ReactNode
  position: 'left' | 'right'
  contentTitle?: string | React.ReactNode
  children?: React.ReactNode
}

const SideDrawer = (props: ISideDrawerProps) => {
  const {
    btnContent,
    contentTitle,
    children,
    top = '16px',
    position = 'left'
  } = props
  const { open, zeroClass, drawerBtnRef, toggleDrawer } = useSideDrawer(props)

  return (
    <div
      className="hidden md:block absolute h-full w-full max-w-[465px] pointer-events-auto"
      style={{
        top,
        left: position === 'left' ? '0' : 'unset',
        right: position === 'right' ? '0' : 'unset'
      }}
    >
      <div
        onClick={toggleDrawer}
        ref={drawerBtnRef}
        className={`absolute top-0 bg-[#1E1E1E] p-2 lg:p-4 cursor-pointer transition-all duration-200 ${zeroClass} animate__animated animate__fadeIn animate__faster h-[50px] w-[50px] lg:h-[72px] lg:w-[72px] flex items-center justify-center`}
        style={
          position === 'left'
            ? { borderRadius: '0 20px 20px 0' }
            : { borderRadius: '20px 0 0 20px' }
        }
      >
        {btnContent}
      </div>
      <SideDrawerPanel
        position={position}
        open={open}
        contentTitle={contentTitle}
        onClose={toggleDrawer}
      >
        {children}
      </SideDrawerPanel>
    </div>
  )
}

export default SideDrawer
