import NotFoundImage from 'assets/images/not-found-image.png'

import LOGO from '../../assets/images/logo.png'

const ProcessingErrorPage = () => {
  return (
    <div className="w-full h-[var(--app-height)] p-8 flex flex-col justify-between bg-[#1E1E1E]">
      <div>
        <img src={LOGO} className="w-[150px]" alt="logo" />
      </div>
      <div className="w-[90%] md:w-[420px] text-center mx-auto">
        <img src={NotFoundImage} className="w-[90%] mb-14" />
        <div className="text-[20px] text-white mb-3 font-semibold">
          Your Scan is under processing right now!
        </div>
        <div className="text-md text-white">
          Your scan is not ready yet. Please come back again to get an update
          over to your scan. If you want to get status on your scan, send us an
          email at{' '}
          <a href="mailto:info@xspada.com" className="text-[#7680FF]">
            info@xspada.com
          </a>
          .
        </div>
      </div>
      <div className="text-md text-[#667085]">© XSpada 2023</div>
    </div>
  )
}

export default ProcessingErrorPage
