import { Button, Collapse } from '@mui/material'
import useThemeImages from 'assets/images'
import { useContext, useState } from 'react'
import ThreeModelContext from 'screens/ThreeModel/ThreeModel.context'
import { useAppSelector } from 'shared/hooks/useRedux'

const EmbedFooter = () => {
  const { ArrowDownWhiteIcon, LocationMarkerGrayIcon } = useThemeImages();
  const { scan_name, scannerDetails } = useAppSelector((state) => ({
    scan_name: state.scan.scan?.name,
    scannerDetails: state.scan.scanner,
  }));

  const threeJsContext = useContext(ThreeModelContext)

  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false)

  return (
    <div className="absolute bottom-0 left-0 w-full z-[100]">
      {(threeJsContext as any).isScanOwner != null && (
        <div className="md:hidden w-full flex items-center justify-center">
          <span
            className={`py-5 px-10 ${
              !isBottomSheetOpen && 'rotate-180'
            } transition-transform`}
            onClick={() => {
              setIsBottomSheetOpen(prev => !prev)
            }}
          >
            <ArrowDownWhiteIcon width="12px" height="12px" />
          </span>
        </div>
      )}
      <div
        className={`w-[calc(100%-40px)] mx-auto mb-[10px] rounded-[12px] py-4 px-6 text-md text-[#DDDDDD]`}
        style={{
          background: 'linear-gradient(0deg, #1E1E1E 0%, #1E1E1E 100%), #FFF',
          boxShadow:
            '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)'
        }}
      >
        <span className="text-[#7680FF]">{scan_name}</span> Captured by{' '}
        {scannerDetails.name}
      </div>
      {(threeJsContext as any).isScanOwner != null && (
        <Collapse
          in={isBottomSheetOpen}
          className="h-[104px] !bottom-0 rounded-t-[20px] !duration-300 !bg-[#1E1E1E]"
        >
          <div className="pb-6 px-5 pt-3">
            <div className="mx-auto h-1 w-[85px] bg-[#393939] rounded-full mb-5"></div>
            <Button
              variant="contained"
              className="!bg-[#F2F3FD1A] h-[52px] !rounded-md !flex !items-center !justify-start !gap-x-[10px] !px-3 !text-md !text-[#DDDDDD] !font-normal"
              fullWidth
              onClick={
                (threeJsContext as any)?.saveCameraPosition == null
                  ? () => {
                      console.log('saveCameraPosition not defined')
                    }
                  : (threeJsContext as any)?.saveCameraPosition
              }
              sx={{
                '&': {
                  boxShadow: '0px 1px 4px 0px #1018283f !important'
                }
              }}
            >
              <LocationMarkerGrayIcon height="18px" width="18px" />
              Save as default starting point
            </Button>
          </div>
        </Collapse>
      )}
    </div>
  )
}

export default EmbedFooter
