import { ButtonBase } from '@mui/material'
import useThemeImages from 'assets/images'
import type React from 'react'
import { useEffect, useRef } from 'react'

interface ISideDrawerPanelProps {
  position: 'left' | 'right'
  open: boolean
  contentTitle?: string | React.ReactNode
  children?: React.ReactNode
  onClose: () => void
  className?: string
  onOpen?: () => void
}

const SideDrawerPanel = (props: ISideDrawerPanelProps) => {
  const {
    contentTitle,
    children,
    onClose,
    className,
    open,
    position,
    onOpen = () => {
      console.log('onOpen')
    }
  } = props
  const { CloseIcon } = useThemeImages()

  const ref = useRef<HTMLDivElement>(null)

  const fullClass = position === 'left' ? '-left-full' : '-right-full'
  const fourClass = position === 'left' ? 'left-4' : 'right-4'

  useEffect(() => {
    setTimeout(
      () => {
        if (open) {
          ref.current?.classList.add(fourClass)
          ref.current?.classList.remove(fullClass)
        } else {
          ref.current?.classList.remove(fourClass)
          ref.current?.classList.add(fullClass)
        }
        ref.current?.classList.remove('opacity-0')
      },
      open ? 200 : 0
    )
  }, [open, fullClass, fourClass])

  useEffect(() => {
    if (open) {
      onOpen()
    }
  }, [open, onOpen])

  return (
    <div
      ref={ref}
      className={`absolute top-0 bg-[#1E1E1E] z-10 w-[calc(100%-32px)] p-6 pt-4 text-white rounded-xl transition-all duration-200 opacity-0 ${
        className ?? ''
      }`}
    >
      <div className="flex items-center justify-between mb-4">
        {typeof contentTitle === 'string' ? (
          <h4 className="font-medium text-[20px]">{contentTitle}</h4>
        ) : (
          contentTitle
        )}
        <ButtonBase
          onClick={onClose}
          className="!rounded-[20px] !h-[23px] !w-[23px] !bg-white !bg-opacity-5 flex items-center justify-center"
        >
          <CloseIcon color="#EB617A" />
        </ButtonBase>
      </div>
      {children}
    </div>
  )
}

export default SideDrawerPanel
