import { createSlice } from '@reduxjs/toolkit'

interface Props {
  isShowControlsOnStart: boolean
  isShownOnceOnStart: boolean
}

const initialState: Props = {
  isShowControlsOnStart: true,
  isShownOnceOnStart: false
}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    toggleShowControlsOnStart(state, action) {
      state.isShowControlsOnStart = action.payload
      state.isShownOnceOnStart = true
    }
  }
})

export default userInfoSlice.reducer

export const { toggleShowControlsOnStart } = userInfoSlice.actions
