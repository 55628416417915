import useThemeImages from 'assets/images'
import { useContext, useEffect } from 'react'
import ThreeModelContext from 'screens/ThreeModel/ThreeModel.context'
import { useDeleteSnapshotMutation } from 'shared/graphql/utils/enhanced'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { type ISnapshot } from 'shared/types/snapshot/snapshot.type'
import { rtkHandler } from 'shared/utils/handlers'
import { initiateDownload } from 'shared/utils/helper'
import { setSelectedFile } from 'store/slices/dashboard/dashboardSlice'
import {
  setCloseCommonModal,
  setCommonModal,
  setCommonModalLoadingBtn
} from 'store/slices/utils/commonModalSlice'

interface IUseFileDetailsOverlayProps {
  fileByProp?: ISnapshot
}

const useFileDetailsOverlay = (props?: IUseFileDetailsOverlayProps) => {
  const dispatch = useAppDispatch()

  const { DeleteLayerIcon } = useThemeImages()

  const selectedFile = useAppSelector(
    (state) => props?.fileByProp ?? state.dashboard.selected_file,
  );
  const scan_id = useAppSelector((state) => state.scan.scan?.scan_id);

  const threeJsContext = useContext(ThreeModelContext)

  const [deleteSnapshot, deleteSnapshotRes] = useDeleteSnapshotMutation()

  const onDownloadClickHandler = () => {
    initiateDownload(
      `${process.env.REACT_APP_SERVER_URL}/${scan_id}/snapshots/${selectedFile?._id}/download/snapshot_${selectedFile?._id}.png`
    )
  }

  const onDeleteClickHandler = () => {
    dispatch(
      setCommonModal({
        title: 'Delete Snapshot',
        contentComponent: (
          <div>
            Are you sure you want to delete{' '}
            <span className="text-[#7680FF] w-fit">{selectedFile?.name}</span>{' '}
            snapshot?
          </div>
        ),
        icon: <DeleteLayerIcon />,
        dialogPaperProps: {
          className: '!max-w-[343px]'
        },
        dialogueActionClassName: 'flex-col',
        buttons: [
          {
            children: 'Delete',
            id: 'delete-snapshot',
            color: 'error',
            onClick: () => {
              deleteSnapshot({
                id: Number.parseInt(selectedFile?._id ?? '0')
              })
                .then(() => {
                  console.log('success')
                })
                .catch(() => {
                  console.log('error')
                })
            }
          }
        ]
      })
    )
  }

  const onSnapshotInViewerClickHandler = () => {
    if ((threeJsContext as any).sceneTree != null) {
      ;(threeJsContext as any).sceneTree.metadata.camera_controls.setPosition(
        (selectedFile as any).position.x,
        (selectedFile as any).position.y,
        (selectedFile as any).position.z
      )
      ;(threeJsContext as any).sceneTree.metadata.camera_controls.setLookAt(
        (selectedFile as any).position.x,
        (selectedFile as any).position.y,
        (selectedFile as any).position.z,
        (selectedFile as any).rotation.x,
        (selectedFile as any).rotation.y,
        (selectedFile as any).rotation.z
      )
    }
  }

  useEffect(() => {
    dispatch(
      setCommonModalLoadingBtn(
        deleteSnapshotRes.isLoading ? 'delete-snapshot' : ''
      )
    )
  }, [deleteSnapshotRes.isLoading])

  useEffect(() => {
    rtkHandler(deleteSnapshotRes, {
      onSuccess() {
        dispatch(setSelectedFile(null))
        dispatch(setCloseCommonModal())
      }
    })
  }, [deleteSnapshotRes])

  return {
    selectedFile,
    isDeleting: deleteSnapshotRes.isLoading,
    onDownloadClickHandler,
    onDeleteClickHandler,
    onSnapshotInViewerClickHandler
  }
}

export default useFileDetailsOverlay
