import { IconButton, type IconButtonProps, styled } from '@mui/material'

interface IAppIconButton extends IconButtonProps {
  icon: React.ReactNode
}

const CustomIconButton = styled(IconButton)(({ theme }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      height: '40px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '48px',
      height: '48px'
    },
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'rgb(244, 235, 255, 0.1)',
    borderWidth: '4px !important',
    borderStyle: 'solid !important',
    borderColor: 'rgb(249, 245, 255, 0.02)',
    position: 'relative',

    // before
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 'calc(100% + 12px)',
      height: 'calc(100% + 12px)',
      borderRadius: '50%',
      border: '3px solid rgb(72, 74, 90)',
      top: '-6px',
      left: '-6px'
    }
  }
})

const AppIconButton = (props: IAppIconButton) => {
  const { icon, ...others } = props

  return <CustomIconButton {...others}>{icon}</CustomIconButton>
}

export default AppIconButton
