import { useEffect, useState } from 'react'
import { useGetMyScansByIDQuery } from 'shared/graphql/utils/enhanced'
import { useAppDispatch, useAppSelector } from 'shared/hooks/useRedux'
import { type ISnapshot } from 'shared/types/snapshot/snapshot.type'
import { rtkHandler } from 'shared/utils/handlers'
import { setAllFiles } from 'store/slices/dashboard/dashboardSlice'

const useSnapshotsScreen = () => {
  const dispatch = useAppDispatch()

  const scan_id = useAppSelector((state) => state.scan.scan?.scan_id);

  const snapshotsRes = useGetMyScansByIDQuery({
    id: Number.parseInt(scan_id ?? '')
  })

  const [snapshots, setSnapshots] = useState<ISnapshot[]>([])

  useEffect(() => {
    rtkHandler(snapshotsRes, {
      showSuccessToast: false,
      onSuccess(res) {
        setSnapshots(res.scan.snapshots)
        dispatch(setAllFiles(res.scan.snapshots))
      }
    })
  }, [snapshotsRes])

  return { snapshots, isLoading: snapshotsRes.isLoading }
}

export default useSnapshotsScreen
